// import { newApiRequest as apiRequest } from 'api/apiRequest'
import i18n, { getLocalizedLink } from 'translation'
import { formatDate, formatAmount, formatMoney } from 'utils'
import { apiRequest } from '../apiRequest'

// TODO: Dummy data to be removed
import {
  allData,
  allStatsData,
  individualStatsData,
  professionalStatsData,
  singleSubscriptionStatsData,
} from '../dummyData/subscriptionsDummyData'

const t = i18n.getFixedT(null, 'subscriptionsPage')

const getAllList = async () => {
  return {
    data: allData,
    tableInfo: {
      heads: [
        'nameOrEmail',
        'type',
        'status',
        'signupDate',
        'licenses',
        'lastUsed',
        'dailyUse',
        'payment',
        'actions',
      ],
      rowIdName: 'id',
      initialOrder: 'nameOrEmail',
    },
  }
  // return apiRequest('accounts', []).get({ id }, config)
}

const getProfessionalList = async (proUserId) => {
  if (proUserId) {
    const { result, error } = await apiRequest(
      `/accounts/subscription/pro?id=${proUserId}`,
      {
        subscriptions: [],
        stats: {},
      }
    ).get()

    if (error) {
      console.log('Error fetching subscriptions: ', error)
      return { error }
    }

    const parsedPayments = result.payments.map(({ id, createdAt, amount }) => ({
      id,
      amount: formatMoney(amount, result.currency),
      payment: formatDate(createdAt),
      date: formatDate(createdAt),
    }))

    const { name, signupDate, proAccountType } = result.subscriptionInfo

    const gridName = {
      title: name,
      subtitle: {
        isBold: false,
        text: `${t('ACTIVE')} - ${t('SIGNUP_DATE')}: ${formatDate(signupDate)} `,
      },
      body: [
        // TODO: Add button for deactivation
        // {
        //   isButton: true,
        //   clickHandler: () => {},
        //   text: t('DEACTIVATE'),
        // },
      ],
    }

    if (proAccountType)
      gridName.body.unshift({
        isLink: true,
        text: t('VIEW_USAGE'),
        link: getLocalizedLink({
          path: ['accounts', proAccountType],
          param: proUserId,
        }),
      })

    // TODO: Add end date
    // if (status === 'active')
    //   gridName.subtitle2 = {
    //     isBold: false,
    //     text: `${t('END_DATE')} ${endDate} `,
    //   }

    return {
      data: parsedPayments,
      tableInfo: {
        heads: [
          'date',
          'amount',
          'payment',
          // TODO: Enable action
          // 'actions'
        ],
        initialOrder: 'date',
        rowIdName: 'date',
      },
      stats: {
        gridName,
        gridStats: [
          {
            title: 'TOTAL_REVENUE',
            amount: `${formatMoney(result.stats.revenueTotal, result.currency)}`,
          },
        ],
      },
    }
  }

  // No proUserId passed, return all
  const { result, error } = await apiRequest('/accounts/subscription/pro/list', {
    subscriptions: [],
    stats: {},
  }).get()

  // TODO: Handle error
  if (error) {
    console.log('Error fetching subscriptions: ', error)
    return { error }
  }

  const parsedProData = result.subscriptions.map((subscription) => ({
    ...subscription,
    licenses: formatAmount(subscription.licenses, 0, { showFull: true }),
    type: 'PROFESSIONAL',
    signupDate: formatDate(subscription.signupDate),
    lastUsed: subscription.lastUsed ? formatDate(subscription.lastUsed) : '',
    links: {
      name: { path: ['subscriptions', 'professional'], param: subscription.id },
    },
    // TODO:
    // dailyUse: `${RandomSource(0, 9) * 10}m`,
    // makePayment: payment === 'DECLINED',
  }))

  return {
    data: parsedProData,
    tableInfo: {
      heads: [
        'name',
        'status',
        'signupDate',
        'licenses',
        'lastUsed',
        // 'dailyUse',
        'payment',
        // 'actions',
      ],
      rowIdName: 'id',
      initialOrder: 'name',
    },
    stats: {
      gridStats: [
        {
          title: 'REVENUE_PRO',
          amount: formatMoney(result.stats.revenueProAccounts, result.currency),
        },
        {
          title: 'NEW_ACCOUNTS',
          amount: formatAmount(result.stats.newAccounts),
        },
        {
          title: 'CANCELLED_ACCOUNTS',
          amount: formatAmount(result.stats.cancelledAccounts),
        },
      ],
    },
  }
}

const getIndividualList = async (userId) => {
  if (userId) {
    const { result, error } = await apiRequest(
      `/accounts/subscription/individual?id=${userId}`,
      {
        subscriptionInfo: {},
        payments: [],
        stats: {},
      }
    ).get()

    if (error) {
      console.log('Error fetching subscriptions: ', error)
      return { error }
    }

    const parsedPayments = result.payments.map(({ id, createdAt, amount }) => ({
      id,
      amount: formatMoney(amount, result.currency),
      payment: formatDate(createdAt),
      date: formatDate(createdAt),
    }))

    const {
      email,
      signupDate,
      associatedProAccount,
      associatedProAccountType,
      associatedProAccountIsDoctor,
    } = result.subscriptionInfo

    const gridName = {
      title: email,
      subtitle: {
        isBold: false,
        text: `${t('ACTIVE')} - ${t('SIGNUP_DATE')}: ${formatDate(signupDate)}`,
      },
      body: [
        {
          isLink: true,
          text: t('VIEW_USAGE'),
          link: getLocalizedLink({
            path: ['accounts', 'patients'],
            param: userId,
          }),
        },
        // TODO
        // {
        //   isButton: true,
        //   clickHandler: () => {},
        //   text: t('DEACTIVATE'),
        // },
      ],
    }

    // TODO: if patient was invited by doctor, associatedProAccountType will be null and the pro user will instead have a group: 'doctors' proerty
    // Add

    // TODO: reenable once route 'accounts/doctors is active:
    // const addLinkToReferrer =
    //   associatedProAccount && (associatedProAccountType || associatedProAccountIsDoctor)

    const addLinkToReferrer = associatedProAccount && associatedProAccountType

    if (addLinkToReferrer) {
      // TODO: reenable once route 'accounts/doctors is active:
      // const referrerAccountType = associatedProAccountType  || 'doctors'
      // const text = t(`ASSOCIATED_${associatedProAccountIsDoctor ? 'DOCTOR' : 'PRO'}_ACCOUNT`)

      const referrerAccountType = associatedProAccountType
      const text = t(`ASSOCIATED_PRO_ACCOUNT`)

      gridName.body.push({
        text,
        isLink: true,
        link: getLocalizedLink({
          path: ['accounts', referrerAccountType],
          param: associatedProAccount,
        }),
      })
    }

    // TODO: Add end date
    // if (status === 'active')
    //   gridName.subtitle2 = {
    //     isBold: false,
    //     text: `${t('END_DATE')} ${endDate} `,
    //   }

    return {
      data: parsedPayments,
      tableInfo: {
        heads: [
          'date',
          'amount',
          'payment',
          // TODO: Enable action
          //'actions'
        ],
        initialOrder: 'date',
        rowIdName: 'id',
      },
      stats: {
        gridName,
        gridStats: [
          {
            title: 'TOTAL_REVENUE',
            amount: `${formatMoney(result.stats.revenueTotal, result.currency)}`,
          },
        ],
      },
    }
  }

  // No userId passed, return all
  const { result, error } = await apiRequest('/accounts/subscription/individual/list', {
    subscriptions: [],
    stats: {},
  }).get()

  if (error) {
    console.log('Error fetching subscriptions: ', error)
    return { error }
  }

  const parsedIndividualData = result.subscriptions.map((subscription) => ({
    ...subscription,
    type: 'INDIVIDUAL',
    signupDate: formatDate(subscription.signupDate),
    lastUsed: subscription.lastUsed ? formatDate(subscription.lastUsed) : '',
    links: { email: { path: ['subscriptions', 'individual'], param: subscription.id } },
    // TODO:
    // dailyUse: `${RandomSource(0, 9) * 10}m`,
    // makePayment: payment === 'DECLINED',
  }))

  return {
    data: parsedIndividualData,
    tableInfo: {
      heads: [
        'email',
        'status',
        'signupDate',
        'lastUsed',
        // TODO
        // 'dailyUse',
        'payment',
        // TODO
        // 'actions',
      ],
      initialOrder: 'email',
      rowIdName: 'id',
    },
    stats: {
      gridStats: [
        {
          title: 'REVENUE_INDIVIDUAL',
          amount: formatMoney(result.stats.revenueIndividualAccounts, result.currency),
        },
        {
          title: 'NEW_ACCOUNTS',
          amount: formatAmount(result.stats.newAccounts),
        },
        {
          title: 'CANCELLED_ACCOUNTS',
          amount: formatAmount(result.stats.cancelledAccounts),
        },
      ],
    },
  }
}

const getSubscriptionsList = (type, userId) => {
  // Temporary mock function until backend up and running

  switch (type) {
    case 'all':
      return getAllList()
    case 'individual':
      return getIndividualList(userId)
    case 'professional':
      return getProfessionalList(userId)
    default:
      throw Error('Invalid type')
  }
}

const getSubscriptionsStats = async (type, id) => {
  // Temp mock function until backend up and running
  if (id) {
    const subscription = allData.find(({ id: someId }) => someId === id)

    if (!subscription) return

    const { email, name, signupDate, type, endDate, status } = subscription
    const gridName =
      type === 'INDIVIDUAL'
        ? {
            title: email,
            subtitle: {
              isBold: false,
              text: `${t('ACTIVE')} - ${t('SIGNUP_DATE')}: ${signupDate}`,
            },
            body: [
              {
                isLink: true,
                text: t('VIEW_USAGE'),
                link: getLocalizedLink({ path: ['accounts', 'patients'], param: id }),
              },
              {
                isLink: true,
                text: t('ASSOCIATED_PRO_ACCOUNT'),
                link: getLocalizedLink({ path: ['#'] }),
              },
              // TODO: Enable when functional
              // {
              //   isButton: true,
              //   clickHandler: () => {},
              //   text: t('DEACTIVATE'),
              // },
            ],
          }
        : {
            title: name,
            subtitle: {
              isBold: false,
              text: `${t('INACTIVE')} - ${t('SIGNUP_DATE')}: ${signupDate} `,
            },
            body: [
              {
                isLink: true,
                text: t('VIEW_USAGE'),
                link: getLocalizedLink({ path: ['#'] }),
              },
              {
                isButton: true,
                clickHandler: () => {},
                text: t('DEACTIVATE'),
              },
            ],
          }

    if (status === 'active')
      gridName.subtitle2 = {
        isBold: false,
        text: `${t('END_DATE')} ${endDate} `,
      }

    return { ...singleSubscriptionStatsData, gridName }
  } else {
    switch (type) {
      case 'all':
        return allStatsData
      case 'individual':
        return individualStatsData
      case 'professional':
        return professionalStatsData
      default:
        return {}
    }
  }
}

export const subscriptionsApi = {
  getSubscriptionsList,
  getSubscriptionsStats,
}
