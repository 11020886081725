import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider } from '@material-ui/styles'
import maTheme from './theme'

import Routes from './routes/Routes'
import { Snackbars } from 'components/Snackbars'
import { useInitialization } from 'utils/customHooks'

function App({ theme, eLearningInfo }) {
  const { t } = useTranslation('routes')
  const initialAuthDone = useInitialization()

  return (
    <>
      <Helmet titleTemplate="%s | Sound Care" defaultTitle={t('MUSIC_CARE_ADMIN')} />

      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <CssBaseline />
              {eLearningInfo && (
                <div id="drimify-container" style={{ lineHeight: 0 }}></div>
              )}
              {initialAuthDone && <Routes />}
              <Snackbars />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  )
}

const mapStateToProps = ({ theme, auth }) => ({
  eLearningInfo: auth.eLearningInfo,
  theme,
})

export default connect(mapStateToProps)(App)
