import {
  FAIL_FETCH_MUSIC,
  START_FETCH_GENRES,
  SET_ALL_SONGS,
  SET_ALL_GENRES,
  START_GET_GENRES,
  SUCCESS_GET_GENRES,
  FAIL_GET_GENRES,
  START_GET_SONGS,
  SUCCESS_GET_SONGS,
  FAIL_GET_SONGS,
} from '../constants'

const initialState = {
  allSongs: [],
  allGenres: [],
  allSongsMap: {},
  allGenresMap: {},
  isFetchingGenres: false,
  error: null,
  genres: [],
  genresMap: {},
  songs: [],
  songsMap: {},
}

const musicReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_SONGS: {
      const { allSongs, allSongsMap } = action.payload
      return { ...state, allSongs, allSongsMap }
    }

    case SET_ALL_GENRES: {
      const { allGenres, allGenresMap } = action.payload
      return {
        ...state,
        allGenres,
        allGenresMap,
        isFetchingGenres: false,
        error: null,
      }
    }

    case START_FETCH_GENRES: {
      return { ...state, isFetchingGenres: true, error: null }
    }

    case FAIL_FETCH_MUSIC: {
      return { ...state, error: action.payload }
    }

    case START_GET_GENRES: {
      return { ...state, isGettingGenres: true, error: null }
    }

    case SUCCESS_GET_GENRES: {
      const { genres, genresMap } = action.payload
      return {
        ...state,
        genres,
        genresMap,
        isGettingGenres: false,
        error: null,
      }
    }

    case FAIL_GET_GENRES: {
      return { ...state, isGettingGenres: false, error: action.payload }
    }

    case START_GET_SONGS: {
      return { ...state, isGettingSongs: true, error: null }
    }

    case SUCCESS_GET_SONGS: {
      const { songs, songsMap } = action.payload
      return {
        ...state,
        songs,
        songsMap,
        isGettingSongs: false,
        error: null,
      }
    }

    case FAIL_GET_SONGS: {
      return { ...state, isGettingSongs: false, error: action.payload }
    }

    default:
      return { ...state }
  }
}

export default musicReducer
