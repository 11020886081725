import { green, grey, red } from '@material-ui/core/colors'
import { formatDate, formatAmount, formatMoney, getSessionTimeFormat } from 'utils/format'
import { getAudioDuration } from 'utils/audio'
import { isMatch, compareDesc, isValid } from 'date-fns'
import { getCurrentLanguage } from 'translation'

export {
  getSessionTimeFormat,
  getAudioDuration,
  formatDate,
  formatDate as format,
  formatAmount,
  formatMoney,
}

export const getCellPercentColor = (val) =>
  val > 0 ? green[500] : val < 0 ? red[500] : grey[600]

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const descendingComparator = (a, b, orderBy) => {
  const formatPattern =
    process.env[`REACT_APP_DATE_FORMAT_${getCurrentLanguage().toUpperCase()}`]

  if (!isMatch(a[orderBy], formatPattern) && !isMatch(b[orderBy], formatPattern)) {
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1

    return 0
  } else {
    if (getCurrentLanguage() === 'fr')
      if (isMatch(a[orderBy], 'd/M/yy') || isMatch(b[orderBy], 'd/M/yy')) {
        const [dA, mA, yA] = a[orderBy]?.split('/') || ''
        const [dB, mB, yB] = b[orderBy]?.split('/') || ''

        return compareDesc(
          isValid(new Date(`${mB} ${dB} ${yB}`))
            ? new Date(`${mB} ${dB} ${yB}`)
            : new Date(null),
          isValid(new Date(`${mA} ${dA} ${yA}`))
            ? new Date(`${mA} ${dA} ${yA}`)
            : new Date(null)
        )
      }

    return compareDesc(new Date(b[orderBy]), new Date(a[orderBy]))
  }
}

export const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

// Helper function to extract specific string content
export const extractStringFragment = (content, regex) => {
  if (!content || !(regex instanceof RegExp)) return ''
  const result = regex.exec(content)
  return result ? result[1] : ''
}

export const isEmailFormat = (toCheck) =>
  !!toCheck.match(
    // Same regex as on backend
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

export const isTextFormat = (toCheck) => !!toCheck.match(/^[a-zA-Z0-9_\-\s'()]*$/)

export const doesEmailAlreadyExist = () => {
  // TODO once api up
  return false
}

export const startsWithVowel = (str) =>
  ['A', 'E', 'I', 'O', 'U'].includes((str[0] || '').toUpperCase())

export const getRealImageType = async (file) => {
  const fr = new FileReader()

  return new Promise((resolve) => {
    fr.onloadend = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4)

      const header = [...arr].map((el) => el.toString(16)).join('')

      // Check the file signature against known types
      switch (header) {
        case '89504e47':
          resolve('image/png')
          break

        case '47494638':
          resolve('image/gif')
          break

        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          resolve('image/jpeg')
          break

        case '25504446':
          resolve('application/pdf')
          break

        default:
          resolve('')
          break
      }
    }
    fr.readAsArrayBuffer(file)
  })
}

export const parseObjectFromConfig = (str) =>
  str.split(';').reduce((acc, pair) => {
    const [key, vals] = pair.split(':')
    acc[key] = vals.split(',')
    return acc
  }, {})
