import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrentLanguage } from 'translation'
import { connect } from 'react-redux'

const useStyles = makeStyles(() => ({
  footerFixed: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerRelative: {
    position: 'relative',
  },
}))

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
`

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`
const Footer = ({ footerFixed, isDoctor }) => {
  const classes = useStyles()
  const { t } = useTranslation('footer')
  const { t: tLinks } = useTranslation('links')

  const { support, terms, help, privacy, homepage } = {
    help: tLinks('FAQ'),
    homepage: tLinks('HOMEPAGE'),
    privacy: tLinks('PRIVACY'),
    support: tLinks('CONTACT'),
    terms: tLinks(isDoctor ? 'TERMS_DOCTOR' : 'TERMS_PRO'),
  }

  return (
    <Wrapper className={footerFixed ? classes.footerFixed : classes.footerRelative}>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem component="a" href={support}>
                <ListItemText primary={t('SUPPORT')} />
              </ListItem>
              <ListItem component="a" href={help}>
                <ListItemText primary={t('HELP_CENTER')} />
              </ListItem>
              <ListItem component="a" href={privacy}>
                <ListItemText primary={t('PRIVACY')} />
              </ListItem>
              <ListItem component="a" href={terms}>
                <ListItemText primary={t('TERMS_OF_SERVICE')} />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem component="a" href={homepage}>
              <ListItemText primary={`© ${new Date().getFullYear()} - Music Care`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

const mapStateToProps = ({ auth: { isDoctor } }) => ({ isDoctor })

export default connect(mapStateToProps)(Footer)

Footer.propTypes = {
  footerFixed: PropTypes.bool,
  isDoctor: PropTypes.bool.isRequired,
}
