import axios from 'axios'
import { apiRequest } from 'api/apiRequest'

const uploadAvatarToS3 = (putSignedUrl, avatarImg) => {
  return { data: avatarImg }
  // return apiRequest(putSignedUrl, {}).put({ avatarImg })
}

const getSignedPutImageUrl = ({ fileName, contentType, category }) =>
  apiRequest(
    `/file/signed-put-image-url?fileName=${fileName}&contentType=${contentType}&category=${category}`
  ).get()

const getSignedPutSongUrl = ({ fileName, contentType }) =>
  apiRequest(
    `/file/signed-put-song-url?fileName=${fileName}&contentType=${contentType}`
  ).get()

const uploadFileToS3 = async (signedUrl, file, contentType, progressCb) => {
  const parseToBinaryFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsArrayBuffer(file)
    })
  }

  const binaryFile = await parseToBinaryFile(file)

  try {
    const result = await axios.put(signedUrl, binaryFile, {
      onUploadProgress: (ProgressEvent) =>
        progressCb(ProgressEvent.loaded / ProgressEvent.total),
      headers: { 'Content-Type': contentType },
    })
    return { result }
  } catch (error) {
    return { error, result: {} }
  }
}

export const awsApi = {
  getSignedPutImageUrl,
  getSignedPutSongUrl,
  uploadAvatarToS3,
  uploadFileToS3,
}
