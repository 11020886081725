import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { routeLanguagePaths } from 'routes/constants'
import translations from 'translation/translations'

const allowedLanguages = ['en', 'fr']
const defaultLanguage = 'fr'

const { en, fr } = translations

// Initialize i18n package
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallBackLng: defaultLanguage,
    resources: {
      en,
      fr,
    },
    ns: ['general'],
    fallbackNS: 'general',
    keySeparator: false,
  })

export const setCurrentLanguage = async (lang, noReload) => {
  const parsedLang = (lang.includes('-') ? lang.split('-')[0] : lang).toLowerCase()

  if (!allowedLanguages.includes(parsedLang)) return
  if (parsedLang === getCurrentLanguage()) return

  await i18n.changeLanguage(parsedLang)
  document.documentElement.setAttribute('lang', lang)
  if (!noReload) setTimeout(() => window.location.reload(), 100)
}

export const getCurrentLanguage = () => {
  const currentLanguage = i18n.language.includes('-')
    ? i18n.language.split('-')[0]
    : i18n.language

  const currentLangLower = currentLanguage.toLowerCase()

  return allowedLanguages.includes(currentLangLower) ? currentLangLower : defaultLanguage
}

export const getLocalizedLink = (linkDataInput) => {
  // If no need for params, can pass just array instead of object
  const linkData =
    typeof linkDataInput === 'string'
      ? { path: [linkDataInput] }
      : Array.isArray(linkDataInput)
      ? { path: linkDataInput }
      : linkDataInput

  if (linkData.path[0] === '#') return '/#'

  const localizedPaths = routeLanguagePaths[getCurrentLanguage()]

  if (
    linkData.path[0] === 'accounts' &&
    linkData.path?.[1] &&
    /^[a-z0-9]{24}$/i.test(linkData.path[1])
  ) {
    const localizedPath = `${localizedPaths.accounts.all}/${linkData.path[1]}`
    return linkData.param ? `${localizedPath}/${linkData.param}` : localizedPath
  }

  const localizedPath = linkData.path.reduce((acc, linkEl) => acc[linkEl], localizedPaths)
  return linkData.param ? `${localizedPath}/${linkData.param}` : localizedPath
}

// Main function used for translation with useTranslation hook
export default i18n
