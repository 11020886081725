import { useParams, useRouteMatch } from 'react-router-dom'
import { getCurrentLanguage } from 'translation'

const useParsePath = ({ pathRoot, defaultTypes, dynamicTypeShape }) => {
  const lang = getCurrentLanguage()
  const { path } = useRouteMatch()
  const { id: requestedId } = useParams()

  const pathSuffix = path.replace(pathRoot, '')

  // Remove id param from end of path if loading specific user
  const typePath = pathSuffix.split('/')[0]
  const typesByLang = defaultTypes[lang]

  let type
  if (typePath === '') type = 'all'
  else {
    const delocalizedType = typesByLang[typePath]
    if (!delocalizedType && !dynamicTypeShape?.test(typePath)) return { isValid: false }
    type = delocalizedType || typePath
  }

  return {
    requestedId,
    type,
    isValid: true,
    isDynamic: !!dynamicTypeShape?.test(typePath),
  }
}

export default useParsePath
