import { representativesData } from './accountsDummyData'

import { format, subWeeks, subHours, subMinutes, subDays, subMonths } from 'date-fns'
import { random, range } from 'lodash'
import { getCurrentLanguage } from 'translation'

const indications = ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING']
const songs = [
  { songId: 40, titleFr: 'Yarik', titleEn: 'Yarik' },
  { songId: 8, titleFr: 'Ballade Jazzy', titleEn: 'Jazzy Ballad' },
  { songId: 12, titleFr: 'Electro Jazz', titleEn: 'Electro Jazz' },
  { songId: 47, titleFr: 'La voix des Elfes', titleEn: 'Voice of the Elves' },
  { songId: 17, titleFr: 'Légende celtique', titleEn: 'Celtic Legend' },
  { songId: 46, titleFr: 'Moonlight', titleEn: 'Moonlight' },
  { songId: 34, titleFr: 'Rêverie', titleEn: 'Reverie' },
  { songId: 35, titleFr: 'Pause Romantique', titleEn: 'Romantic Break' },
  { songId: 5, titleFr: 'Thème Classique', titleEn: 'Around the Piano 2' },
  { songId: 4, titleFr: 'Thème Baroque', titleEn: 'Around the ,Piano' },
  { songId: 42, titleFr: 'Pacific Islands', titleEn: 'Pacific Islands' },
  { songId: 38, titleFr: 'Folk Calédonien', titleEn: 'New Caledonian folk' },
  { songId: 20, titleFr: 'Trésors des Andes', titleEn: 'Andean Treasure' },
  { songId: 44, titleFr: 'Misik Ker', titleEn: 'Misik Ker' },
  { songId: 39, titleFr: 'Wheen Palic', titleEn: 'Wheen Palic' },
  { songId: 36, titleFr: 'Ballade pour Guitare', titleEn: 'Guitar Ballad' },
  { songId: 33, titleFr: 'Nuit cubaine', titleEn: 'Cuban Night' },
  { songId: 10, titleFr: "Cahier d'Orient", titleEn: 'Message from the Orient' },
  { songId: 32, titleFr: 'Inspiration Flamenca', titleEn: 'Flamenca Inspiration' },
  { songId: 31, titleFr: 'Cotton Blues', titleEn: 'Cotton Blues' },
  { songId: 9, titleFr: 'Ballade pour Piano', titleEn: 'Piano Ballad' },
  { songId: 7, titleFr: 'Thème Romantique', titleEn: 'Romantic Era' },
  { songId: 48, titleFr: 'Mississippi Blues', titleEn: 'Mississippi Blues' },
  { songId: 45, titleFr: 'Parfum do Brazil', titleEn: 'Parfum do Brazil' },
  { songId: 28, titleFr: 'Accordéon Lounge', titleEn: 'Accordion Lounge' },
  { songId: 13, titleFr: 'Evocation Indienne', titleEn: 'Indian Evocation' },
  { songId: 3, titleFr: 'Musique de Film', titleEn: 'Classical Harp' },
  { songId: 49, titleFr: 'Purple Rock', titleEn: 'Purple Rock' },
  { songId: 11, titleFr: "Douceur d'Asie", titleEn: 'Scents of Asia' },
  { songId: 14, titleFr: "Groove d'Afrique", titleEn: 'African Groove' },
  { songId: 16, titleFr: 'Guitare Rock', titleEn: 'Rock Guitar' },
  { songId: 15, titleFr: 'Guitare Folk', titleEn: 'Folk Guitar' },
  { songId: 43, titleFr: 'Lo Ker', titleEn: 'Lo Ker' },
  { songId: 37, titleFr: 'Afrothérapie', titleEn: 'Afrotherapy' },
  { songId: 41, titleFr: 'Reggae Calédonien', titleEn: 'Caledonian reggae' },
  { songId: 18, titleFr: 'Reggae Jamaïcain 1', titleEn: 'Jamaican Reggae 1' },
  { songId: 25, titleFr: 'Reggae Jamaïcain 2', titleEn: 'Jamaican Reggae 2' },
]

export const sessionItems = representativesData.map(({ name, id, referredDate }) => {
  const { songId, titleEn, titleFr } = songs[random(0, songs.length - 1)]
  const indication = indications[random(3)]
  const totalPerSession = random(1, 20)

  return {
    name,
    id,
    songId,
    titleEn,
    titleFr,
    totalPerSession,
    indication,
    date: referredDate,
    links: { title: { path: ['music', 'statistics'], param: songId } },
  }
})

export const musicItems = songs.map(({ songId, titleEn, titleFr }) => {
  const avgPainBefore = random(1, 10)
  const avgPainAfter = random(1, 10)
  const indication = indications[random(3)]

  return {
    songId,
    titleEn,
    titleFr,
    sessions: random(20, 60),
    avgPainBefore,
    avgPainAfter,
    indication,
    percentImprovement: Math.round(
      ((avgPainAfter - avgPainBefore) / avgPainBefore) * 100
    ),
    links: { title: { path: ['music', 'statistics'], param: songId } },
  }
})

const patientNames = [
  'Nedra Tomaszewski',
  'Margeret Appleberry',
  'Hermila Lanclos',
  'Alma Casiano',
  'Charlott Teamer',
  'Audrie Mollett',
  'Virgina Inskeep',
  'Pei Kohen',
  'Karyn Stockbridge',
  'Debbie Caperton',
  'Nicolette Marcin',
  'Lenna Lybarger',
  'Dinah Chrysler',
  'Kit Burchett',
  'Bryce Morelock',
  'Kelley Poor',
  'Sandy Shilling',
  'Lynwood Stark',
  'Erline Stork',
  'Garfield Szewczyk',
  'Esteban Caballero',
  'Veronica Fleischer',
  'Alessandra Hughey',
  'Justa Katzman',
  'Tashia Byford',
  'Carolin Beverly',
  'Lesley Rozell',
  'Rachael Beasley',
  'Magda Pawlak',
  'Bailey Imes',
  'Tiny Vanhouten',
  'Damien Robertson',
  'Laureen Auman',
  'Antwan Sarvis',
  'Victoria Lindley',
  'Kathlene Furman',
  'Orlando Holtz',
  'Esta Nguyen',
  'Leonarda Garduno',
  'Tana Charlebois',
  'Maurine Kesler',
  'Eilene Lease',
  'Devona Edward',
  'Claud Costigan',
  'Shane Reiff',
  'Letisha Tousant',
  'Porsche Schnabel',
  'Julienne Chatmon',
  'Josephine Sohn',
  'Yuonne Sprenger',
  'Maxie Schiro',
  'Fidela Meaux',
  'Jarvis Carnell',
  'Spring Barron',
  'Alicia Strelow',
  'Regine Fahie',
  'Archie Wadleigh',
  'Marlana Pharis',
  'Sofia Chartier',
  'Toshiko Rodgers',
  'Deedee Clift',
  'Alethea Rollo',
  'Sung Manner',
  'Lisha Demott',
  'Davis Michaels',
  'Betsy Steinhoff',
  'Peggie Riehl',
  'Clarissa Brinsfield',
  'Tamar Chin',
  'Else Rodney',
  'Alison Dan',
  'Genny Soderquist',
  'Ivana Coty',
  'Arlene Mogensen',
  'Wanda Agosto',
  'Renay Tovey',
  'Jamison Wegner',
  'Johnetta Hines',
  'Kerri Pascua',
  'Roseline Cambridge',
  'Janey Farrow',
  'Randell Hossain',
  'Eugena Okamura',
  'Ardith Reddick',
  'Dawn Guerrera',
  'Joellen Mungin',
  'Alisia Kmetz',
  'Maryrose Bains',
  'Ina Cumberbatch',
  'Kerstin Mcdonagh',
  'Santana Burrage',
  'Tia Sandin',
  'Marlys Allin',
  'Carlotta Crown',
  'Eliza Chaffins',
  'Vikki Thaler',
  'Glendora Bartlett',
  'Mertie Rooks',
  'Colene Tung',
  'Eustolia Reamer',
  'Asa Zeitler',
  'Bridgett Groesbeck',
  'Lin Post',
  'Monty Eakes',
  'Williemae Hopes',
  'Joanna Corner',
  'Terrie Mckay',
  'Grisel Mustard',
  'Trinh Lovette',
  'Leatha Ludlow',
  'Val Mills',
  'Antonietta Abrev',
  'Lisette Primo',
  'Erline Wittig',
  'Colette Vogel',
  'Alvera Mantz',
  'Benton Younker',
  'Lynn Lewin',
  'Remona Swain',
  'Verdell Saini',
  'Chas Swasey',
  'Danyel Dutcher',
  'Garry Gambrell',
  'Harland Still',
  'Magen Mull',
  'Oren Lor',
  'Leonida Sheffey',
  'Lucio Twyman',
  'Oda Klos',
  'Madlyn Pratt',
  'Marielle Marshburn',
  'Zofia Haggett',
  'Emory Feathers',
  'Kareen Tinkle',
  'Clorinda Hoak',
  'Tara Blaker',
  'Alane Rine',
  'Migdalia Ansari',
  'Hilde Zdenek',
  'Agnus Pearman',
  'Kimbery Dussault',
  'Ruthanne Garg',
  'Magan Quesinberry',
  'Hank Glasco',
  'Catrina Junior',
  'Eloise Donlan',
  'Shanae Brannen',
  'Hallie Knoll',
  'Linnie Gauer',
  'Cordie Kitts',
  'Winnie Herter',
  'Liz Creel',
  'Rosetta Numbers',
  'Scotty Just',
  'Leigha Mcmanus',
  'Ernestine Gallien',
  'Beth Hamid',
  'Yolando Raye',
  'Faustino Backlund',
  'Evette Edney',
  'Stacy Willmon',
  'Audrie Magill',
  'Lilliam Kerfien',
  'Maya Tillis',
  'Lashunda Ruth',
  'Ozella Whitesel',
  'Juanita Hannon',
  'Hulda Peralez',
  'Olga Haecker',
  'Mei Niver',
  'Vickie Siemers',
  'Temika Baggett',
  'Lyman Forman',
  'Dyan Feenstra',
  'Georgeanna Sampsel',
  'Jonie Bosque',
  'Cyndy Vandine',
  'Sueann Kinkel',
  'Sabra Bartel',
  'Kathyrn Chenier',
  'Magan Jeremiah',
  'Majorie Mackenzie',
  'Debra Murch',
  'Edmundo Janik',
  'Cathy Nunnally',
  'Georgann Ritchey',
  'Harold Evers',
  'Vanessa Quesenberry',
  'Raguel Minogue',
  'Collene Petroff',
  'Renato Canada',
  'Pamala Meals',
  'Woodrow Faul',
  'Beulah Cruse',
  'Alexandra Printz',
  'Tama Lahti',
  'Jackie Tichenor',
  'Coreen Marotz',
  'Rosalva Brockway',
  'Merle Grimmer',
  'Lola Bagby',
  'Delinda Weast',
  'Keenan Macdougall',
  'Mikaela Scherr',
  'Ivy Wingrove',
  'Dion Harford',
  'Bryant Hansell',
  'Neville Eliason',
  'Shawanna Fuchs',
  'Kristel Sousa',
  'Inez Soltani',
  'Kyoko Duck',
  'Jacquelyne Legros',
  'Aliza Sumler',
  'Gertrude Askins',
  'Shirlene Penland',
  'Renay Conine',
  'Bong Gamet',
  'Scot Holaday',
  'Wilson Killoran',
  'Travis Kocher',
  'Deandre Mannings',
  'Octavia Gonsalves',
  'Arnette Berrios',
  'Barbera Salls',
  'Marianne Silvey',
  'Vernie Whipps',
  'Antwan Sisler',
  'Bambi Heavrin',
  'Donette Bonier',
  'Eleni Cervantez',
  'Tiesha Dames',
  'Letha Lennon',
  'Jaclyn Serrano',
  'Alycia Mcwilliams',
  'Tasia Corn',
  'Lasonya Swett',
  'Maybelle Wilkin',
  'Billi Pilkington',
  'Ermelinda Hickmon',
  'Allyn Bacher',
  'Ahmed Haymon',
  'Christia Saulters',
  'Nichol Mcneilly',
  'Vasiliki Begay',
  'Oren Thames',
  'Isa Demartini',
  'Maryjo Swicegood',
  'Ling Runyan',
  'Teressa Kaelin',
]

const comments = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Odio ut enim blandit volutpat maecenas volutpat blandit.',
  'Cursus metus aliquam eleifend mi in nulla posuere.',
  'Id consectetur purus ut faucibus pulvinar elementum.',
  'Consequat id porta nibh venenatis cras sed felis.',
  'Netus et malesuada fames ac turpis.',
  'Tempor id eu nisl nunc mi ipsum.',
  'Eget mauris pharetra et ultrices neque ornare.',
  'Ipsum dolor sit amet consectetur adipiscing elit ut.',
  'Dignissim sodales ut eu sem integer vitae.',
  'Quis varius quam quisque id diam.',
  'Aliquam ut porttitor leo a diam sollicitudin.',
  'Purus sit amet luctus venenatis lectus magna.',
  'Massa placerat duis ultricies lacus.',
  'A arcu cursus vitae congue mauris rhoncus aenean.',
  'Porttitor leo a diam sollicitudin tempor id eu nisl.',
  'Tincidunt id aliquet risus feugiat in.',
  'Laoreet suspendisse interdum consectetur libero id faucibus.',
  'Libero enim sed faucibus turpis in.',
  'Non nisi est sit amet facilisis magna etiam tempor.',
  'Leo in vitae turpis massa sed elementum tempus egestas sed.',
  'Lacus laoreet non curabitur gravida arcu ac.',
  'Vehicula ipsum a arcu cursus vitae congue mauris rhoncus aenean.',
  'Nisi lacus sed viverra tellus.',
  'Imperdiet proin fermentum leo vel orci porta non pulvinar.',
  'Porta non pulvinar neque laoreet suspendisse interdum consectetur libero.',
  'Proin sed libero enim sed faucibus turpis in.',
  'Nulla facilisi nullam vehicula ipsum a.',
  'Ac turpis egestas integer eget aliquet nibh praesent tristique magna.',
  'Tortor id aliquet lectus proin nibh nisl.',
  'Erat pellentesque adipiscing commodo elit at imperdiet.',
  'Enim neque volutpat ac tincidunt vitae semper quis.',
  'Lectus urna duis convallis convallis tellus id interdum.',
  'Et ultrices neque ornare aenean euismod elementum.',
]

export const commentItems = patientNames.map((name, i) => {
  const { songId, titleEn, titleFr } = songs[random(songs.length - 1)]
  return {
    songId,
    titleEn,
    titleFr,
    commentId: String(5602359331477 + i),
    comment: comments[random(comments.length - 1)],
    status: random(100) < 85 ? 'approved' : random(100) < 50 ? 'pending' : 'archived',
    email: `${name.replace(/\s/g, '_')}@gmail.com`,
    date: format(subDays(new Date(), i), 'd/M/yy'),
  }
})

export const songStatisticsItems = indications.map((indication) => ({
  connections: random(400, 2000),
  indication,
}))
