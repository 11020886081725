import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { get } from 'lodash'
import AuthLayout from 'layouts/Auth'
import { getLocalizedLink } from 'translation'
import Page403 from 'pages/auth/Page403'

const PrivateRoute = ({
  isAuthenticated,
  render: Component,
  permission,
  permissions,
  noDoctor,
  isDoctor,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        (!permission || get(permissions, permission)) && (!noDoctor || !isDoctor) ? (
          <Component {...props} />
        ) : (
          <>
            <AuthLayout>
              <Page403 />
            </AuthLayout>
          </>
        )
      ) : (
        <Redirect to={getLocalizedLink({ path: ['auth', 'signIn'] })} />
      )
    }
  />
)

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isDoctor: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ auth: { isAuthenticated, attributes, groups } }) => ({
  isAuthenticated,
  permissions: attributes?.permissions || {},
  isDoctor: groups.includes('doctors'),
})

export default connect(mapStateToProps)(PrivateRoute)
