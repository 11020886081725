const bariolLetterSpacing = '0.035rem'
const typography = {
  useNextVariants: true,
  fontFamily: [
    'Bariol',
    'Nunito',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 14,
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: bariolLetterSpacing,
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: bariolLetterSpacing,
  },
  h3: {
    // letterSpacing: '1rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: bariolLetterSpacing,
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: bariolLetterSpacing,
  },
  h5: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: bariolLetterSpacing,
  },
  h6: {
    fontSize: '1.0625rem',
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: bariolLetterSpacing,
  },
  body1: {
    fontSize: 14,
    letterSpacing: bariolLetterSpacing,
  },
  body2: {
    fontSize: 16,
    letterSpacing: bariolLetterSpacing,
  },
  button: {
    textTransform: 'none',
    letterSpacing: bariolLetterSpacing,
  },
  caption: {
    letterSpacing: bariolLetterSpacing,
  },
}

export default typography
