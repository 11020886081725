import { sortBy, keyBy } from 'lodash'
import { musicApi } from 'api'

import {
  SET_ALL_SONGS,
  SET_ALL_GENRES,
  START_FETCH_GENRES,
  FAIL_FETCH_MUSIC,
  START_GET_GENRES,
  SUCCESS_GET_GENRES,
  FAIL_GET_GENRES,
  START_GET_SONGS,
  FAIL_GET_SONGS,
  SUCCESS_GET_SONGS,
} from 'redux/constants'

import { getSongImageAddress, getSongAudioAddress } from 'utils/http'
import { extractStringFragment } from 'utils'

const genreImages = {
  // Numbers below correspond to genre id
  1: 'rock.png',
  2: 'latin.png',
  3: 'classical.png',
}

export const getGenres = () => async (dispatch) => {
  dispatch({ type: START_GET_GENRES })

  const { result, error } = await musicApi.getGenres()
  if (error) return dispatch({ type: FAIL_GET_GENRES, payload: error })

  const { genres } = result
  const genresMap = keyBy(genres, 'id')

  dispatch({
    type: SUCCESS_GET_GENRES,
    payload: { genres, genresMap },
  })
}

export const getSongs = (type) => async (dispatch) => {
  dispatch({ type: START_GET_SONGS })

  const { result, error } = await musicApi.getSongs(type)
  if (error) return dispatch({ type: FAIL_GET_SONGS, payload: error })

  const { songs } = result
  const songsMap = keyBy(songs, 'id')

  dispatch({
    type: SUCCESS_GET_SONGS,
    payload: { songs, songsMap },
  })
}

export const fetchAllMusic = () => async (dispatch) => {
  dispatch({ type: START_FETCH_GENRES })
  try {
    const { result: fetchedGenres, genresErr } = await musicApi.getGenresOld()
    const { result: fetchedTranslationsEn, error: transErr } =
      await musicApi.getTranslations('en')

    if (genresErr || transErr) throw genresErr || transErr

    const { categories: translatedGenresInfoEn, products: translatedSongInfoEn } =
      fetchedTranslationsEn

    const parsedGenresInfo = sortBy(
      fetchedGenres.map(({ id_rubrique, nom, position }) => {
        const genreEn = translatedGenresInfoEn.find(
          ({ id }) => parseInt(id) === parseInt(id_rubrique)
        )
        const genreNameEn = JSON.parse(genreEn.valeur).nom
        const genreId = parseInt(id_rubrique)
        return {
          genreId,
          genreNameEn,
          genreNameFr: nom,
          position: parseInt(position),
          genreImage: genreImages[genreId],
        }
      }),
      'position'
    )

    const genresMap = keyBy(parsedGenresInfo, 'genreId')

    const parsedSongInfoEn = translatedSongInfoEn
      .map(({ id, valeur }) => ({
        songId: parseInt(id),
        ...JSON.parse(valeur),
      }))
      .map(({ songId, description, nom }) => ({
        songId,
        descriptionEn: description,
        titleEn: nom,
      }))

    const parsedSongMapEn = keyBy(parsedSongInfoEn, 'songId')

    const { result: fetchedSongs, error: songsErr } = await musicApi.getSongsOld()
    if (songsErr) throw songsErr

    const parsedSongs = fetchedSongs.map((song) => {
      const {
        nom: titleFr,
        id_produit,
        id_rubrique,
        image: imageName,
        gratuite,
        longueur,
        nouveaute,
        position,
        temps_1,
        temps_2,
        temps_3,
        thumbnail: thumbnailName,
        updated,
        description: descrArtistInstrFr,
      } = song

      const songId = parseInt(id_produit)

      const { descriptionEn: descrArtistInstrEn, titleEn } = parsedSongMapEn[songId] || {}

      const genreId = parseInt(id_rubrique)
      const genreNameEn = (genresMap[genreId] || {}).genreNameEn || ''
      const genreNameFr = (genresMap[genreId] || {}).genreNameFr || ''
      const imageUrl = getSongImageAddress(imageName)
      const fileUrl = getSongAudioAddress(songId)
      const thumbnail = getSongImageAddress(thumbnailName)

      const artistRegex = /Compositeur : (.*?)\n/
      const descriptionRegexEn = /Description : (.*?)\n/
      const descriptionRegexFr = /Descriptif : (.*?)\n/
      const instrumentsRegex = /Instruments : (.*?)$/

      const artist = extractStringFragment(descrArtistInstrFr, artistRegex)
      const descriptionEn =
        extractStringFragment(descrArtistInstrEn, descriptionRegexEn) + '.'
      const descriptionFr =
        extractStringFragment(descrArtistInstrFr, descriptionRegexFr) + '.'
      const instrumentsEn = extractStringFragment(
        descrArtistInstrEn,
        instrumentsRegex
      ).split(', ')
      const instrumentsFr = extractStringFragment(
        descrArtistInstrFr,
        instrumentsRegex
      ).split(', ')

      // Make sure both arrays are of equal length
      // TODO Remove once make sure all instrument arrays in stored data are of equal length on each song
      if (instrumentsEn.length < instrumentsFr.length) {
        instrumentsFr.forEach((_, i) => {
          if (i >= instrumentsEn.length) instrumentsEn[i] = null
        })
      } else if (instrumentsFr.length < instrumentsEn.length) {
        instrumentsEn.forEach((_, i) => {
          if (i >= instrumentsFr.length) instrumentsFr[i] = null
        })
      }

      const numSongs = genresMap[genreId].numberOfSongs
      genresMap[genreId].numberOfSongs = numSongs ? numSongs + 1 : 1

      return {
        artist,
        fileUrl,
        genreId,
        genreNameEn,
        genreNameFr,
        imageName,
        imageUrl,
        songId,
        titleEn,
        titleFr,
        descriptionEn,
        descriptionFr,
        instrumentsEn,
        instrumentsFr,
        gratuite,
        isNew:
          parseInt(nouveaute) === 0
            ? ''
            : Math.random() > 0.5
            ? '2020-08-20'
            : '2020-10-20',
        songLength: parseInt(longueur),
        status: Math.random() > 0.8 ? 'archived' : 'active',
        position: parseInt(position),
        time_1: parseInt(temps_1),
        time_2: parseInt(temps_2),
        time_3: parseInt(temps_3),
        thumbnail,
        updated,
      }
    })

    const sortedSongs = sortBy(parsedSongs, ['artist', 'title'])
    const allSongsMap = keyBy(sortedSongs, 'songId')
    dispatch({
      type: SET_ALL_GENRES,
      payload: { allGenresMap: genresMap, allGenres: parsedGenresInfo },
    })
    dispatch({
      type: SET_ALL_SONGS,
      payload: { allSongs: sortedSongs, allSongsMap },
    })
  } catch (err) {
    dispatch({ type: FAIL_FETCH_MUSIC, payload: err })
  }
}

export const deleteGenre =
  (genreIdToDelete, genreIdToChangeSongsTo) => async (dispatch) => {
    const { result, error } = await musicApi.deleteGenre(
      genreIdToDelete,
      genreIdToChangeSongsTo
    )

    dispatch(fetchAllMusic())
    return { result, error }
  }

export const changeSongPositions = (from, to) => async (dispatch) => {}

export const changeGenrePositions = (from, to) => async (dispatch) => {}
