import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Power } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Menu, MenuItem, IconButton as MuiIconButton } from '@material-ui/core'

import { signOut } from 'redux/actions'
import { getLocalizedLink } from 'translation'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const HeaderUserMenu = ({ signOut }) => {
  const { t } = useTranslation('header')
  const history = useHistory()
  const [anchorMenu, setAnchorMenu] = useState(null)

  const toggleMenu = (event) => setAnchorMenu(event.currentTarget)
  const closeMenu = () => setAnchorMenu(null)

  const signOutHandler = () => {
    closeMenu()
    signOut()
  }

  const goToProfile = () => {
    closeMenu()
    history.push(getLocalizedLink('profile'))
  }

  return (
    <>
      <IconButton
        aria-owns={!!anchorMenu ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={closeMenu}
      >
        {/* TODO: Enable profile once done in next stage */}
        {/* <MenuItem onClick={goToProfile}>{t('PROFILE')}</MenuItem> */}
        <MenuItem onClick={signOutHandler}>{t('SIGN_OUT')}</MenuItem>
      </Menu>
    </>
  )
}

const willMapDispatchToProps = {
  signOut,
}

export default connect(null, willMapDispatchToProps)(HeaderUserMenu)

HeaderUserMenu.propTypes = {
  signOut: PropTypes.func.isRequired,
}
