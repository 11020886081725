import { SET_PRO_ACCOUNT_ROUTES, SET_PRO_ACCOUNT_TYPES } from '../constants'
import { keyBy } from 'lodash'

const initialState = {
  proAccountTypes: null,
  proAccountTypesMap: null,
  proAccountRoutes: [],
}

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRO_ACCOUNT_TYPES: {
      const proAccountTypes = action.payload
      const proAccountTypesMap = proAccountTypes && keyBy(proAccountTypes, 'id')

      return {
        ...state,
        proAccountTypes,
        proAccountTypesMap,
      }
    }

    case SET_PRO_ACCOUNT_ROUTES: {
      const proAccountRoutes = action.payload || []

      return {
        ...state,
        proAccountRoutes,
      }
    }

    default:
      return { ...state }
  }
}

export default accountsReducer
