export const routeLanguagePaths = {
  en: {
    accounts: {
      all: '/accounts',
      distributions: `/distributions`,
      patients: '/accounts/patients',
      myProAccount: '/accounts/my-pro-account',
      // TODO: Add doctors
      // doctors: '/accounts/doctors'
    },
    auth: {
      signIn: '/sign-in',
      setNewPassword: '/set-new-password',
      resetPassword: '/reset-password',
    },
    createAccount: {
      distribution: `/create-distribution`,
      doctor: '/create-account/doctor',
      pro: '/create-account/pro',
      individual: '/create-account/individual',
    },
    editAccount: '/edit-account',
    dashboard: '/dashboard',
    invitations: '/invitations',
    music: {
      genres: '/music/genres',
      songs: '/music/songs',
      statistics: '/music/statistics',
    },
    profile: '/profile',
    register: {
      doctor: '/register-doctor',
    },
    subscriptions: {
      all: '/subscriptions',
      individual: '/subscriptions/individual',
      professional: '/subscriptions/professional',
    },
    training: '/training',
  },
  fr: {
    accounts: {
      all: '/comptes',
      distributions: `/distributions`,
      patients: '/comptes/patients',
      myProAccount: '/comptes/mon-compte-pro',
      // TODO: Add doctors
      // doctors: '/comptes/medecins'
    },
    auth: {
      signIn: '/se-connecter',
      setNewPassword: '/definir-mot-de-passe',
      resetPassword: '/reinitialiser-mot-de-passe',
    },
    createAccount: {
      distribution: `/creer-distribution`,
      doctor: '/creer-compte/medecin',
      pro: '/creer-compte/pro',
      individual: '/creer-compte/individuel',
    },
    editAccount: '/editer-compte',
    dashboard: '/dashboard',
    invitations: '/invitations',
    music: {
      genres: '/musique/categorie',
      songs: '/musique/seances',
      statistics: '/musique/statistiques',
    },
    profile: '/profil',
    register: {
      doctor: '/inscription-medecin',
    },
    subscriptions: {
      all: '/abonnements',
      individual: '/abonnements/individuel',
      professional: '/abonnements/professionnel',
    },
    training: '/formation',
  },
}
