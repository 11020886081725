import { subWeeks, subHours, subMinutes, subDays, subMonths } from 'date-fns'
import { random } from 'lodash'
import { getCurrentLanguage } from 'translation'
import { formatDate } from 'utils'

const lang = getCurrentLanguage()
const countries = {
  en: {
    usa: 'USA',
    france: 'France',
  },
  fr: {
    usa: 'Etats-Unis',
    france: 'France',
  },
}

const localizedCountries = countries[lang]

const songs = ['Yarik', 'Electro Jazz', 'Moonlight', 'Reverie', 'Mississippi Blues']
const songsFr = ['Yarik', 'Electro Jazz', 'Moonlight', 'Rêverie', 'Mississippi Blues']

const names = [
  'Sherlyn Whidbee',
  'Maisie Digirolamo',
  'Cortney Borge',
  'Gaynell Magyar',
  'Nilsa Hedges',
  'Corazon Rostad',
  'Mahalia Chiaramonte',
  'Taylor Hymel',
  'Mohammad Weinmann',
  'Ronda Pitkin',
  'Rosario Tyner',
  'Jennifer Seely',
  'Demetra Thoreson',
  'Grant Foor',
  'Belia Gatling',
  'Keila Vanduzer',
  'Priscila Roff',
  'Tawny Eichman',
  'Harrison Maresca',
  'Dalene Bridgman',
  'Leanne Younce',
  'Laveta Summerford',
  'Bradford Izaguirre',
  'Scot Lafromboise',
  'Royce Stainbrook',
  'Azzie Delee',
  'Roma Pichon',
  'Annelle Dials',
  'Cecile Roache',
  'Thelma Tong',
  'Dione Rasor',
  'Wanetta Vankirk',
  'Jamal Bonebrake',
  'Bernardo Boydstun',
  'Naomi Eargle',
  'Marietta Hiner',
  'Harriet Hayles',
  'Abbey Keasler',
  'Shamika Kovacich',
  'Krysten Gamino',
  'Nu Lion',
  'Jennell Saba',
  'Oma Watkin',
  'Lane Gillison',
  'Rowena Harris',
  'Dusty Blazek',
  'Jackie Ton',
  'Alline Haye',
  'Dianna Timpson',
  'Roosevelt Pullin',
  'Marissa Jinkins',
  'Hyun Spinney',
  'Janae Hanus',
  'Charlena Liberty',
  'Tawanna Sugrue',
  'Noreen Bushey',
  'Marquerite Pledger',
  'Judith Betton',
  'Marsha Hammonds',
  'Jaqueline Rustad',
  'Benny Negrete',
  'Melita Leflore',
  'Marcy Waldrip',
  'Laverne Kukla',
  'Hosea Conine',
  'Adalberto Highsmith',
  'Noma Chevalier',
  'Mellissa Valls',
  'Chloe Carlyle',
  'Sophie Jakubowski',
  'Jarrett Haslem',
  'Nana Shor',
  'Otis Schill',
  'Meagan Shkreli',
  'Rodrick Maloy',
  'Jordan Coss',
  'Sonny Stribling',
  'Markita Munroe',
  'Stephen Ramaker',
  'Glenda Peraza',
  'Chelsie Balls',
  'Bobbye Swain',
  'Raeann Hanway',
  'Julio Geeter',
  'Lisa Ringgold',
  'Iris Rowan',
  'Terra Oliva',
  'Shirly Mcnair',
  'Gracie Tew',
  'Piper Quattlebaum',
  'Claudia Petersen',
  'Melodi Coan',
  'Shea Ranck',
  'Kala Shrock',
  'Quinn Brooke',
  'Chante Perkinson',
  'Ammie Winans',
  'Emeline Lovely',
  'Latarsha Ericson',
  'Jerri Knauer',
  'Albertine Dalal',
  'Lyda Krupp',
  'Andres Gibb',
  'Vallie Wyrick',
  'Carry Dingess',
  'Wen Sowards',
  'Theodore Loe',
  'Galina Mcgivney',
  'Vera Cantor',
  'Darcel Bergerson',
  'Pete Jeansonne',
  'Alpha Haltom',
  'Wanda Lex',
  'Annis Darnall',
  'Rachele Counter',
  'Deanne Slade',
  'Drema Mcnab',
  'Dario Carwell',
  'Larue Ruby',
  'Elanor Mcgeehan',
  'Latrice Corrado',
  'Cecelia Dorfman',
  'Lurlene Luk',
  'Sol Jandreau',
  'Zenaida Storms',
  'Kathrin Linscott',
  'Solomon Seats',
  'Velma Heredia',
  'Doria Windley',
  'Oralia Lawless',
  'Vertie Hovis',
  'Cierra Hypes',
  'Vi Blakeman',
  'Rosenda Bier',
  'Xuan Gawronski',
  'Bessie Briggs',
  'Estella Beauregard',
  'Lillian Lanford',
  'Adela Nave',
  'Lasonya Brashear',
  'Karry Ashley',
  'Leonie Coderre',
  'Vernetta Rochelle',
  'Harris Perrine',
  'Myles Mazzola',
  'Cristen Mcduff',
  'Candi Calaway',
  'Fransisca Embry',
  'Bella Hoppes',
  'Diane Cree',
  'Na Holder',
  'Elma Yellowhair',
  'Mariana Critelli',
  'Regina Ebright',
  'Beatris Pray',
  'Libby Lebron',
  'Blondell Mifflin',
  'Melodie Gossett',
  'Elliot Condict',
  'Darrin Mccullum',
  'Loni Mcentee',
  'Soledad Delorey',
  'Ma Schrimsher',
  'Sharell Straley',
  'Ignacia Stecklein',
  'Laurie Philippe',
  'Theo Carwile',
  'Aleen Pretty',
  'Ria Upson',
  'Elisha Charron',
  'Marlana Pohlmann',
  'Daisey Spero',
  'Andy Patti',
  'Ashlee Milazzo',
  'Leena Walraven',
  'Donetta Loan',
  'Lillian Hobgood',
  'Staci Duncan',
  'Laraine Bruso',
  'Ilona Breiner',
  'Julee Hoover',
  'Becky Maxon',
  'Iraida Yip',
  'Mignon Coltharp',
  'Felton Fenn',
  'Lavonne Cosme',
  'Penelope Bays',
  'Winnie Vigna',
  'Mitzi Salyers',
  'Art Krider',
  'Talitha Heskett',
  'Guillermo Rolando',
  'Orlando Pegues',
  'Marguerite Crinklaw',
  'Scot Aubry',
  'Jodi Formby',
  'Edda Mathisen',
  'Hallie Cohan',
  'Ta Eynon',
  'Michiko Shingleton',
  'Olen Olah',
  'Rosana Weaver',
  'Shirlene Ortez',
  'Loise Oyama',
  'Bennie Lotz',
  'Miranda Busby',
  'Florene Dollard',
  'Sherley Trial',
  'Lura Olaughlin',
  'Shawanna Callas',
  'Nora Neidert',
  'Russel Kitchen',
  'Bertha Pyles',
  'Jani Calaway',
  'Eveline Cardamone',
  'Iris Barnard',
  'Neil Moises',
  'Maribeth Wacaster',
  'Abel Cervantez',
  'Mona Havener',
  'Brenton Mckay',
  'Cheree Paterson',
  'Diana Danaher',
  'Vaughn Thibeaux',
  'Cecil Haymon',
  'Silvana Lovett',
  'Arron Petry',
  'Sharee Colburn',
  'Joslyn Anzaldua',
  'Moira Upham',
  'Farah Franco',
  'Vera Beedle',
  'Hsiu Brigmond',
  'Deon Vancleave',
  'Nicol Jelinek',
  'Freddy Eargle',
  'Alton Blosser',
  'Evelia Fortune',
  'Bong Shuck',
  'Violette Crick',
  'Quyen Barefield',
  'Joline Neve',
  'Margy Fragoso',
  'Andree Ledford',
  'Elise Propst',
  'Tai Haycock',
  'Guadalupe Chicoine',
  'Dolly Llanes',
  'Domonique Krizan',
  'Meta Armistead',
  'Conchita Sera',
  'Pierre Clutter',
  'Kina Sherer',
  'Reena Cota',
  'Daniell Venzon',
  'Janay Coffin',
  'Jack Dengler',
  'Violette Blanche',
  'Daina Viruet',
  'Kory Harned',
  'Cami Strode',
  'Serita Hayden',
  'Bettyann Graybill',
  'Marleen Zwiebel',
  'Demetria Kautz',
  'Wenona Parks',
  'Tam Tjaden',
  'Alix Garth',
  'Diamond Torsiello',
  'Elinor Tash',
  'Shelby Gulotta',
  'Leandro Yin',
  'Donita Elling',
  'Brenda Comes',
  'Veronique Hollingworth',
  'Darby Concha',
  'Georgeann Timothy',
  'Babara Ngo',
  'Jona Page',
  'Laurice Milligan',
  'Shonda Tyra',
  'Margie Koeppel',
  'Celeste Koepp',
  'My Albarran',
  'Hortense Tacy',
  'Rosemary Aurand',
  'Jayne Reulet',
  'Felicia Ferriera',
  'Rosalie Hearn',
  'Shawana Temple',
  'Margarita Steck',
  'Li Madlock',
  'Eryn Federico',
  'Gillian Pannone',
  'Lelah Nordstrom',
  'Xenia Stander',
  'Marvis Houghton',
  'Maragaret Engstrom',
  'Pa Salzano',
  'Tammara Mickey',
]
const repArray = names.slice(0, Math.floor(names.length / 2))
const docArray = names.slice(Math.floor(names.length / 2))

export const representativesData = repArray
  .map((fullName, i) => {
    const [firstName, lastName] = fullName.split(' ')
    const type = 'REPRESENTATIVE'
    const email = `${firstName}@${lastName}.com`
    const referrals = Math.ceil(Math.random() * 100)

    return { id: String(1602059431477 + i), type, firstName, lastName, email, referrals }
  })
  .map(({ firstName, lastName, email, referrals, id, type }) => ({
    id,
    name: `${firstName[0]} ${lastName}`,
    fullName: `${firstName} ${lastName}`,
    email,
    referrals,
    links: { name: { path: ['accounts', 'representatives'], param: id } },
    company: Math.random() > 0.5 ? 'Sanofi' : 'Good Company',
    startDate: formatDate(subWeeks(new Date(), 5)),
    type,
  }))

export const doctorsData = docArray
  .map((fullName, i) => {
    const [firstName, lastName] = fullName.split(' ')
    const type = 'DOCTOR'
    const email = `${firstName}@${lastName}.com`
    const referrals = random(1, 100)
    const representative = repArray[Math.floor((repArray.length - 1) * Math.random())]
    const avgAfter = random(0, 10)
    const avgBefore = random(0, 10)
    const avgImprovement = random(-100, 100)
    const patients = 30 * random(0, 8) + random(0, 10)
    const sessions = patients * random(1, 10)
    const referrerName = representativesData[representativesData.length - 1].fullName
    const referredDate = formatDate(subWeeks(new Date(), 4))
    const organization = 'CHU (France)'
    return {
      id: String(1602059763477 + i),
      type,
      firstName,
      lastName,
      email,
      referrals,
      representative,
      avgAfter,
      avgBefore,
      avgImprovement,
      patients,
      sessions,
      referrerName,
      referredDate,
      organization,
    }
  })
  .map(
    ({
      avgAfter,
      avgBefore,
      avgImprovement,
      email,
      firstName,
      id,
      lastName,
      referrals,
      representative,
      patients,
      sessions,
      referrerName,
      referredDate,
      organization,
      type,
    }) => ({
      id,
      name: `${firstName[0]} ${lastName}`,
      fullName: `${firstName} ${lastName}`,
      email,
      representative,
      referrals,
      avgAfter,
      avgBefore,
      avgImprovement,
      links: { name: { path: ['accounts', 'doctors'], param: id } },
      isPro: random(10) < 7,
      mostPlayed: songs[random(0, 4)],
      patients,
      sessions,
      referrerName,
      referredDate,
      organization,
      type,
    })
  )

const patientNames = [
  'Nedra Tomaszewski',
  'Margeret Appleberry',
  'Hermila Lanclos',
  'Alma Casiano',
  'Charlott Teamer',
  'Audrie Mollett',
  'Virgina Inskeep',
  'Pei Kohen',
  'Karyn Stockbridge',
  'Debbie Caperton',
  'Nicolette Marcin',
  'Lenna Lybarger',
  'Dinah Chrysler',
  'Kit Burchett',
  'Bryce Morelock',
  'Kelley Poor',
  'Sandy Shilling',
  'Lynwood Stark',
  'Erline Stork',
  'Garfield Szewczyk',
  'Esteban Caballero',
  'Veronica Fleischer',
  'Alessandra Hughey',
  'Justa Katzman',
  'Tashia Byford',
  'Carolin Beverly',
  'Lesley Rozell',
  'Rachael Beasley',
  'Magda Pawlak',
  'Bailey Imes',
  'Tiny Vanhouten',
  'Damien Robertson',
  'Laureen Auman',
  'Antwan Sarvis',
  'Victoria Lindley',
  'Kathlene Furman',
  'Orlando Holtz',
  'Esta Nguyen',
  'Leonarda Garduno',
  'Tana Charlebois',
  'Maurine Kesler',
  'Eilene Lease',
  'Devona Edward',
  'Claud Costigan',
  'Shane Reiff',
  'Letisha Tousant',
  'Porsche Schnabel',
  'Julienne Chatmon',
  'Josephine Sohn',
  'Yuonne Sprenger',
  'Maxie Schiro',
  'Fidela Meaux',
  'Jarvis Carnell',
  'Spring Barron',
  'Alicia Strelow',
  'Regine Fahie',
  'Archie Wadleigh',
  'Marlana Pharis',
  'Sofia Chartier',
  'Toshiko Rodgers',
  'Deedee Clift',
  'Alethea Rollo',
  'Sung Manner',
  'Lisha Demott',
  'Davis Michaels',
  'Betsy Steinhoff',
  'Peggie Riehl',
  'Clarissa Brinsfield',
  'Tamar Chin',
  'Else Rodney',
  'Alison Dan',
  'Genny Soderquist',
  'Ivana Coty',
  'Arlene Mogensen',
  'Wanda Agosto',
  'Renay Tovey',
  'Jamison Wegner',
  'Johnetta Hines',
  'Kerri Pascua',
  'Roseline Cambridge',
  'Janey Farrow',
  'Randell Hossain',
  'Eugena Okamura',
  'Ardith Reddick',
  'Dawn Guerrera',
  'Joellen Mungin',
  'Alisia Kmetz',
  'Maryrose Bains',
  'Ina Cumberbatch',
  'Kerstin Mcdonagh',
  'Santana Burrage',
  'Tia Sandin',
  'Marlys Allin',
  'Carlotta Crown',
  'Eliza Chaffins',
  'Vikki Thaler',
  'Glendora Bartlett',
  'Mertie Rooks',
  'Colene Tung',
  'Eustolia Reamer',
  'Asa Zeitler',
  'Bridgett Groesbeck',
  'Lin Post',
  'Monty Eakes',
  'Williemae Hopes',
  'Joanna Corner',
  'Terrie Mckay',
  'Grisel Mustard',
  'Trinh Lovette',
  'Leatha Ludlow',
  'Val Mills',
  'Antonietta Abrev',
  'Lisette Primo',
  'Erline Wittig',
  'Colette Vogel',
  'Alvera Mantz',
  'Benton Younker',
  'Lynn Lewin',
  'Remona Swain',
  'Verdell Saini',
  'Chas Swasey',
  'Danyel Dutcher',
  'Garry Gambrell',
  'Harland Still',
  'Magen Mull',
  'Oren Lor',
  'Leonida Sheffey',
  'Lucio Twyman',
  'Oda Klos',
  'Madlyn Pratt',
  'Marielle Marshburn',
  'Zofia Haggett',
  'Emory Feathers',
  'Kareen Tinkle',
  'Clorinda Hoak',
  'Tara Blaker',
  'Alane Rine',
  'Migdalia Ansari',
  'Hilde Zdenek',
  'Agnus Pearman',
  'Kimbery Dussault',
  'Ruthanne Garg',
  'Magan Quesinberry',
  'Hank Glasco',
  'Catrina Junior',
  'Eloise Donlan',
  'Shanae Brannen',
  'Hallie Knoll',
  'Linnie Gauer',
  'Cordie Kitts',
  'Winnie Herter',
  'Liz Creel',
  'Rosetta Numbers',
  'Scotty Just',
  'Leigha Mcmanus',
  'Ernestine Gallien',
  'Beth Hamid',
  'Yolando Raye',
  'Faustino Backlund',
  'Evette Edney',
  'Stacy Willmon',
  'Audrie Magill',
  'Lilliam Kerfien',
  'Maya Tillis',
  'Lashunda Ruth',
  'Ozella Whitesel',
  'Juanita Hannon',
  'Hulda Peralez',
  'Olga Haecker',
  'Mei Niver',
  'Vickie Siemers',
  'Temika Baggett',
  'Lyman Forman',
  'Dyan Feenstra',
  'Georgeanna Sampsel',
  'Jonie Bosque',
  'Cyndy Vandine',
  'Sueann Kinkel',
  'Sabra Bartel',
  'Kathyrn Chenier',
  'Magan Jeremiah',
  'Majorie Mackenzie',
  'Debra Murch',
  'Edmundo Janik',
  'Cathy Nunnally',
  'Georgann Ritchey',
  'Harold Evers',
  'Vanessa Quesenberry',
  'Raguel Minogue',
  'Collene Petroff',
  'Renato Canada',
  'Pamala Meals',
  'Woodrow Faul',
  'Beulah Cruse',
  'Alexandra Printz',
  'Tama Lahti',
  'Jackie Tichenor',
  'Coreen Marotz',
  'Rosalva Brockway',
  'Merle Grimmer',
  'Lola Bagby',
  'Delinda Weast',
  'Keenan Macdougall',
  'Mikaela Scherr',
  'Ivy Wingrove',
  'Dion Harford',
  'Bryant Hansell',
  'Neville Eliason',
  'Shawanna Fuchs',
  'Kristel Sousa',
  'Inez Soltani',
  'Kyoko Duck',
  'Jacquelyne Legros',
  'Aliza Sumler',
  'Gertrude Askins',
  'Shirlene Penland',
  'Renay Conine',
  'Bong Gamet',
  'Scot Holaday',
  'Wilson Killoran',
  'Travis Kocher',
  'Deandre Mannings',
  'Octavia Gonsalves',
  'Arnette Berrios',
  'Barbera Salls',
  'Marianne Silvey',
  'Vernie Whipps',
  'Antwan Sisler',
  'Bambi Heavrin',
  'Donette Bonier',
  'Eleni Cervantez',
  'Tiesha Dames',
  'Letha Lennon',
  'Jaclyn Serrano',
  'Alycia Mcwilliams',
  'Tasia Corn',
  'Lasonya Swett',
  'Maybelle Wilkin',
  'Billi Pilkington',
  'Ermelinda Hickmon',
  'Allyn Bacher',
  'Ahmed Haymon',
  'Christia Saulters',
  'Nichol Mcneilly',
  'Vasiliki Begay',
  'Oren Thames',
  'Isa Demartini',
  'Maryjo Swicegood',
  'Ling Runyan',
  'Teressa Kaelin',
]

export const patientsData = patientNames.map((fullName, i) => {
  const type = 'PATIENT'
  const [firstName, lastName] = fullName.split(' ')
  const email = `${firstName}.${lastName}@gmail.com`
  const age = Math.ceil(Math.random() * 20) + 40
  const sessions = Math.ceil(Math.random() * 200)
  const gender = Math.random() > 0.5 ? 'M' : 'F'
  const avgBefore = random(0, 10)
  const avgAfter = random(0, 10)
  const avgImprovement = Math.ceil(Math.random() * 200) - 100
  const mostPlayed = songs[random(0, 4)]
  const id = String(1603386645358 + i)
  const referrerName = doctorsData[doctorsData.length - 1].fullName
  const referredDate = formatDate(subWeeks(new Date(), 4))
  return {
    id,
    type,
    email,
    gender,
    age,
    sessions,
    referrerName,
    referredDate,
    avgAfter,
    avgBefore,
    avgImprovement,
    mostPlayed,
    links: {
      email: { path: ['accounts', 'patients'], param: id },
      healthRecord: { path: [`#`] },
    },
  }
})

export const patientsStatsData = {
  gridStats: [
    {
      title: 'TOTAL_PATIENTS',
      amount: '1,240',
    },
    {
      title: 'NUMBER_OF_LISTENS',
      amount: '2,334',
    },
  ],
  table: [
    {
      id: 'songsPlayedData',
      headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
      data: [
        [{ cellData: 'Yarik', isHead: true, noTranslate: true }, { cellData: 320 }],

        [
          { cellData: 'Ballade Jazy', isHead: true, noTranslate: true },
          { cellData: 220 },
        ],

        [
          { cellData: 'Electro Jazz', isHead: true, noTranslate: true },
          { cellData: 110 },
        ],
        [{ cellData: 'Reverie', isHead: true, noTranslate: true }, { cellData: 75 }],
      ],
    },
    {
      id: 'indicationData',
      headCells: [
        { headCell: 'INDICATION' },
        { headCell: 'CONNECTIONS' },
        { headCell: 'CHANGE' },
      ],
      data: [
        [
          { cellData: 'PAIN', isHead: true },
          { cellData: 100 },
          { cellData: 6, isPercent: true },
        ],

        [
          { cellData: 'ANXIETY', isHead: true },
          { cellData: 90 },
          { cellData: -8, isPercent: true },
        ],

        [
          { cellData: 'SLEEP', isHead: true },
          { cellData: 13 },
          { cellData: 0, isPercent: true },
        ],
        [
          { cellData: 'AWAKENING', isHead: true },
          { cellData: 40 },
          { cellData: 3, isPercent: true },
        ],
      ],
    },
  ],
  charts: [
    [
      {
        type: 'barMultiple',
        height: '235px',
        chartData: {
          alwaysShowTooltips: true,
          noTooltipsTitles: true,
          chartWidth: '95',
          title: `CHANGE`,
          key: 'beforeAfterComparison',
          data: [
            {
              labels: ['PAIN_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [10],
                },
              ],
              group: 0,
            },
            {
              labels: ['PAIN_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [3],
                },
              ],
              group: 0,
            },
            {
              labels: ['ANXIETY_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [4],
                },
              ],
              group: 1,
            },
            {
              labels: ['ANXIETY_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [7],
                },
              ],
              group: 1,
            },
            {
              labels: ['SLEEP_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [9],
                },
              ],
              group: 2,
            },
            {
              labels: ['AWAKENING_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [6],
                },
              ],
              group: 3,
            },
          ],
        },
      },
    ],
  ],
}

export const patientStatsData = {
  gridStats: [
    {
      title: 'NUMBER_OF_LISTENS',
      amount: '2,333',
    },
  ],
  table: [
    {
      id: 'songsPlayedData',
      headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
      data: [
        [{ cellData: 'Yarik', isHead: true, noTranslate: true }, { cellData: 320 }],

        [
          { cellData: 'Ballade Jazy', isHead: true, noTranslate: true },
          { cellData: 220 },
        ],

        [
          { cellData: 'Electro Jazz', isHead: true, noTranslate: true },
          { cellData: 110 },
        ],
        [{ cellData: 'Reverie', isHead: true, noTranslate: true }, { cellData: 75 }],
      ],
    },
    {
      id: 'indicationData',
      headCells: [
        { headCell: 'INDICATION' },
        { headCell: 'CONNECTIONS' },
        { headCell: 'CHANGE' },
      ],
      data: [
        [
          { cellData: 'PAIN', isHead: true },
          { cellData: 100 },
          { cellData: 6, isPercent: true },
        ],

        [
          { cellData: 'ANXIETY', isHead: true },
          { cellData: 90 },
          { cellData: -8, isPercent: true },
        ],

        [
          { cellData: 'SLEEP', isHead: true },
          { cellData: 13 },
          { cellData: 0, isPercent: true },
        ],
        [
          { cellData: 'AWAKENING', isHead: true },
          { cellData: 40 },
          { cellData: 3, isPercent: true },
        ],
      ],
    },
  ],
  charts: [
    [
      {
        type: 'barMultiple',
        height: '235px',
        chartData: {
          alwaysShowTooltips: true,
          noTooltipsTitles: true,
          chartWidth: '95',
          title: `CHANGE`,
          key: 'beforeAfterComparison',
          data: [
            {
              labels: ['PAIN_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [10],
                },
              ],
              group: 0,
            },
            {
              labels: ['PAIN_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [3],
                },
              ],
              group: 0,
            },
            {
              labels: ['ANXIETY_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [4],
                },
              ],
              group: 1,
            },
            {
              labels: ['ANXIETY_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [7],
                },
              ],
              group: 1,
            },
            {
              labels: ['SLEEP_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [9],
                },
              ],
              group: 2,
            },
            {
              labels: ['AWAKENING_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [6],
                },
              ],
              group: 3,
            },
          ],
        },
      },
    ],
  ],
}

export const representativeStatsData = {
  gridStats: [
    {
      title: 'DOCTORS',
      amount: '850',
    },
    {
      title: 'CONVERSION_RATE',
      amount: '80%',
    },
  ],
  charts: [
    [
      {
        type: 'bar',
        height: '260px',
        chartData: {
          noTooltipsTitles: true,
          title: `CONVERSIONS`,
          data: {
            labels: Array(12)
              .fill()
              .map((_, i) => formatDate(subMonths(new Date(), i), 'MMM'))
              .reverse(),
            datasets: [
              {
                label: 'JOINED',
                translateLabel: true,
                data: [52, 39, 40, 45, 35, 55, 75, 62, 73, 95, 75, 80],
              },
              {
                label: 'INVITED',
                translateLabel: true,
                data: [22, 10, 12, 23, 23, 30, 35, 30, 38, 50, 40, 45],
              },
            ],
          },
        },
      },
    ],
  ],
}

export const doctorStatsData = {
  gridStats: [
    {
      title: 'PATIENTS',
      amount: '1,240',
    },
    {
      title: 'AVG_TIME',
      amount: '15:02',
    },
    {
      title: 'AVG_CHANGE',
      amount: '10%',
    },
    {
      title: 'AVG_PAIN_AFTER',
      amount: '2/10',
    },
  ],
  charts: [
    [
      {
        type: 'pie',
        chartData: {
          title: 'AGE',
          data: {
            noTranslate: true,
            labels: ['18-24', '25-34', '35-49', '50-74', '75+'],
            datasets: [
              {
                isPercent: true,
                data: [14, 18, 23, 33, 12],
                borderColor: 'transparent',
              },
            ],
          },
          tableHeight: 135,
          tableWidth: 135,
        },
        height: '136.125px',
      },
      {
        type: 'pie',
        chartData: {
          title: 'GENDER',
          data: {
            labels: ['MALE', 'FEMALE', 'OTHER'],
            datasets: [
              {
                isPercent: true,
                data: [46, 52, 2],
                borderColor: 'transparent',
              },
            ],
          },
          tableHeight: 135,
          tableWidth: 135,
        },
        height: '136.125px',
      },
    ],
    [
      {
        type: 'doughnut',
        chartData: {
          title: 'CONNECTIONS',
          data: {
            center: { value: 243, descr: 'CONNECTIONS' },
            labels: ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING'],
            datasets: [
              {
                data: [100, 90, 13, 40],
              },
            ],
            headCells: ['INDICATION', 'CONNECTIONS', 'CHANGE'],
            otherColumns: [
              {
                isPercent: true,
                data: [6, 8, 0, -2],
              },
            ],
          },
        },
        height: '368px',
      },
    ],
  ],
}

export const sessionsData = Array(100)
  .fill()
  .map((_, i) => {
    const before = random(1, 10)
    const after = random(1, 10)
    const improvement = Math.round(((after - before) / before) * 100)
    const comments = [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      ' Ut enim ad minim veniam',
      'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
      '',
    ]

    return {
      session: formatDate(
        subMinutes(subHours(subDays(new Date(), i), random(1, 3)), random(15, 90)),
        'd/M/yy H:mm'
      ),
      song: songs[random(4)],
      before,
      indication: ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING'][random(3)],
      after,
      improvement,
      comment: comments[random(0, 4)],
      type: 'SESSION',
    }
  })

const frenchHospitalNames = [
  'Amiens University Hospital',
  'Angers University Hospital',
  'Beaujon Hospital',
  'Bicêtre Hospital',
  'Broussais University Hospital',
  'Central Hospital',
  'Central Hospital',
  'Centre Hospitalier Générale',
  'Centre Hospitalier',
  'Centre Hospitalier Sud Francilien',
  'Centre Hospitalier Universitaire de Caen',
  'CHU Poitiers',
  'CHU Bordeaux',
  'CHS',
  'Clinique Boneffon',
  'Clinique les Franciscaines',
  'Foch Hospital',
  'Grenoble University Hospital',
  'Groupe hospitalier Diaconesses Croix Saint-Simon',
  'Guingamp Hospital',
  'Henri Poincaré University Hospital',
  `Hôpital d'instruction des armées Percy`,
  'Hôpital de Dubois Hautepierre Saints-Marguerite',
  'Hôpital de Hautepierre',
  'Hôpital de La Grave',
  'Hôpital des armées',
  'Hôpital Européen Georges-Pompidou',
  'Centre Hôspitalier de Cannes',
  'Hospices Civils de Lyon',
  'Hôpital de Chambéry',
  'Hospital Lapeyronie',
  'Hospital Saint Eloi',
  'Hôtel-Dieu de Paris',
  'Institution Nationale des Invalides',
  'Jean Minjoz Hospital',
  'Limoges University Hospital',
  'Nantes University Hospital',
  'Necker-Enfants Malades Hospital',
  'CHU Nice - Pasteur',
  'Paul Morel Hospital',
  'Pitié-Salpêtrière Hospital',
  'Polyclinique du grand Sud',
  'Psychiatric Hospital Lacolombière',
  'Reims University Hospital',
  'René Dubois Hospital',
  'Rennes University Hospital',
  'Rouen University Hospital',
  'Saint-Antoine University Hospital',
  'Tourcoing Hospital',
  'University Hospital CAREMO',
  'University Hospital of Montpellier',
  'Val-de-Grâce Military Hospital',
  'Rothschild Hospital',
]

const americanHospitalNames = [
  `Children's National Medical Center`,
  'George Washington University Hospital',
  'Hospital for Sick Children',
  'Howard University Hospital',
  'MedStar Georgetown University Hospital',
  'MedStar National Rehabilitation Hospital',
  'MedStar Washington Hospital Center',
  'Psychiatric Institute of Washington',
  'Sibley Memorial Hospital',
  'Specialty Hospital of Washington - Capitol Hill',
  'Specialty Hospital of Washington - Hadley',
  'St. Elizabeths Hospital',
  'United Medical Center ',
]

const combinedHospitals = [
  ...frenchHospitalNames.map((name) => ({ name, country: localizedCountries['france'] })),
  ...americanHospitalNames.map((name) => ({
    name,
    country: localizedCountries['usa'],
  })),
]

export const hospitalsData = combinedHospitals.map((hospital, i) => {
  const id = String(1698719431411 + i)
  return {
    ...hospital,
    id,
    type: 'HOSPITAL',
    licenses: random(20, 120),
    sessions: random(600, 3000),
    avgAfter: random(1, 10),
    avgBefore: random(1, 10),
    avgImprovement: Math.ceil(Math.random() * 200) - 100,
    mostPlayed: songs[random(0, 4)],
    createdBy: `Dr. ${docArray[random(0, docArray.length - 1)]}`,
    createdDate: formatDate(subWeeks(new Date(), random(8, 40))),
    links: {
      referrals: { path: [`#`] },
      licenses: { path: [`#`] },
      code: { path: [`#`] },
      name: { path: ['accounts', 'hospitals'], param: id },
    },
    referrals: random(200, 800),
    licenses: random(40, 79),
    isPro: random(0, 10) < 6,
    code:
      hospital.name
        .replace("'", '')
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase() + random(5000, 9999),
    email: `contact@${hospital.name
      .replace("'", '')
      .replace(/\s/g, '.')
      .toLowerCase()}.com`,
  }
})

export const hospitalStatsData = {
  gridStats: [
    {
      title: 'SESSIONS',
      amount: '15:02',
    },
    {
      title: 'AVG_TIME',
      amount: '15:02',
    },
    {
      title: 'AVG_CHANGE',
      amount: '10%',
    },
    {
      title: 'AVG_PAIN_AFTER',
      amount: '2/10',
    },
  ],
  table: [
    {
      id: 'songsPlayedData',
      headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
      data: [
        [{ cellData: 'Yarik', isHead: true, noTranslate: true }, { cellData: 320 }],

        [
          { cellData: 'Ballade Jazy', isHead: true, noTranslate: true },
          { cellData: 220 },
        ],

        [
          { cellData: 'Electro Jazz', isHead: true, noTranslate: true },
          { cellData: 110 },
        ],
        [{ cellData: 'Reverie', isHead: true, noTranslate: true }, { cellData: 75 }],
      ],
    },
    {
      id: 'indicationData',
      headCells: [
        { headCell: 'INDICATION' },
        { headCell: 'CONNECTIONS' },
        { headCell: 'CHANGE' },
      ],
      data: [
        [
          { cellData: 'PAIN', isHead: true },
          { cellData: 100 },
          { cellData: 6, isPercent: true },
        ],

        [
          { cellData: 'ANXIETY', isHead: true },
          { cellData: 90 },
          { cellData: -8, isPercent: true },
        ],

        [
          { cellData: 'SLEEP', isHead: true },
          { cellData: 13 },
          { cellData: 0, isPercent: true },
        ],
        [
          { cellData: 'AWAKENING', isHead: true },
          { cellData: 40 },
          { cellData: 3, isPercent: true },
        ],
      ],
    },
  ],
  charts: [
    [
      {
        type: 'line',
        height: '193px',
        chartData: {
          title: `PERC_IMPROVEMENT_LEVELS`,
          data: {
            labels: Array(12)
              .fill()
              .map((_, i) => formatDate(subWeeks(new Date(), i), 'MM/d'))
              .reverse(),
            datasets: [
              {
                label: 'PAIN',
                data: [52, 39, 40, 45, 35, 55, 75, 62, 73, 95, 75, 80],
              },
              {
                label: 'ANXIETY',
                data: [22, -10, 12, 23, 23, 30, 35, 30, 38, 50, 40, 45],
              },
            ],
          },
        },
      },
    ],
  ],
}

export const hospitalsStatsData = {
  gridStats: [
    {
      title: 'HOSPITALS',
      amount: '1,330',
    },
    {
      title: 'TOTAL_PATIENTS',
      amount: '2,334',
    },
  ],
  table: [
    {
      id: 'songsPlayedData',
      headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
      data: [
        [{ cellData: 'Yarik', isHead: true, noTranslate: true }, { cellData: 320 }],

        [
          { cellData: 'Ballade Jazy', isHead: true, noTranslate: true },
          { cellData: 220 },
        ],

        [
          { cellData: 'Electro Jazz', isHead: true, noTranslate: true },
          { cellData: 110 },
        ],
        [{ cellData: 'Reverie', isHead: true, noTranslate: true }, { cellData: 75 }],
      ],
    },
    {
      id: 'indicationData',
      headCells: [
        { headCell: 'INDICATION' },
        { headCell: 'CONNECTIONS' },
        { headCell: 'CHANGE' },
      ],
      data: [
        [
          { cellData: 'PAIN', isHead: true },
          { cellData: 100 },
          { cellData: 6, isPercent: true },
        ],

        [
          { cellData: 'ANXIETY', isHead: true },
          { cellData: 90 },
          { cellData: -8, isPercent: true },
        ],

        [
          { cellData: 'SLEEP', isHead: true },
          { cellData: 13 },
          { cellData: 0, isPercent: true },
        ],
        [
          { cellData: 'AWAKENING', isHead: true },
          { cellData: 40 },
          { cellData: 3, isPercent: true },
        ],
      ],
    },
  ],
  charts: [
    [
      {
        type: 'barMultiple',
        height: '235px',
        chartData: {
          alwaysShowTooltips: true,
          noTooltipsTitles: true,
          chartWidth: '95',
          title: `CHANGE`,
          key: 'beforeAfterComparison',
          data: [
            {
              labels: ['PAIN_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [10],
                },
              ],
              group: 0,
            },
            {
              labels: ['PAIN_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [3],
                },
              ],
              group: 0,
            },
            {
              labels: ['ANXIETY_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [4],
                },
              ],
              group: 1,
            },
            {
              labels: ['ANXIETY_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [7],
                },
              ],
              group: 1,
            },
            {
              labels: ['SLEEP_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [9],
                },
              ],
              group: 2,
            },
            {
              labels: ['AWAKENING_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [6],
                },
              ],
              group: 3,
            },
          ],
        },
      },
    ],
  ],
}

export const independentPractitionersData = doctorsData.map((doctorData) => {
  const fullName = doctorData.fullName.split(' ').reverse().join(' ')
  const name = `${fullName.split(' ')[0][0]} ${fullName.split(' ')[1]}`

  const id = String(Number(doctorData.id) + 22222222222)
  return {
    ...doctorData,
    type: 'INDEPENDENT_PRACTITIONER',
    id,
    links: {
      name: {
        path: ['accounts', 'independentPractitioners'],
        param: id,
      },
    },
    fullName,
    name,
    email: `${name.split(' ').join('_')}@indep_pract.com`,
  }
})

export const independentPractitionerStatsData = { ...doctorStatsData }

export const allData = [
  ...patientsData,
  // TODO: Hide reps until v3
  // ...representativesData,
  ...hospitalsData,
  ...doctorsData,
  ...independentPractitionersData,
]

const comments = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Vehicula ipsum a arcu cursus vitae congue mauris rhoncus aenean. Fermentum posuere urna nec tincidunt praesent.',
  'Pretium aenean pharetra magna ac placerat vestibulum.',
  'Tellus in metus vulputate eu scelerisque felis imperdiet.',
  'Sapien pellentesque habitant morbi tristique senectus et. Bibendum neque egestas congue quisque. ',
]

export const dashboardStatsData = {
  topData: {
    gridStats: [
      {
        title: 'USERS',
        amount: '1,240',
      },
      {
        title: 'PENDING_INVITATIONS',
        amount: '466',
      },
    ],
    // table: [
    //   {
    //     id: 'songsPlayedData',
    //     headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
    //     data: [
    //       [{ cellData: 'Yarik', isHead: true, noTranslate: true }, { cellData: 320 }],

    //       [
    //         { cellData: 'Ballade Jazy', isHead: true, noTranslate: true },
    //         { cellData: 220 },
    //       ],

    //       [
    //         { cellData: 'Electro Jazz', isHead: true, noTranslate: true },
    //         { cellData: 110 },
    //       ],
    //       [{ cellData: 'Reverie', isHead: true, noTranslate: true }, { cellData: 75 }],
    //     ],
    //   },
    //   {
    //     id: 'indicationData',
    //     headCells: [
    //       { headCell: 'INDICATION' },
    //       { headCell: 'CONNECTIONS' },
    //       { headCell: 'CHANGE' },
    //     ],
    //     data: [
    //       [
    //         { cellData: 'PAIN', isHead: true },
    //         { cellData: 100 },
    //         { cellData: 6, isPercent: true },
    //       ],

    //       [
    //         { cellData: 'ANXIETY', isHead: true },
    //         { cellData: 90 },
    //         { cellData: -8, isPercent: true },
    //       ],

    //       [
    //         { cellData: 'SLEEP', isHead: true },
    //         { cellData: 13 },
    //         { cellData: 0, isPercent: true },
    //       ],
    //       [
    //         { cellData: 'AWAKENING', isHead: true },
    //         { cellData: 40 },
    //         { cellData: 3, isPercent: true },
    //       ],
    //     ],
    //   },
    // ],
    // charts: [
    //   [
    //     {
    //       type: 'barMultiple',
    //       height: '235px',
    //       chartData: {
    //         alwaysShowTooltips: true,
    //         noTooltipsTitles: true,
    //         chartWidth: '95',
    //         title: `CHANGE`,
    //         key: 'beforeAfterComparison',
    //         data: [
    //           {
    //             labels: ['PAIN_BEFORE'],
    //             translateLabels: true,
    //             datasets: [
    //               {
    //                 data: [10],
    //               },
    //             ],
    //             group: 0,
    //           },
    //           {
    //             labels: ['PAIN_AFTER'],
    //             translateLabels: true,
    //             datasets: [
    //               {
    //                 data: [3],
    //               },
    //             ],
    //             group: 0,
    //           },
    //           {
    //             labels: ['ANXIETY_BEFORE'],
    //             translateLabels: true,
    //             datasets: [
    //               {
    //                 data: [4],
    //               },
    //             ],
    //             group: 1,
    //           },
    //           {
    //             labels: ['ANXIETY_AFTER'],
    //             translateLabels: true,
    //             datasets: [
    //               {
    //                 data: [7],
    //               },
    //             ],
    //             group: 1,
    //           },
    //           {
    //             labels: ['SLEEP_AFTER'],
    //             translateLabels: true,
    //             datasets: [
    //               {
    //                 data: [9],
    //               },
    //             ],
    //             group: 2,
    //           },
    //           {
    //             labels: ['AWAKENING_AFTER'],
    //             translateLabels: true,
    //             datasets: [
    //               {
    //                 data: [6],
    //               },
    //             ],
    //             group: 3,
    //           },
    //         ],
    //       },
    //     },
    //   ],
    // ],
  },
  dashboardCommentsTable: {
    title: 'LATEST_COMMENTS',
    data: {
      dataset: patientsData.slice(0, 4).map(({ email, id }, i) => {
        const date = formatDate(subDays(new Date(), i))
        const rand = random(0, 4)
        return {
          id: 'C' + id,
          userId: id,
          email,
          date,
          titleEn: songs[rand],
          titleFr: songsFr[rand],
          comment: comments[i],
        }
      }),
    },
  },
  doughnutConnectionsChart: {
    title: 'CONNECTIONS_TOTAL',
    data: {
      center: { value: 243, descr: 'CONNECTIONS' },
      labels: ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING'],
      datasets: [
        {
          data: [100, 90, 13, 40],
        },
      ],
      headCells: ['INDICATION', 'CONNECTIONS'],
    },
  },

  dashboardTopMusicTable: {
    title: 'TOP_MUSIC',
    data: {
      total: 300,
      dataset: [
        {
          id: '2222059431477',
          titleEn: 'Jazzy Ballad',
          titleFr: 'Ballade Jazzy',
          patients: 221,
        },

        {
          id: '2222059431478',
          titleEn: 'Electro Jazz',
          titleFr: 'Electro Jazz',
          patients: 188,
        },

        {
          id: '2222059431479',
          titleEn: 'Voice of the Elves',
          titleFr: 'La voix des Elfes',
          patients: 177,
        },

        {
          id: '2222059431480',
          titleEn: 'Celtic Legend',
          titleFr: 'Légende celtique',
          patients: 162,
        },

        {
          id: '2222059431481',
          titleEn: 'Around the Piano',
          titleFr: 'Thème Baroque',
          patients: 86,
        },

        { id: '2222059431482', titleEn: 'Reverie', titleFr: 'Rêverie', patients: 32 },
      ],
    },
  },
  dashboardTopLoacationsMap: {
    title: 'TOP_LOCATIONS_USING_MUSIC_CARE',
    data: [
      {
        latLng: [39.904202, 116.407394],
        name: 'Beijing',
      },
      {
        latLng: [28.70406, 77.102493],
        name: 'Delhi',
      },
      {
        latLng: [41.00824, 28.978359],
        name: 'Istanbul',
      },
      {
        latLng: [40.7127837, -74.0059413],
        name: 'New York',
      },
      {
        latLng: [34.052235, -118.243683],
        name: 'Los Angeles',
      },
      {
        latLng: [41.878113, -87.629799],
        name: 'Chicago',
      },
      {
        latLng: [51.507351, -0.127758],
        name: 'London',
      },
      {
        latLng: [55.755825, 37.617298],
        name: 'Moscow',
      },
      {
        latLng: [40.416775, -3.70379],
        name: 'Madrid',
      },
    ],
  },
}
