import React from 'react'
import i18n from 'translation'

import async from 'components/utils/Async'
import {
  // BookOpen,
  // Briefcase,
  // Calendar as CalendarIcon,
  // CheckSquare,
  // CreditCard,
  Grid as GridIcon,
  // Heart,
  // Layout,
  List as ListIcon,
  // Map,
  // Monitor,
  Music as MusicIcon,
  // ShoppingCart,
  // PieChart,
  // Sliders,
  UserPlus as UserPlusIcon,
  User as UserIcon,
  Users as UsersIcon,
} from 'react-feather'

import {
  AvTimer as AvTimerIcon,
  DesktopMac as DesktopMacIcon,
  Email as EmailIcon,
  Info as InfoIcon,
  LibraryMusic as LibraryMusicIcon,
} from '@material-ui/icons'

import { getCurrentLanguage } from 'translation'
import { routeLanguagePaths } from 'routes/constants'

const t = i18n.getFixedT(null, 'routes')
const tLinks = i18n.getFixedT(null, 'links')

const currentLanguage = getCurrentLanguage()

const {
  auth,
  accounts,
  createAccount,
  dashboard,
  editAccount,
  elearning,
  invitations,
  music,
  profile,
  subscriptions,
  training,
} = routeLanguagePaths[currentLanguage]

// -- Accounts --
const AccountsPage = async(() => import('pages/accounts/AccountsPage'))
const DistributionsPage = async(() => import('pages/distributions/DistributionsPage'))

const accountsRoutes = {
  id: 'accounts',
  name: t('ACCOUNTS'),
  icon: <UsersIcon />,
  component: null,
  noDoctor: true,
  children: [
    {
      path: accounts.distributions,
      name: t('DISTRIBUTIONS'),
      component: DistributionsPage,
      permission: 'admin.distribution',
    },
    {
      path: `${accounts.distributions}/:id`,
      name: t('DISTRIBUTION'),
      component: DistributionsPage,
      permission: 'admin.distribution',
      noSidebar: true,
    },
    {
      path: accounts.myProAccount,
      name: t('MY_PRO_ACCOUNT'),
      component: AccountsPage,
    },
    // {
    //   path: accounts.doctors,
    //   name: t('DOCTORS'),
    //   component: AccountsPage,
    // },
    // {
    //   path: accounts.hospitals,
    //   name: t('HOSPITALS'),
    //   component: AccountsPage,
    // },
    // {
    //   path: accounts.independentPractitioners,
    //   name: t('INDEPENDENT_PRACTITIONERS'),
    //   component: AccountsPage,
    // },
    // Hide reps until v3
    // {
    //   path: accounts.representatives,
    //   name: t('REPRESENTATIVES'),
    //   component: AccountsPage,
    // },
    // {
    //   path: `${accounts.representatives}/:id`,
    //   name: t('REPRESENTATIVE'),
    //   component: AccountsPage,
    //   noSidebar: true,
    // },
    {
      path: `${accounts.patients}/:id`,
      name: t('PATIENT'),
      component: AccountsPage,
      noSidebar: true,
    },
    // {
    //   path: `${accounts.doctors}/:id`,
    //   name: t('DOCTOR'),
    //   component: AccountsPage,
    //   noSidebar: true,
    // },
    // {
    //   path: `${accounts.hospitals}/:id`,
    //   name: t('HOSPITALS'),
    //   component: AccountsPage,
    //   noSidebar: true,
    // },
    // {
    //   path: `${accounts.independentPractitioners}/:id`,
    //   name: t('INDEPENDENT_PRACTITIONERS'),
    //   component: AccountsPage,
    //   noSidebar: true,
    // },
  ],
}

// -- Registration --
const RegisterProPage = async(() => import('pages/registration/RegisterProPage'))
const RegisterDoctorPage = async(() => import('pages/registration/RegisterDoctorPage'))

const registrationProRoutes = {
  id: 'registration-pro',
  name: t('REGISTRATION_PRO'),
  component: null,
  children: [
    {
      path: '/register',
      name: 'Register',
      component: RegisterProPage,
      language: 'en',
    },
    {
      path: '/inscription',
      name: 'Inscription',
      component: RegisterProPage,
      language: 'fr',
    },
  ],
}

const registrationDoctorRoutesEn = {
  id: 'register-dooctor-en',
  isColumn: true,
  component: RegisterDoctorPage,
  path: '/register-doctor',
  isFullWidth: true,
  language: 'en',
}

const registrationDoctorRoutesFr = {
  id: 'register-dooctor-fr',
  isColumn: true,
  component: RegisterDoctorPage,
  path: '/inscription-medecin',
  isFullWidth: true,
  language: 'fr',
}

// -- Auth --
const SignInPage = async(() => import('pages/auth/SignIn'))
const ResetPasswordPage = async(() => import('pages/auth/ResetPassword'))
const Page404Page = async(() => import('pages/auth/Page404'))
const Page500Page = async(() => import('pages/auth/Page500'))

const authRoutes = {
  id: 'auth',
  name: 'Auth',
  component: null,
  children: [
    {
      path: '/se-connecter',
      name: t('SIGN_IN'),
      component: SignInPage,
    },
    {
      path: '/sign-in',
      name: t('SIGN_IN'),
      component: SignInPage,
    },
    {
      path: auth.resetPassword,
      name: 'Reset Password',
      component: ResetPasswordPage,
    },
    // {
    //   path: auth.setNewPassword,
    //   name: 'Set new Password',
    //   component: SetNewPasswordPage,
    // },
    {
      path: '/404',
      name: '404 Page',
      component: Page404Page,
    },
    {
      path: '/500',
      name: '500 Page',
      component: Page500Page,
    },
  ],
}

// -- Sign Up --
const SignUpDistributionPage = async(() => import('pages/signUp/SignUpDistributionPage'))
const signUpRoutes = {
  id: 'sign-up-en',
  isColumn: true,
  isFullWidth: true,
  footerFixed: true,
  path: '/signup-distribution/:link',
  name: t('SIGN_UP'),
  component: SignUpDistributionPage,
}

// -- Create Account --
const CreateProAccountPage = async(() =>
  import('pages/createEditAccounts/CreateProAccountPage')
)
const EditProAccountPage = async(() =>
  import('pages/createEditAccounts/EditProAccountPage')
)
const CreateAccountInviteFormPatientPage = async(() =>
  import('pages/createEditAccounts/CreateAccountInviteFormPatientPage')
)
const CreateAccountInviteFormDoctorPage = async(() =>
  import('pages/createEditAccounts/CreateAccountInviteFormDoctorPage')
)

const CreateDistributionPage = async(() =>
  import('pages/createEditAccounts/CreateDistributionPage')
)

// -- E-learning --
const ELearningPage = async(() => import('pages/eLearning/ELearningPage'))
const eLearningRoutesEn = {
  id: 'e-learning-en',
  isColumn: true,
  component: ELearningPage,
  path: '/welcome',
  isFullWidth: true,
  language: 'en',
  footerFixed: true,
}
const eLearningRoutesFr = {
  id: 'e-learning-fr',
  isColumn: true,
  component: ELearningPage,
  path: '/bienvenue',
  isFullWidth: true,
  language: 'fr',
  footerFixed: true,
}

// -- Dashboard --
const DashboardPage = async(() => import('pages/dashboard/DashboardPage'))
const dashboardRoutes = {
  id: 'dashboard',
  name: t('DASHBOARD'),
  icon: <AvTimerIcon />,
  component: DashboardPage,
  path: dashboard,
}

// -- Invitations --
const InvitationsPage = async(() => import('pages/invitations/InvitationsPage'))
const invitationsRoutes = {
  id: 'invitations',
  name: t('INVITATIONS'),
  icon: <GridIcon />,
  children: [
    {
      path: invitations,
      name: t('ALL'),
      component: InvitationsPage,
    },
    {
      path: createAccount.pro,
      name: t('CREATE_ACCOUNT_PRO'),
      component: CreateProAccountPage,
      isCreate: true,
      permission: 'admin.createPro',
    },
    {
      center: true,
      path: createAccount.distribution,
      name: t('CREATE_DISTRIBUTION'),
      component: CreateDistributionPage,
      isCreate: true,
      permission: 'admin.distribution',
    },
    {
      path: `${editAccount}/:id`,
      name: t('EDIT_ACCOUNT'),
      component: EditProAccountPage,
      noSidebar: true,
      permission: 'admin.createPro',
    },
    {
      center: true,
      path: createAccount.individual,
      name: t('CREATE_ACCOUNT_INDIVIDUAL'),
      component: CreateAccountInviteFormPatientPage,
      isCreate: true,
    },
    {
      center: true,
      path: createAccount.doctor,
      name: t('CREATE_ACCOUNT_DOCTOR'),
      component: CreateAccountInviteFormDoctorPage,
      isCreate: true,
      permission: 'invitations.doctor',
    },
  ],
}

// -- Music --
const SongsPage = async(() => import('pages/music/songs/SongsPage'))
const GenresPage = async(() => import('pages/music/genres/GenresPage'))
const MusicStatisticsPage = async(() => import('pages/music/reports/MusicStatisticsPage'))
const SongStatisticsPage = async(() => import('pages/music/reports/SongStatisticsPage'))

const musicRoutes = {
  id: 'music',
  name: t('MUSIC'),
  icon: <MusicIcon />,
  component: null,
  children: [
    {
      path: music.statistics,
      name: t('LISTENING_STATISTICS'),
      component: MusicStatisticsPage,
      permission: 'reporting.musicStatistics',
    },
    {
      path: `${music.statistics}/:id`,
      name: t('SONG_STATISTICS'),
      component: SongStatisticsPage,
      noSidebar: true,
      permission: 'reporting.musicStatistics',
    },
    {
      path: music.songs,
      name: t('SONGS'),
      component: SongsPage,
      permission: 'admin.music',
    },
    {
      path: music.genres,
      name: t('GENRES'),
      component: GenresPage,
      permission: 'admin.music',
    },
  ],
}

// -- Subscriptions --
const SubscriptionsPage = async(() => import('pages/subscriptions/SubscriptionsPage'))

const subscriptionsRoutes = {
  id: 'subscriptions',
  name: t('SUBSCRIPTIONS'),
  icon: <ListIcon />,
  component: null,
  permission: 'admin.viewPro',
  children: [
    // TODO: temporarily remove all
    // {
    //   path: subscriptions.all,
    //   name: t('ALL'),
    //   component: SubscriptionsPage,
    //   permission: 'admin.viewPro',
    // },
    {
      path: subscriptions.individual,
      name: t('INDIVIDUAL'),
      component: SubscriptionsPage,
    },
    {
      path: subscriptions.professional,
      name: t('PROFESSIONAL'),
      component: SubscriptionsPage,
    },
    {
      path: `${subscriptions.individual}/:id`,
      name: t('INDIVIDUAL'),
      component: SubscriptionsPage,
      noSidebar: true,
    },
    {
      path: `${subscriptions.professional}/:id`,
      name: t('PROFESSIONAL'),
      component: SubscriptionsPage,
      noSidebar: true,
    },
  ],
}

// -- Profile --
const ProfilePage = async(() => import('pages/profile/ProfilePage'))

const profileRoutes = {
  id: 'profile',
  name: t('PROFILE'),
  icon: <UserIcon />,
  component: ProfilePage,
  path: profile,
}

// -- Training --
const TrainingPage = async(() => import('pages/training/TrainingPage'))

const trainingRoutes = {
  id: 'training',
  name: t('TRAINING'),
  icon: <DesktopMacIcon />,
  component: TrainingPage,
  path: training,
}

// // Components components
// const Alerts = async(() => import('../pages/components/Alerts'))
// const Avatars = async(() => import('../pages/components/Avatars'))
// const Badges = async(() => import('../pages/components/Badges'))
// const Buttons = async(() => import('../pages/components/Buttons'))
// const Cards = async(() => import('../pages/components/Cards'))
// const Chips = async(() => import('../pages/components/Chips'))
// const Dialogs = async(() => import('../pages/components/Dialogs'))
// const ExpPanels = async(() => import('../pages/components/ExpansionPanels'))
// const Lists = async(() => import('../pages/components/Lists'))
// const Menus = async(() => import('../pages/components/Menus'))
// const Pagination = async(() => import('../pages/components/Pagination'))
// const Progress = async(() => import('../pages/components/Progress'))
// const Snackbars = async(() => import('../pages/components/Snackbars'))
// const Tooltips = async(() => import('../pages/components/Tooltips'))

// // Dashboards components
// const Default = async(() => import('../pages/dashboards/Default'))
// const Analytics = async(() => import('../pages/dashboards/Analytics'))

// // Forms components
// const Pickers = async(() => import('../pages/forms/Pickers'))
// const SelectionCtrls = async(() => import('../pages/forms/SelectionControls'))
// const Selects = async(() => import('../pages/forms/Selects'))
// const TextFields = async(() => import('../pages/forms/TextFields'))
// const Dropzone = async(() => import('../pages/forms/Dropzone'))
// const Editors = async(() => import('../pages/forms/Editors'))

// // Icons components
// const MaterialIcons = async(() => import('../pages/icons/MaterialIcons'))
// const FeatherIcons = async(() => import('../pages/icons/FeatherIcons'))

// // Pages components
// const Blank = async(() => import('../pages/pages/Blank'))
// const InvoiceDetails = async(() => import('../pages/pages/InvoiceDetails'))
// const InvoiceList = async(() => import('../pages/pages/InvoiceList'))
// const Orders = async(() => import('../pages/pages/Orders'))
// const Pricing = async(() => import('../pages/pages/Pricing'))
// const Profile = async(() => import('../pages/pages/Profile'))
// const Settings = async(() => import('../pages/pages/Settings'))
// const Tasks = async(() => import('../pages/pages/Tasks'))
// const Projects = async(() => import('../pages/pages/Projects'))
// const Calendar = async(() => import('../pages/pages/Calendar'))

// // Tables components
// const SimpleTable = async(() => import('../pages/tables/SimpleTable'))
// const AdvancedTable = async(() => import('../pages/tables/AdvancedTable'))

// // Chart components
// const Chartjs = async(() => import('../pages/charts/Chartjs'))

// // Maps components
// const GoogleMaps = async(() => import('../pages/maps/GoogleMaps'))
// const VectorMaps = async(() => import('../pages/maps/VectorMaps'))

// // Documentation
// const Welcome = async(() => import('../pages/docs/Welcome'))
// const GettingStarted = async(() => import('../pages/docs/GettingStarted'))
// const EnvironmentVariables = async(() =>
//   import('../pages/docs/EnvironmentVariables')
// )
// const Deployment = async(() => import('../pages/docs/Deployment'))
// const Theming = async(() => import('../pages/docs/Theming'))
// const StateManagement = async(() => import('../pages/docs/StateManagement'))
// const Support = async(() => import('../pages/docs/Support'))
// const Changelog = async(() => import('../pages/docs/Changelog'))
// const Presentation = async(() => import('../pages/docs/Presentation'))

// const dashboardsRoutes = {
//   id: 'Dashboard',
//   path: '/dashboard',
//   header: 'Pages',
//   icon: <Sliders />,
//   containsHome: true,
//   children: [
//     {
//       path: '/dashboard/default',
//       name: 'Default',
//       component: Default,
//     },
//     {
//       path: '/dashboard/analytics',
//       name: 'Analytics',
//       component: Analytics,
//     },
//   ],
//   component: null,
// }

// const pagesRoutes = {
//   id: 'Pages',
//   path: '/pages',
//   icon: <Layout />,
//   children: [
//     {
//       path: '/pages/settings',
//       name: 'Settings',
//       component: Settings,
//     },
//     {
//       path: '/pages/pricing',
//       name: 'Pricing',
//       component: Pricing,
//     },
//     {
//       path: '/pages/blank',
//       name: 'Blank Page',
//       component: Blank,
//     },
//   ],
//   component: null,
// }

// const profileRoutes = {
//   id: 'Profile',
//   path: '/profile',
//   icon: <User />,
//   component: Profile,
//   children: null,
// }

// const projectsRoutes = {
//   id: 'Projects',
//   path: '/projects',
//   icon: <Briefcase />,
//   badge: '8',
//   component: Projects,
//   children: null,
// }

// const invoiceRoutes = {
//   id: 'Invoices',
//   path: '/invoices',
//   icon: <CreditCard />,
//   children: [
//     {
//       path: '/invoices',
//       name: 'List',
//       component: InvoiceList,
//     },
//     {
//       path: '/invoices/detail',
//       name: 'Details',
//       component: InvoiceDetails,
//     },
//   ],
//   component: null,
// }

// const orderRoutes = {
//   id: 'Orders',
//   path: '/orders',
//   icon: <ShoppingCart />,
//   component: Orders,
//   children: null,
// }

// const tasksRoutes = {
//   id: 'Tasks',
//   path: '/tasks',
//   icon: <CheckSquare />,
//   badge: '17',
//   component: Tasks,
//   children: null,
// }

// const calendarRoutes = {
//   id: 'Calendar',
//   path: '/calendar',
//   icon: <CalendarIcon />,
//   component: Calendar,
//   children: null,
// }

// const componentsRoutes = {
//   id: 'Components',
//   path: '/components',
//   header: 'Elements',
//   icon: <Grid />,
//   children: [
//     {
//       path: '/components/alerts',
//       name: 'Alerts',
//       component: Alerts,
//     },
//     {
//       path: '/components/avatars',
//       name: 'Avatars',
//       component: Avatars,
//     },
//     {
//       path: '/components/badges',
//       name: 'Badges',
//       component: Badges,
//     },
//     {
//       path: '/components/buttons',
//       name: 'Buttons',
//       component: Buttons,
//     },
//     {
//       path: '/components/cards',
//       name: 'Cards',
//       component: Cards,
//     },
//     {
//       path: '/components/chips',
//       name: 'Chips',
//       component: Chips,
//     },
//     {
//       path: '/components/dialogs',
//       name: 'Dialogs',
//       component: Dialogs,
//     },
//     {
//       path: '/components/expansion-panels',
//       name: 'Expansion Panels',
//       component: ExpPanels,
//     },
//     {
//       path: '/components/lists',
//       name: 'Lists',
//       component: Lists,
//     },
//     {
//       path: '/components/menus',
//       name: 'Menus',
//       component: Menus,
//     },
//     {
//       path: '/components/pagination',
//       name: 'Pagination',
//       component: Pagination,
//     },
//     {
//       path: '/components/progress',
//       name: 'Progress',
//       component: Progress,
//     },
//     {
//       path: '/components/snackbars',
//       name: 'Snackbars',
//       component: Snackbars,
//     },
//     {
//       path: '/components/tooltips',
//       name: 'Tooltips',
//       component: Tooltips,
//     },
//   ],
//   component: null,
// }

// const formsRoutes = {
//   id: 'Forms',
//   path: '/forms',
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: '/forms/pickers',
//       name: 'Pickers',
//       component: Pickers,
//     },
//     {
//       path: '/forms/selection-controls',
//       name: 'Selection Controls',
//       component: SelectionCtrls,
//     },
//     {
//       path: '/forms/selects',
//       name: 'Selects',
//       component: Selects,
//     },
//     {
//       path: '/forms/text-fields',
//       name: 'Text Fields',
//       component: TextFields,
//     },
//     {
//       path: '/forms/dropzone',
//       name: 'Dropzone',
//       component: Dropzone,
//     },
//     {
//       path: '/forms/editors',
//       name: 'Editors',
//       component: Editors,
//     },
//   ],
//   component: null,
// }

// const tablesRoutes = {
//   id: 'Tables',
//   path: '/tables',
//   icon: <List />,
//   children: [
//     {
//       path: '/tables/simple-table',
//       name: 'Simple Table',
//       component: SimpleTable,
//     },
//     {
//       path: '/tables/advanced-table',
//       name: 'Advanced Table',
//       component: AdvancedTable,
//     },
//   ],
//   component: null,
// }

// const iconsRoutes = {
//   id: 'Icons',
//   path: '/icons',
//   icon: <Heart />,
//   children: [
//     {
//       path: '/icons/material-icons',
//       name: 'Material Icons',
//       component: MaterialIcons,
//     },
//     {
//       path: '/icons/feather-icons',
//       name: 'Feather Icons',
//       component: FeatherIcons,
//     },
//   ],
//   component: null,
// }

// const chartRoutes = {
//   id: 'Charts',
//   path: '/charts',
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null,
// }

// const mapsRoutes = {
//   id: 'Maps',
//   path: '/maps',
//   icon: <Map />,
//   children: [
//     {
//       path: '/maps/google-maps',
//       name: 'Google Maps',
//       component: GoogleMaps,
//     },
//     {
//       path: '/maps/vector-maps',
//       name: 'Vector Maps',
//       component: VectorMaps,
//     },
//   ],
//   component: null,
// }

// const presentationRoutes = {
//   id: 'Presentation',
//   path: '/',
//   header: 'Docs',
//   icon: <Monitor />,
//   component: Presentation,
//   children: null,
// }

// const documentationRoutes = {
//   id: 'Documentation',
//   path: '/documentation',
//   icon: <BookOpen />,
//   children: [
//     {
//       path: '/documentation/welcome',
//       name: 'Welcome',
//       component: Welcome,
//     },
//     {
//       path: '/documentation/getting-started',
//       name: 'Getting Started',
//       component: GettingStarted,
//     },
//     {
//       path: '/documentation/environment-variables',
//       name: 'Environment Variables',
//       component: EnvironmentVariables,
//     },
//     {
//       path: '/documentation/deployment',
//       name: 'Deployment',
//       component: Deployment,
//     },
//     {
//       path: '/documentation/theming',
//       name: 'Theming',
//       component: Theming,
//     },
//     {
//       path: '/documentation/state-management',
//       name: 'State Management',
//       component: StateManagement,
//     },
//     {
//       path: '/documentation/support',
//       name: 'Support',
//       component: Support,
//     },
//   ],
//   component: null,
// }

// const changelogRoutes = {
//   id: 'Changelog',
//   path: '/changelog',
//   badge: 'v1.2.0',
//   icon: <List />,
//   component: Changelog,
//   children: null,
// }

// // This route is not visisble in the sidebar
// const privateRoutes = {
//   id: 'Private',
//   path: '/private',
//   component: Blank,
//   children: null,
// }

// Note, link will not work on localhost

const accessAppLink = {
  id: 'access-app',
  name: t('ACCESS_APP'),
  icon: <LibraryMusicIcon />,
  path: process.env.REACT_APP_PLAYER_APP_URL,
  isLinkOut: true,
}

const faqLink = {
  id: 'faq',
  name: t('FAQ'),
  icon: <InfoIcon />,
  path: tLinks('FAQ'),
  isLinkOut: true,
}

const contactUsLink = {
  id: 'contact-us',
  name: t('CONTACT_US'),
  icon: <EmailIcon />,
  path: tLinks('CONTACT'),
  isLinkOut: true,
}

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardRoutes,
  accessAppLink,
  accountsRoutes,
  invitationsRoutes,
  musicRoutes,
  subscriptionsRoutes,
  profileRoutes,
  trainingRoutes,
  faqLink,
  contactUsLink,
  // dashboardsRoutes,
  // pagesRoutes,

  // projectsRoutes,
  // orderRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
  // presentationRoutes,
  // privateRoutes,
]

// Routes using the Auth layout
export const barePublicLayoutRoutes = [
  authRoutes,
  registrationProRoutes,
  registrationDoctorRoutesEn,
  registrationDoctorRoutesFr,
  signUpRoutes,
]

export const eLearningRoutes = [eLearningRoutesFr, eLearningRoutesEn]

// Routes which are private but without a sidebar
export const bareAuthenticatedLayoutRoutes = []
