import { newApiRequest as apiRequest } from 'api/apiRequest'

const logIn = ({ email, password }, token) => {
  const config = token
    ? {
        headers: {
          Authorization: token,
        },
      }
    : {}
  // return apiRequest('auth/signin', {}).post({ email, password }, config)
}

const changeUserInfo = async (values) => {
  // Dummy method until api running
  return await new Promise((resolve) => setTimeout(() => resolve({ data: values }), 3000))
  // return apiRequest('account/info', {}).post(values)
}

const changeAvatar = async (avatarFile) => {
  return await new Promise((resolve) =>
    setTimeout(() => resolve({ data: avatarFile }), 3000)
  )
  // return apiRequest('aws/avatarSignedUrl', {}).post(avatarFile)
}

const changeEmail = async (newEmail) => {
  return await new Promise((resolve) =>
    setTimeout(() => resolve({ data: { email: newEmail } }), 3000)
  )
  // return apiRequest('account/email', {}).post(newEmail)
}

const validatePrescriberCode = async (code) => {
  return await new Promise((resolve) =>
    setTimeout(() => resolve({ data: { result: 'success' } }), 3000)
  )
  // return apiRequest('account/validate-code', {}).post(code)
}

export const authApi = {
  logIn,
  changeAvatar,
  changeEmail,
  changeUserInfo,
  validatePrescriberCode,
}
