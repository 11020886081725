import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Menu, MenuItem, IconButton as MuiIconButton } from '@material-ui/core'

import { setLanguage } from 'redux/actions'
import { getCurrentLanguage } from 'translation'

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const HeaderLanguageMenu = ({ setLanguage }) => {
  const [anchorMenu, setAnchorMenu] = useState(null)

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const setLanguageHandler = (lang) => {
    setLanguage(lang)
    closeMenu()
  }
  const currentLanguage = getCurrentLanguage()

  return (
    <>
      <IconButton
        aria-owns={!!anchorMenu ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        {currentLanguage === 'en' && (
          <Flag src="/static/img/flags/us.png" alt="English" />
        )}
        {currentLanguage === 'fr' && (
          <Flag src="/static/img/flags/fr.png" alt="Français" />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => setLanguageHandler('en')}>English</MenuItem>
        <MenuItem onClick={() => setLanguageHandler('fr')}>Français</MenuItem>
      </Menu>
    </>
  )
}

const willMapDispatchToProps = { setLanguage }

export default connect(null, willMapDispatchToProps)(HeaderLanguageMenu)

HeaderLanguageMenu.propTypes = {
  setLanguage: PropTypes.func.isRequired,
}
