import {
  ADD_SNACKBAR,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  PROCESS_SNACKBAR_QUEUE,
} from 'redux/constants'

export const setSnackbar = (success, message) => async (dispatch, getState) => {
  const snackbarId = String(Date.now())
  const isOpen = getState().snackbar.isOpen

  dispatch({
    type: ADD_SNACKBAR,
    payload: {
      success,
      message,
      snackbarId,
    },
  })

  if (isOpen) dispatch(setOpenState(false))
  else dispatch(processQueue())
}

export const setOpenState = (openState) => async (dispatch) => {
  dispatch({ type: openState ? OPEN_SNACKBAR : CLOSE_SNACKBAR })
}

export const processQueue = () => async (dispatch) => {
  dispatch({ type: PROCESS_SNACKBAR_QUEUE })
}
