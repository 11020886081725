// Accounts
export const SET_PRO_ACCOUNT_TYPES = 'SET_PRO_ACCOUNT_TYPES'
export const SET_PRO_ACCOUNT_ROUTES = 'SET_PRO_ACCOUNT_ROUTES'

// Auth
export const AUTH_START = 'AUTH_START'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_RESET_PASS_SUCCESS = 'AUTH_RESET_PASS_SUCCESS'
export const AUTH_SOCIAL_MSG = 'AUTH_SOCIAL_MSG'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_E_LEARNING = 'SET_E_LEARNING'
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES'

// Music
export const SET_ALL_SONGS = 'SET_ALL_SONGS'
export const SET_ALL_GENRES = 'SET_ALL_GENRES'
export const START_FETCH_GENRES = 'START_FETCH_GENRES'
export const FAIL_FETCH_MUSIC = 'FAIL_FETCH_MUSIC'
export const START_GET_GENRES = 'START_GET_GENRES'
export const SUCCESS_GET_GENRES = 'SUCCESS_GET_GENRES'
export const FAIL_GET_GENRES = 'FAIL_GET_GENRES'
export const START_GET_SONGS = 'START_GET_SONGS'
export const SUCCESS_GET_SONGS = 'SUCCESS_GET_SONGS'
export const FAIL_GET_SONGS = 'FAIL_GET_SONGS'

// Snackbar
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
export const ADD_SNACKBAR = 'ADD_SNACKBAR'
export const PROCESS_SNACKBAR_QUEUE = 'PROCESS_SNACKBAR_QUEUE'

// Theme
export const SET_THEME = 'SET_THEME'
