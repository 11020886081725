import { oldApiRequest, apiRequest } from '../apiRequest'

import {
  sessionItems,
  musicItems,
  songStatisticsItems,
} from '../dummyData/musicDummyData.js'

const addGenre = (newGenreData) =>
  apiRequest('/genre/create', { genre: {} }).post(newGenreData)

const addSong = (newSongData) =>
  apiRequest('/song/create', { song: {} }).post(newSongData)

const updateGenre = (updatedGenreData) =>
  apiRequest('/genre/update', { genre: {} }).put(updatedGenreData)

const updateSong = (updatedSongData) =>
  apiRequest('/song/update', { song: {} }).put(updatedSongData)

const updateSongs = (updatedSongsIds, updatedSongsData) =>
  apiRequest('/song/update-list', { songs: [] }).put({
    ids: updatedSongsIds,
    ...updatedSongsData,
  })

const getGenres = () => apiRequest('/genre/list', []).get()

const getSongs = (status) =>
  apiRequest(`/song/list${status ? `?status=${status}` : ''}`, {
    songs: [],
  }).get()

const deleteGenre = (id, genreIdToChangeSongsTo) =>
  apiRequest(`/genre/delete`, {}).post({ id, genreIdToChangeSongsTo })

const deleteSong = (id) => apiRequest(`/song/delete`, {}).post({ id })

// TODO: Old api and mock calls, to be removed
const getSongsOld = () => oldApiRequest('music', []).get()

const getGenresOld = () => oldApiRequest('categories', []).get()

const getTranslations = (lang = '') =>
  oldApiRequest(`translation?language=${lang.toLowerCase()}`, []).get()

const getMusicStatistics = async (start, end, selectedIndication) => {
  const { result, error } = await apiRequest(
    `/song/stats/list?startDate=${start}&endDate=${end}&indication=${selectedIndication}`
  ).get()

  if (error) return { error }

  const { songs } = result

  const musicTableInfo = {
    heads: [
      'title',
      'sessions',
      'indication',
      'avgPainBefore',
      'avgPainAfter',
      // 'percentImprovement',
    ],
    initialOrder: 'sessions',
    rowIdName: 'songId',
  }

  const songsWithLinks = songs.map((song) => ({
    ...song,
    links: { title: { path: ['music', 'statistics'], param: song.songId } },
  }))

  return {
    data: songsWithLinks,
    tableInfo: musicTableInfo,
  }
}

const getSongStatistics = async (songId) => {
  const { result, error } = await apiRequest(`song/stats/get?songId=${songId}`).get()

  if (error) return { error }

  const songTableInfo = {
    heads: ['indication', 'connections'],
    initialOrder: 'connections',
    rowIdName: 'indication',
  }

  return {
    result: { success: true, songInfo: result, tableInfo: songTableInfo },
  }
}

const getSongStatisticsOld = async (songId) => {
  const songTableInfo = {
    heads: ['indication', 'connections'],
    initialOrder: 'connections',
    rowIdName: 'indication',
  }

  // const song = musicItems.find(({ songId: someSongId }) => someSongId === songId)
  // if (!song) return { result: { success: false }, error: Error('Song not found') }

  return {
    result: { success: true, items: songStatisticsItems, tableInfo: songTableInfo },
  }
}

export const musicApi = {
  addGenre,
  addSong,
  getGenres,
  getSongs,
  deleteGenre,
  deleteSong,
  updateGenre,
  updateSong,
  updateSongs,
  // TODO: Old api or mock calls
  getSongsOld,
  getGenresOld,
  getMusicStatistics,
  getSongStatistics,
  getTranslations,
}
