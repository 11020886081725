export default {
  en: {
    accountsPage: {
      A_NAME: `a name`,
      A_NAME_OR_AN_EMAIL: `a name or an email`,
      ACCEPTS_IMAGE_FILES: `Accepts 'png' and 'jpeg' file types`,
      ACCOUNT_INFO: `Account info`,
      ACCOUNT_TYPE: `Account Type`,
      ACCOUNT_TYPE_NAME_EXISTS: `Account type name already exists`,
      ACCOUNT_TYPE_CREATE: `Create a new account type and set permissions manually`,
      ACCOUNT_TYPE_DESCR: `Select an account type with pre-filled permission settings or create a new account type`,
      ACCOUNT_TYPE_SELECT_CREATE: `Select or create an account type`,
      ACCOUNTS: `Accounts`,
      ACCOUNTS_UPDATED_FOR_SOCIAL_SIGN_IN: `Account updated for sign in via Google successfully. Please sign in again.`,
      ACTIONS: `Actions`,
      ACTIVE_SINCE: `Active since`,
      ADMIN: `Administrative permissions`,
      ADMIN_CREATE_PRO: `Create pro accounts`,
      ADMIN_DESCR: `Set this account's administrative permissions`,
      ADMIN_MANAGE_MUSIC: `Manage music`,
      ADMIN_VIEW_PRO: `View pro accounts`,
      ALL: `All accounts`,
      ALL_ACCOUNTS: `all accounts`,
      AN_EMAIL: `an email`,
      AN_ERROR_OCCURED_PLEASE_TRY_LATER: `An error occurred, please try again later.`,
      ANXIETY: `Anxiety`,
      AVG_CHANGE: `Avg Change`,
      AVG_TIME: `Avg Time`,
      AWAKENING: `Awakening`,
      CHANGE: `Change`,
      CREATE_ACCOUNT: `Create pro account invite`,
      CREATE_A_NEW_ACCOUNT: `Create a new account`,
      CREATE_AN_ACCOUNT_INDIVIDUAL: `Create an individual account`,
      CREATE_AN_ACCOUNT_PRO: `Create a PRO account`,
      COMPANY_LOGO: `Company logo (optional)`,
      COMPANY_LOGO_DESCR: `Choose a brand image to be used throughout the app`,
      COMPANY_LOGO_IMAGE_FILE: `Logo image file`,
      COMPANY_LOGO_PREVIEW: `Company logo preview`,
      CONNECTION: `Connection`,
      CONNECTIONS: `Connections`,
      CONNECTIONS_TOTAL: `Total connections`,
      CONVERSION_RATE: `Conversion rate`,
      CONVERSIONS: `Conversions`,
      CREATED_BY: `Created by`,
      LICENSES_DESCR: `Set a license configuration for this account`,
      DISTRIBUTIONS: `Distributions`,
      DOCTOR: `Doctor`,
      DOCTORS: `Doctors`,
      E_LEARNING_WELCOME: `E-Learning welcome for doctors`,
      EDIT_ACCOUNT: `Edit account`,
      EDIT_AN_ACCOUNT: `Edit a PRO account`,
      EMAIL: `Email`,
      EMAIL_ADDRESS: `Email address`,
      EMAIL_ADDRESS_ADMIN: `Pro account email`,
      EMAIL_ADDRESS_DESCR: `New user will receive an invite to set a password and complete a profile`,
      EMAIL_ADDRESS_EXISTS: `An account associated with this email address already exists`,
      EMAIL_ADDRESS_INVALID: `Invalid email format`,
      FEATURES: `Features`,
      FEATURES_ALWAYS_WELCOME_VID: `Always on welcome video`,
      FEATURES_DESCR: `Set which features this type of account has access to`,
      FEATURES_IMMERSIVE: `Immersive video`,
      FEMALE: `Female`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      FILE_IS_NOT_AN_IMAGE: `File is not an image`,
      FILE_IS_TOO_LARGE: `File can be at most {{ maxFileSize }}mb`,
      HEALTH_RECORD: `Health Record`,
      HOSPITAL: `Hospital`,
      HOSPITALS: `Hospitals`,
      IMAGE_FILE_NAME: `Image file name`,
      INDICATION: `Indication`,
      INDICATIONS: `Indications`,
      INDICATIONS_ANXIETY: `Anxiety`,
      INDEPENDENT_PRACTITIONERS: `Independent Practitioners`,
      INDEPENDENTPRACTITIONERS: `Independent Practitioners`,
      INDICATIONS_AWAKENING: `Awakening`,
      INDICATIONS_DESCR: `Set at least one type of indication that this account can activate`,
      INDICATIONS_PAIN: `Pain`,
      INDICATIONS_SLEEP: `Sleep`,
      INDIVIDUAL_PATIENT: `Individual Patient`,
      INVITATIONS: `Invitations`,
      INVITATIONS_DOCTOR: `Doctor`,
      INVITATIONS_DESCR: `Set the types of invitations this account can send`,
      INVITATIONS_PATIENT: `Patient`,
      INVITATIONS_REPRESENTATIVE: `Representative`,
      INVITED: `Invited`,
      JOINED: `Joined`,
      LICENSE: `License`,
      LICENSES: `Licenses`,
      LOG_OUT_OF_ALL_DEVICES: `Log out of all devices`,
      MALE: `Male`,
      MASK_PRIVATE_INFO: `Mask private info`,
      TRIAL_WITHOUT_CREDIT_CARD: `Trial without credit card`,
      MAX_LENGTH: `Max length: {{ maxLength }} characters`,
      MAX_LICENSES_IS: `Max licenses is {{ max }}`,
      MAX_NUMBER_OF_DAYS_IS_365: `Max number of days is 365`,
      MAX_PRICE_IS: `Max price is {{ max }}`,
      NAME: `Name`,
      NAME_OR_EMAIL: `Name or email`,
      NAME_PRO_ACCOUNT: `Pro account full name`,
      NEW_ACCOUNT_TYPE_NAME: `New account type name`,
      NUM_OF_DAYS: `Free days`,
      NUM_OF_LICENSES: `Number of licenses`,
      OTHER: `Other`,
      PAIN: `Pain`,
      PATIENT: `Patient`,
      PATIENT_RECORD: `Patient record`,
      PATIENTS: `Patients`,
      PERMISSIONS: `Permissions`,
      PLEASE_REGISTER_BEFORE_SOCIAL_SIGN_IN:
        'Please register your account first before attempting to sign in with a Google account',
      PLEASE_SET_A_PRIVATE_PASSWORD_BEFORE_SOCIAL_SIGN_IN:
        'Please set a private password for your account before attempting to sign in with a Google account',
      PREVIEW: `Preview`,
      PRICE: `Patient Subsciption ({{ currencySymbol }})`,
      REFERRAL: `Referral`,
      REFERRALS: `Referrals`,
      REFERRED_BY: `Referred by`,
      REFERRED_BY_REFERRER: `Referred by {{ referrerName }} on`,
      REPORTING: `Reporting`,
      REPORTING_DESCR: `Select the types of reports this account can receive`,
      REPORTING_PAIN: `Pain Levels`,
      REPORTING_MUSIC_STATS: `Music Statistics`,
      REPORTING_PATIENT_RECORDS: `Patient Records`,
      REPRESENTATIVES: `Representatives`,
      SAVE_ACCOUNT_EDITS: `Save account edits`,
      SELECT_PATIENT_RECORD_QUESTIONS: `Select which of the questions below will be presented to the patient.`,
      SENOFI: `Senofi`,
      SESSIONS: `Sessions`,
      SINCE: `Since`,
      SLEEP: `Sleep`,
      SONG: `Song`,
      TYPE: `Type`,
      USERS: `users`,
    },
    auth: {
      ACCOUNT_CREATED_SUCCESSFULLY: `Account registered successfully`,
      // PLEASE_FILL_ALL_FIELDS: `Please fill in all fields`,
      ALREADY_RECEIVED_CODE: `Already received a code?`,
      AN_ERROR_OCCURRED: `Error: Unable to complete request`,
      // AUTHENTICATION_ERROR: `Invalid email or password`,
      CODE_CANNOT_BE_EMPTY: `Code field cannot be empty`,
      COMPLETE_REGISTRATION: `Complete your registration`,
      COMPLETE_REGISTRATION_DESCR: `Set a password to complete the registration of your pro account`,
      CONFIRM_PASSWORD: `Confirm password`,
      CONFIRMATION_CODE: `Confirmation code`,
      // CONNECTION_ERROR: `Cannot connect`,
      ERROR: `An error occured`,
      ERROR_403_FORBIDDEN: `Forbidden`,
      ERROR_403_FORBIDDEN_DESC: `You are not allowed to access this resource.`,
      ERROR_404_PAGE_NOT_FOUND: `Page not found`,
      ERROR_404_PAGE_NOT_FOUND_DESC: `The page you are looking for might have been removed.`,
      ERROR_500_INTERNAL_SERVER_ERROR: `Internal server error`,
      ERROR_500_INTERNAL_SERVER_ERROR_DESCR: `The server encountered something unexpected that didn't allow it to
      complete the request.`,
      EMAIL_ADDRESS: `Email address`,
      EMAIL_FIELD_CANT_BE_EMPTY: `Email field can't be empty`,
      // EMAIL_IS_REQUIRED: `Email is required`,
      ENTER_CONFIRMATION_CODE_AND_NEW_PASSWORD: `Enter the confirmation code that was sent to your email along with your new password`,
      ENTER_EMAIL_TO_RESET_PASSWORD: `Enter your email to reset your password`,
      FORGOT_PASSWORD: `Forgot password`,
      GO_BACK: `Go back`,
      INCORRECT_USERNAME_OR_PASSWORD: `Incorrect email or password`,
      INVITE_OR_USER_EMAIL_ALREADY_EXISTS: `An account associated with this email already exists`,
      INVALID_EMAIL: `Invalid email format`,
      INVALID_INVITE: `Invalid or expired invite`,
      INVALID_VERIFICATION_CODE_PROVIDED_PLEASE_TRY_AGAIN: `Invalid verification code provided`,
      INVALID_DISTRIBUTION: `Invalid distribution link`,
      INVITE_TERMS_UNCHECKED: `Veuillez indiquer que vous avez reçu le consentement de votre patient`,
      JOIN_TERMS_UNCHECKED: `Veuillez accepter les condition de ventes et notre politique de donnes pour completer votre inscription`,
      LINK_TO_RESET_PASSWORD_SENT: `A link to reset your password has been sent to your email`,
      LOG_IN: `Enter your email address to create a free account`,
      MUST_BE_A_PRO_USER: `Must be a PRO user to log in`,
      NETWORK_ERROR: `Network error`,
      NEW_PASSWORD: `New password`,
      PASSWORD: `Password`,
      // PASSWORD_IS_TOO_LONG: `Password is too long`,
      // PASSWORD_IS_TOO_SHORT: `Password is too short`,
      PASSWORD_ATTEMPTS_EXCEEDED: `Too many login attempts`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_NOT_LONG_ENOUGH: `A password must be at least 8 characters long`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_UPPERCASE_CHARACTERS: `A password must contain one of each: a lower case and uppercase letter, a number, a special character`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_LOWERCASE_CHARACTERS: `A password must contain one of each: a lower case and uppercase letter, a number, a special character`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_NUMERIC_CHARACTERS: `A password must contain one of each: a lower case and uppercase letter, a number, a special character`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_SYMBOL_CHARACTERS: `A password must contain one of each: a lower case and uppercase letter, a number, a special character`,
      PLEASE_COMPLETE_E_LEARNING_MODULE: `Please complete the e-learning module before registration`,
      PASSWORD_FIELD_CANT_BE_EMPTY: `Password field can't be empty`,
      PASSWORD_MUST_CONTAIN: `Password must contain at least one number, one uppercase, one lowercase, and one special case character`,
      PASSWORD_MUST_BE: `A password must contain each of: a lower case and uppercase letter, a number, a special character`,
      PASSWORDS_DONT_MATCH: `Passwords do not match`,
      REGISTER: `Register`,
      REMEMBER_ME: `Remember me`,
      REPLACE_ASSIGNED_TEMPORARY_PASSWORD: `You are required to replace your assigned temporary password before being able to sign in`,
      RESET_PASSWORD: `Reset password`,
      RETURN_TO_WEBSITE: `Return to website`,
      RPPS_INVALID: 'Your RPPS number was invalid',
      // SIGN_UP: `Sign up`,
      // PASSWORD_IS_REQUIRED: `Password is required`,
      SAVE_PASSWORD: `Save password`,
      SET_YOUR_PASSWORD: `Set your new password`,
      SIGN_IN: `Sign in`,
      SIGN_IN_TO_YOUR_ACCOUNT: `Sign in to your account to continue`,
      SIGN_IN_WITH_GOOGLE: `Sign in with Google`,
      SUCCESSFULLY_SAVED_PASSWORD: `Successfully saved password`,
      TEMPORARY_PASSWORD_HAS_EXPIRED_AND_MUST_BE_RESET_BY_AN_ADMINISTRATOR: `The temporary password has expired and must be reset by an administrator`,
      TOGGLE_PASSWORD_VISIBILITY: `Toggle password visibility`,
      WELCOME_TO_MUSIC_CARE: `Welcome to Music Care Admin Tool`,
      USER_DOES_NOT_EXIST: `User does not exist`,
    },
    dashboardPage: {
      DASHBOARD: `Dashboard`,
      TOP_LOCATIONS_USING_MUSIC_CARE: `Top locations`,
      TOP_MUSIC: `Top music`,
      TOP_PRESCRIBERS: `Top Prescribers`,
      WELCOME_BACK: `Welcome back`,
    },
    distributionsPage: {
      DISTRIBUTIONS: `Distributions`,
      REGISTRATION_LINK: `Lien d'inscription`,
    },
    eLearningPage: {
      BEGIN_E_LEARNING: `Start`,
      INVALID_INVITE: `Invalid or expired invitation`,
      UNABLE_TO_BEGIN_TRAINING: `Error: Unable to begin the eLearning module`,
      WELCOME_SUBTEXT: ``,
      WELCOME_TEXT: ``,
      WELCOME_TO_MUSIC_CARE: ``,
    },
    footer: {
      HELP_CENTER: `Help Center`,
      PRIVACY: `Privacy Policy`,
      SIGN_UP: `Sign up for Music Care`,
      SUPPORT: `Support`,
      TERMS_OF_SERVICE: `Terms of Service`,
    },
    general: {
      ARCHIVE: `Archive`,
      BY: `by`,
      CANCEL: `Cancel`,
      DAY: `Day`,
      DAYS: `Days`,
      DELETE: `Delete`,
      DELETING: `Deleting`,
      EDIT: `Edit`,
      EDITING: `Editing`,
      SAVE: `Save`,
      SELECT_ALL: `Select all`,
      SELECTED: `selected`,
      THERE_ARE: `There are`,
      THERE_IS: `There is`,
      UNARCHIVE: `Unarchive`,
    },
    genresPage: {
      ACCEPTS_IMAGE_FILES: `Accepts both 'png' and 'jpeg' image file types`,
      ACTIONS: `Actions`,
      ADD_NEW_GENRE: `Add a new genre`,
      ADD_GENRE: `Add Genre`,
      DELETE_GENRE_BODY: `This action will irreversibly delete this genre. Are you sure you want to proceed?`,
      DELETE_GENRE_BODY_MUST_CHANGE: `{{ numSongsSentence }} song{{ plural }} that currently belong{{ notPlural }} to this genre. Please choose a different genre that they can be assigned to before this one is deleted.`,
      DELETE_GENRE_BODY_CANT_DELETE: `Since there are songs that are assigned to this genre and there are no other genres available, this genre cannot be deleted. In order to delete this genre, please create another genre that these songs could be reassigned to first.`,
      DELETE_GENRE_ASSIGN_TO: `Genre to assign songs to`,
      DELETE_GENRE_WARNING: `WARNING!`,
      DELETE_REASSIGN: `Reassign & delete genre`,
      FILE_IS_NOT_AN_IMAGE: `File is not an image`,
      GENRE_ALREADY_EXISTS: `A genre with this name already exists`,
      GENRE_EDIT_PROPERTIES: `Edit the properties of a genre.`,
      GENRE_IMAGE: `Genre Image`,
      GENRE_MAX_LENGTH: `Max length: {{ maxLength }} characters`,
      GENRE_NAME_CANT_BE_EMPTY: `Genre name field cannot be empty`,
      GENRE_NAME_EN: `Genre name EN`,
      GENRE_NAME_FR: `Genre name FR`,
      GENRE_PROPERTIES: `Genre Properties`,
      GENRES: `Musical Genres`,
      GENRES_MANAGEMENT: `Genres Management`,
      GENRES_TABLE: `Genres Table`,
      IMAGE_FILE_NAME: `Image file name`,
      INVALID_POSITION_VALUE: `Invalid position value`,
      MAX_FILE_SIZE_IS: `Max file size is {{ maxFileSize }}`,
      NAME_EN: `Name EN`,
      NAME_FR: `Name FR`,
      NUMBER_OF_SONGS: `Number of songs`,
      POSITION: `Position`,
      POSITION_AMONG_GENRES: `Position among genres`,
      PREVIEW: `Preview`,
      SELECT_IMAGE_PREVIEW: `Select an image to see a preview`,
      UPLOADED_IMAGE: `Uploaded image`,
    },
    header: {
      INVITE_PATIENT: `Invite patient`,
      INVITE_PATIENT: `Invite patient`,
      OPEN_DRAWER: `Open drawer`,
      PROFILE: `Profile`,
      SEARCH: `Search`,
      SIGN_OUT: `Sign out`,
    },
    invitationsPage: {
      ACCEPTED: `Accepted`,
      DOCTOR: `Doctor`,
      INVITATIONS: `Invitations`,
      INVITE_DOCTORS: `Invite doctors to join your Music Care network.`,
      PATIENT: `Patient`,
      PRO: `Pro`,
      RESEND: `Resend`,
      REVOKE: `Revoke`,
      SENT: `Sent`,
      YOU_WILL_SEE_THEIR_ACTIVITY: `You will see their activity and that of their patients in your reports.`,
      YOUR_PRESCRIBER_CODE: `Your prescriber code is`,
    },
    invitePage: {
      ACCESS_DEMONSTRATION: `Demonstration access`,
      ACCESS_APPLICATION: `Application access`,
      CHOOSE_TYPE_OF_INVITATION: `Choose an invitation type`,
      DASHBOARD_AND_REPORTS: `Dashboard and reports`,
      DOCTOR: `Doctor`,
      EVERY_USER_WILL_RECEIVE_INVITATION: `Every new user will receive an invitation email with an E-learning link`,
      INVITATION_GP: `GP Invitation`,
      INVITAION_PATIENT: `Patient Invitation`,
      INVITE: `Invite`,
      INVITE_USER: `Invite user`,
      MED_REP: `Med Rep`,
      PATIENT: `Patient`,
      PATIENT_HEALTH_DOSSIER: `Patient health dossier`,
      PRO: `Pro`,
    },
    inviteFormPage: {
      ACCOUNT_TYPE: `Account type`,
      AN_ERROR_OCCURRED: `An error eccured when attempting to send the emails.`,
      CLOSE: `Close`,
      COMPLETE_SIGN_UP: `Complete sign up`,
      CREATE_A_DISTRIBUTION: `Create a distribution`,
      CREATE_DISTRIBUTION: `Create`,
      DISTRIBUTION_CHANNEL_EXISTS: `A distribution with this name already exists`,
      DISTRIBUTION_FORM: `Distribution form`,
      DISTRIBUTION_LINK: `Distribution Link`,
      DISTRIBUTION_LINK_DESCR: `Eligendi laborum quia expedita voluptatibus ut aspernatur. Et iure enim qui asperiores vel non et vel. Aut sed veniam non et porro vero minima hic.`,
      DOCTOR: `Doctor`,
      EMAIL_ADDRESS: `Email address`,
      EMAIL_ALREADY_ENTERED: `This email has already been entered above`,
      EMAIL_ADDRESS_EXISTS: `An account with this email address already exists`,
      EMAIL_ADDRESS_INVALID: `Invalid email format`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      GO_BACK: `Go back`,
      INVALID_REFERRAL_CODE: `Invalid referral code`,
      INVITE: `Invite`,
      INVITE_DOCTOR: `Invite doctors`,
      INVITE_FORM: `Invite form`,
      INVITE_PATIENT: `Invite patients`,
      LEGAL_DISCLAIMER: `I certify that I have informed my patient and obtained his express consent, in accordance with the `,
      LEGAL_DISCLAIMER_2: `, to communicate the above email address to Music Care so that the latter can offer him its services`,
      LEGAL_DISCLAIMER_LINK: `applicable regulations on personal data and the MUSIC CARE T & Cs`,
      NAME_CAN_BE_AT_MOST: `A name can be at most 40 characters`,
      NAME_IS_REQUIRED: `A name is required to create a distribution`,
      NAME_IS_TOO_SHORT: `A name must be at least 3 characters long`,
      NAME_MAY_NOT_CONTAIN_ANY_SPECIAL_CHARACTERS: `A name may not contain any special characters`,
      NEW_DISTRIBUTION_NAME: `New distribution name`,
      PATIENT: `Patient`,
      PRESCRIBER_CODE_TOO_SHORT: `A prescriber code is 8 characters long`,
      PRESCRIBER_CODE: `Prescriber Code (*Optional)`,
      SEND_INVITATIONS: `Send invitations`,
    },
    links: {
      ABOUT_MUSIC_CARE: `https://www.music-care.com/en/company.html`,
      APP_APPLE_STORE: `https://apps.apple.com/ca/app/music-care/id849636644`,
      APP_GOOGLE_STORE: `https://play.google.com/store/apps/details?id=com.o12s.musiccare`,
      CONTACT: `https://app.music.care/contact`,
      FAQ: `https://www.music-care.com/en/help.html`,
      HOMEPAGE: `https://www.music-care.com`,
      PRIVACY: `https://www.music-care.com/en/personal-data.html`,
      TERMS_DOCTOR: `https://www.music.care/conditions-prescripteurs.html`,
      TERMS_PRO: `https://www.music.care/conditions-professionnels.html`,
      TERMS_PRO_2: `https://www.music.care/politiques.html`,
      TERMS_PRO_3: `https://docs.google.com/forms/d/e/1FAIpQLScAELoSaTvGcPgAj5_UbsMB49kZ_BmaJnEaQo8qgZ1kzp-9kQ/viewform`,
      LEGAL_DISCLAIMER: `https://www.music.care/conditions-pro.html`,
    },
    musicStatisticsPage: {
      ALL: `All`,
      ANXIETY: `Anxiety`,
      AWAKENING: `Awakening`,
      END: `End`,
      EXPORT: `Export`,
      FILTER_CRITERIA: `Filter criteria`,
      INDICATION: `Indication`,
      LISTENING_STATISTICS: `Listening Statistics`,
      MUSIC_RIGHTS: `Music Rights`,
      MUSIC_STATISTICS: `Music Statistics`,
      SEARCH: `Search`,
      SELECT_START_AND_END: `Select a start and an end date`,
      SESSION_STATISTICS: `Session statistics for PRO accounts`,
      SLEEP: `Sleep`,
      SONG_STATISTICS: `Song statistics`,
      START: `Start`,
      STATS_EXPORT: `music_statistics`,
      PAIN: `Pain`,
    },
    profilePage: {
      ADDRESS: `Address`,
      ADDRESS_CONTINUED: `Address continued`,
      CHANGE_IMAGE: `Change`,
      CHANGE_EMAIL: `Change email address`,
      CHANGE_PASSWORD: `Change email address`,
      CONFIRM_EMAIL: `Confirm new email`,
      CONFIRM_EMAIL_CHANGE: `Confirm email change`,
      CONFIRM_PASSWORD: `Confirm password`,
      CONFIRM_PASSWORD_CHANGE: `Confirm password change`,
      COUNTRY: `Country`,
      CITY: `City`,
      CURRENT_PASSWORD: `Current password`,
      DOCTOR_ID: `RPPS Number`,
      EMAIL_ADDRESS: `Current email address`,
      EMAILS_MUST_MATCH: `Emails must match`,
      EXPIRED: `Expired`,
      FIELD_CAN_BE_AT_MOST: `{{ fieldName }} cannot be longer than {{ max }} characters`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      FIELD_IS_REQUIRED: `Field is required`,
      IMAGE_RECOMMENDATION: `An image at least 128px by 128px in .jpg format is recommended`,
      INVALID_PASSWORD: `Invalid password`,
      MUST_BE_A_VALID_EMAIL: `Must be a valid email`,
      NAME: `Full name`,
      NEW_EMAIL: `New email`,
      NEW_PASSWORD: `New password`,
      NEW_PASSWORD_CONFIRM: `Confirm new password`,
      OF: `of`,
      OF_MASC: `of`,
      PASSWORD_CAN_BE_AT_MOST: `Password can be at most {{ max }} characters`,
      PASSWORD_MUST_CONTAIN: `Password must contain at least one number, one uppercase, one lowercase, and one special case character`,
      PASSWORD_IS_TOO_SHORT: `Password is too short`,
      PASSWORDS_DO_NOT_MATCH: `Passwords do not match`,
      PERSONAL_USAGE_LEFT: `Personal usage left`,
      PLEASE_CONTACT_ADMIN: `Please contact your administrator for more information.`,
      POSTAL_CODE: `Postal code`,
      PROFILE: `Profile`,
      SAVE: `Save`,
      STATE_ETC: `State, Provice, etc.`,
      THE_APOSTROPHE: ``, // leave empty, only relevant in french translation
      THE_MASC: ``,
      THE_FEMIN: ``, // leave empty, only relevant in french translation
      YOU_WERE_INVITED_BY: `You were invited by`,
      YOUR_ACCESS: `Your access`,
      YOUR_INFORMATION: `Your information`,
      USER_AVATAR: `User avatar`,
    },
    registrationPage: {
      ACCOUNT_CREATED_SUCCESSFULLY: `Account registered successfully`,
      CONGRATULATIONS: `Congratulations! You have completed the training`,
      CONGRATULATIONS_SUBTITLE: `Please create your free account to recommend Music Care to your patients through demonstrator access and your patient management tool.`,
      DOCTOR_ID: `RPPS number`,
      DOCTOR_ID_CAN_BE_AT_MOST: `RPPS number can be at most {{ max }} characters`,
      DOCTOR_ID_IS_REQUIRED: `RPPS number is required`,
      DOCTOR_REGISTRATION: `Doctor registration`,
      EMAIL: `Email`,
      LEGAL_DOCTOR_A: `J’ai lu et accepte expressément les `,
      LEGAL_DOCTOR_B: ` de MUSIC CARE.`,
      LEGAL_DOCTOR_DESCR_A: `Vos données personnelles ici collectées sont utilisées conformément à la `,
      LEGAL_DOCTOR_DESCR_B: ` (Article B§3) de MUSIC CARE aux fins de répondre à ses obligations précontractuelles et/ou contractuelles à votre égard dans le cadre la fourniture des services de MUSIC CARE liés à votre Compte. Pour toute question relative à vos données personnelles, vous pouvez contacter son délégué à la protection des données (ci-après « DPO ») joignable à l’adresse postale MUSIC CARE – DPO 7 rue Popincourt – 75011 Paris (France) ou par email : dpo@music.care`,
      LEGAL_DOCTOR_DESCR_LINK: `Politique de Protection des Données`,
      LEGAL_DOCTOR_LINK: `Conditions Générales d’Utilisation des Services`,
      LEGAL_DOCTOR_C_1: `J’accepte que mes Données de Santé soient utilisées par MUSIC CARE suivant sa Politique de `,
      LEGAL_DOCTOR_C_2: ` de façon totalement anonymisée pour des recherches scientifiques pour le développement des services thérapeutiques de MUSIC CARE. Je peux m’opposer à cet usage à tout moment en cliquant `,
      LEGAL_DOCTOR_C_3: ` ou sur demande écrite aux coordonnées précitées.`,
      LEGAL_DOCTOR_LINK_2: `Protection des Données`,
      LEGAL_DOCTOR_LINK_3: `ICI`,
      NAME: `Name`,
      NAME_CAN_BE_AT_MOST: `Name can be at most {{ max }} characters`,
      NAME_IS_REQUIRED: `Name is required`,
      PASSWORD: `Password`,
      PASSWORD_CONFIRM: `Confirm password`,
      PASSWORD_CAN_BE_AT_MOST: `Password can be at most {{ max }} characters`,
      PASSWORDS_DO_NOT_MATCH: `Passwords do not match`,
      PASSWORD_IS_REQUIRED: `Password is required`,
      PASSWORD_IS_TOO_SHORT: `Password is too short`,
      PASSWORD_MUST_CONTAIN: `Password must contain at least one number, one uppercase, one lowercase, and one special case character`,
      REGISTER: `Register`,
      RPPS_INVALID: 'Your RPPS number was invalid',
      TOGGLE_PASSWORD_VISIBILITY: `Toggle password visibility`,
    },
    routes: {
      ACCESS_APP: `App Access/Demo`,
      ACCOUNTS: `Accounts`,
      ALL: `All`,
      CONTACT_US: `Contactez nous`,
      CREATE_ACCOUNT_DOCTOR: `Doctor Account`,
      CREATE_ACCOUNT_INDIVIDUAL: `Individual Account`,
      CREATE_ACCOUNT_PRO: `PRO Account`,
      CREATE_AN_ACCOUNT: `Create an Account`,
      CREATE_DISTRIBUTION: `Distribution Account`,
      DASHBOARD: `Dashboard`,
      DISTRIBUTION: `Distribution`,
      DISTRIBUTIONS: `Distributions`,
      DOCTORS: `Doctors`,
      EDIT_ACCOUNT: `Edit an Account`,
      FAQ: `FAQ`,
      GENRES: `Genres`,
      HOSPITALS: `Hospitals`,
      INDEPENDENT_PRACTITIONERS: `Independent Practitioners`,
      INDIVIDUAL: `Individual`,
      INVITATIONS: `Invitations`,
      LISTENING_STATISTICS: `Listening Statistics`,
      MUSIC: `Music`,
      MUSIC_CARE_ADMIN: `Music Care Admin`,
      MY_PRO_ACCOUNT: `My Pro Account`,
      PATIENTS: `Patients`,
      PROFESSIONAL: `Professional`,
      PROFILE: `Profile`,
      REGISTRATION_DOCTOR: `Doctor Registration`,
      REGISTRATION_PRO: `Pro Registration`,
      REPRESENTATIVES: `Representatives`,
      SIGN_UP: `Sign Up`,
      SONGS: `Songs`,
      SUBSCRIPTIONS: `Subscriptions`,
      TRAINING: `Training`,
    },
    signUpPage: {
      ACCOUNT_SUCCESSFULLY_CREATED: `Account successfully created. Please check your email.`,
      AN_ERROR_OCCURRED: `Error: unable to complete request`,
      CONFIRM_PASSWORD: `Confirm password`,
      CONTINUE_TO_SIGNUP: `Continue to signup`,
      DISPLAY_TRAINING_FORM: `Register For Training`,
      EMAIL_ADDRESS: `Email`,
      FIELD_CAN_BE_AT_MOST: `Field can be at most {{ maxLen }} characters`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      MUST_BE_A_VALID_EMAIL: `Must be a valid email`,
      NAME: `Full name`,
      OR: `OR`,
      PLEASE_CHECK_YOUR_EMAIL: `Please check your email`,
      REGISTRATION: `Registration`,
      SIGN_UP: `Sign up`,
      SUBMIT_EMAIL: `Register`,
      TOGGLE_PASSWORD_VISIBILITY: `Toggle password visibility`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION: `Offer your patients new management of their chronic pain.`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION_DESCR: `The Music CARE web-app has proven itself in hospitals and has been validated by more than 150 scientific publications. Music CARE is now available for your patients. With this short presentation, you will learn why and how to add a dose of music to your prescriptions. Enter your email address to get started:`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION_FOOTER: `Your personal data managed here is in accordance with the data protection policy (article B§7) of MUSIC CARE for the purposes of meeting its pre-contractual obligations or your compliance within the framework of the MUSIC CARE training to which you subscribe. If you do not create an MC account at the end of this training, your personal data entered here will be deleted within a maximum of 30 days following the end of the training. For any questions relating to your personal data, you can contact its data protection officer (hereinafter "DPO") at the postal address MUSIC CARE - DPO 7 rue Popincourt - 75011 Paris (France) or by email: dpo@music.care`,
      YOU_WERE_REFERRED_BY: `You were referred by {{ honorific }}{{ referrerName }}`,
    },
    snackbar: {
      ACCOUNT_EDIT_SNACKBAR_FAIL: `Unable to edit account`,
      ACCOUNT_EDIT_SNACKBAR_SUCCESS: `Successfully edited account`,
      AVATAR_FILE_IS_TOO_LARGE: `File is too large`,
      AVATAR_FILE_IS_NOT_AN_IMAGE: `File is too large`,
      CREATE_ACCOUNT_SNACKBAR_FAIL: `Failed to invite pro user`,
      CREATE_ACCOUNT_SNACKBAR_SUCCESS: `Successfully invited pro user`,
      DISTRIBUTION_CREATION_FAIL: `Failed to create distribution`,
      DISTRIBUTION_CREATION_SUCCESS: `Successfully created distribution`,
      EDIT_ACCOUNT_SNACKBAR_FAIL: `Failed to update pro account`,
      EDIT_ACCOUNT_SNACKBAR_SUCCESS: `Successfully updated pro account`,
      GENRE_ADD_SNACKBAR_FAIL: `Failed to add genre`,
      GENRE_ADD_SNACKBAR_SUCCESS: `Successfully added genre`,
      GENRE_DELETE_SNACKBAR_FAIL: 'Unable to delete genre',
      GENRE_DELETE_SNACKBAR_SUCCESS: 'Genre successfully deleted',
      GENRE_EDIT_SNACKBAR_FAIL: `Failed to edit genre`,
      GENRE_EDIT_SNACKBAR_SUCCESS: `Successfully edited genre`,
      IMAGE_UPLOAD_FAIL: `Unable to upload image`,
      INVITES_CODE_FAIL: `Invalid referral code provided`,
      INVITES_FETCH_FAIL: `Unable to obtain invites`,
      INVITES_SENT_FAIL: `Unable to create invites for some of the requested emails`,
      IMAGE_UPLOAD_FILE_IS_NOT_AN_IMAGE_FAIL: `Selected file is not a valid image`,
      IMAGE_UPLOAD_FILE_IS_TOO_LARGE_FAIL: `Selected file is too large`,
      IMAGE_UPLOAD_SUCCESS: `Successfully updated avatar image`,
      INVITES_SENT_SUCCESS: `Successfully sent invitations to the provided email(s)`,
      INVITE_RESEND_FAIL: `Unable to resend invite`,
      INVITE_RESEND_SUCCESS: `Invite successfully resent`,
      INVITE_REVOKE_FAIL: `Unable to revoke invite`,
      INVITE_REVOKE_SUCCESS: `Invite successfully revoked`,
      LOG_OUT_ALL_DEVICES_FAIL: `Unable to logout of devices using this account`,
      LOG_OUT_ALL_DEVICES_SUCCESS: `Successfully logged out on all devices using this account`,
      MUSIC_STATISTICS_FAIL: `Unable to download statistics at this time`,
      PASSWORD_LIMIT_EXCEEDED: `Password change attempt limit exceeded, please try after some time`,
      PASSWORD_UPDATE_FAIL: `Could not update password`,
      PASSWORD_UPDATE_SUCCESS: `Updated password successfully`,
      PROFILE_UPDATE_FAIL: `Could not update profile`,
      PROFILE_UPDATE_SUCCESS: `Updated profile successfully`,
      REGISTER_DISTRIBUTION_SNACKBAR_FAIL: `Unable to register`,
      REGISTER_DISTRIBUTION_SNACKBAR_SUCCESS: `Successfully sent a registration email`,
      REGISTER_DOCTOR_SNACKBAR_SUCCESS: `Successfully registered doctor account`,
      REGISTER_PRO_SNACKBAR_SUCCESS: `Successfully registered pro account`,
      SONG_ADD_SNACKBAR_FAIL: `Failed to add song`,
      SONG_ADD_SNACKBAR_SUCCESS: `Successfully added song`,
      SONG_ARCHIVE_FAIL: `Failed to archive song`,
      SONG_ARCHIVE_SUCCESS: `Song successfully archived`,
      SONG_DELETE_SNACKBAR_FAIL: `Failed to delete song{{ plural }}`,
      SONG_DELETE_SNACKBAR_SUCCESS: `Successfully deleted song{{ plural }}`,
      SONG_EDIT_SNACKBAR_FAIL: `Failed to edit song{{ plural }}`,
      SONG_EDIT_SNACKBAR_SUCCESS: `Successfully edited song{{ plural }}`,
      SONG_UNARCHIVE_FAIL: `Failed to unarchive song`,
      SONG_UNARCHIVE_SUCCESS: `Song successfully unarchived`,
      SONGS_EDIT_SNACKBAR_FAIL: `Failed to update {{ numErrors }} song{{ plural }}`,
      SONGS_EDIT_SNACKBAR_SUCCESS: `Successfully updated {{ numSongs }} songs`,
      UNABLE_TO_SEND_INVITES: `Unable to send invites`,
    },
    songsPage: {
      // Currently app saves downloaded songs as 'mp3' files by default, until change it there to allow m4a handling, only mp3 is allowed
      // ACCEPTS_AUDIO_FILES: `Accepts 'mp3' and 'm4a' audio files`,
      ACCEPTS_AUDIO_FILES: `Accepts 'mp3' audio files`,
      ACCEPTS_IMAGE_FILES: `Accepts both 'png' and 'jpeg' image file types`,
      ACTIONS: `Actions`,
      ACTIVE: `Active`,
      ADD_AN_INSTRUMENT_EN: ` `,
      ADD_AN_INSTRUMENT_FR: ` `,
      ADD_NEW_SONG: `Add a new song`,
      ADD_SONG: `Add Song`,
      ALL: `All`,
      ARTIST: `Artist`,
      ARCHIVED: `Archived`,
      AUDIO_FILE: `Audio File`,
      AUDIO_FILE_A: `Audio File A Tempo 1-3`,
      AUDIO_FILE_B: `Audio File B Tempo 4-7`,
      AUDIO_FILE_C: `Audio File C Tempo 8-10`,
      DELETE_SONG_BODY: `Deleting a song will remove its audio file as well as all of its information from the song directory. Are you sure you wish to delete this song?`,
      DELETE_SONG_TITLE: `Delete a song`,
      DELETE_SONG_WARNING: `WARNING!`,
      DESCRIPTION_EN: `Description EN`,
      DESCRIPTION_FR: `Description FR`,
      EDIT_PROPERTIES_OF: `Edit the properties of {{ editTarget }}.`,
      EDIT_SONGS: `Edit Song(s)`,
      EDITING: `Editing`,
      EDITING_SONGS: `Editing {{ numberEdited }} songs`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      FILE_NAME: `File name`,
      FILE_IS_NOT_AN_IMAGE: `File is not an image`,
      FILE_IS_NOT_AUDIO: `Loaded file is not  valid audio file`,
      FILE_IS_TOO_LARGE: `File can be at most {{ maxFileSize }}mb`,
      FILE_LENGTH_NEEDS_TO_BE_AT_LEAST: `Audio file needs to be at least {{ minDuration }} seconds long`,
      FILL_PROPERTIES: `Fill in the properties of the song to be added`,
      GENRE: `Genre`,
      IMAGE: `Image`,
      INSTRUMENT_EN: `Instrument EN`,
      INSTRUMENT_EXISTS: `Instrument already exists`,
      INSTRUMENT_FR: `Instrument FR`,
      INSTRUMENT_PAIRS: `Instrument pairs`,
      INSTRUMENTS: `Instruments`,
      INSTRUMENTS_MODIFICATION_NOT_AVAILABLE: `Modification of instruments is not available when editing multiple songs.`,
      INVALID_POSITION_VALUE: `Invalid position value`,
      IS_NEW: `Is new`,
      LENGTH_SET_AUTOMATICALLY: `Length is set automatically`,
      LOOP_PROPERTIES: `Loop Properties`,
      MARK_AS_NEW_UNTIL: `Mark as 'New' Until`,
      MAX_FILE_SIZE_IS: `Max file size is {{ maxFileSize }}`,
      MODIFICATION_LOOP_NOT_AVAILABLE: `Modification of loop properties is not available when editing multiple songs.`,
      MULTIPLE_SONGS: `multiple songs at once`,
      MUST_BE_MORE_THAN_LESS_THAN: `Must be more than time {{ timeVal1 }} & less than time {{ timeVal2 }}`,
      MUST_BE_MORE_THAN_TIME: `Must be more than time{{ plural }} {{- timeVal }}`,
      MUST_BE_LESS_THAN_LENGTH: `Must be less than length`,
      MUST_BE_LESS_THAN_TIME: `Must be less than time{{ plural }} {{- timeVal }}`,
      NEW_STATUS: `'New' status`,
      NEW_STATUS_WILL_BE_INDICATED: `'New' status will be indicated until this date (inclusive)`,
      NO_PREVIEWING_IMAGE_WHEN_MULTIPLE: `Previewing and replacing an image not available when editing multiple songs.`,
      NO_REPLACING_IMAGE_WHEN_MULTIPLE: `Replacing an audio file not available when editing multiple songs.`,
      POSITION: `Position`,
      POSITION_MODIFICATION_NOT_AVAILABLE: `Position modification is not available when editing multiple songs.`,
      POSITION_IN_GENRE1: `Position within first genre`,
      POSITION_IN_GENRE2: `Position within second genre`,
      POSITION_IN_GENRE3: `Position within third genre`,
      PREVIEW: `Preview`,
      SELECT_AUDIO_TO_ANALYZE: `Select an audio file to analyze length`,
      SELECT_AUDIO_FIRST: `Select an audio file first`,
      SELECT_IMAGE_PREVIEW: `Select an image to see a preview`,
      SINGLE_SONG: `a single song`,
      SONG_ID: `Song ID`,
      SONG_ALBUM_COVER: `Song album cover image`,
      SONG_EXISTS_WITH_THIS_ARTIST_AND_TITLE: `A song already exists with this artist and genre`,
      SONG_MAX_LENGTH: `Max length: {{ maxLength }} characters`,
      SONG_PROPERTIES: `Song Properties`,
      SONGS: `Songs`,
      SONGS_MANAGEMENT: `Songs Management`,
      SONGS_TABLE: `Songs Table`,
      SONGS_WITH_MULTIPLE_ARTISTS_SELECTED: `Multiple artists selected`,
      SONGS_WITH_MULTIPLE_GENRES_SELECTED: `Multiple genres selected`,
      STATUS: `Status`,
      TIME_NUMBER: `Time {{ number }} (s)`,
      TITLE: `Song`,
      TITLE_EN: `Song EN`,
      TITLE_FR: `Song FR`,
      TOTAL_LENGTH: `Total length (s)`,
      UPDATED: `Updated`,
      USING_UPLOADED_FILE: `Using currently uploaded file`,
    },
    stats: {
      ACTIVE_PRO_ACCOUNTS: `Active Pro Accounts`,
      AFTER: `After`,
      AGE: `Age`,
      ANXIETY: `Anxiety`,
      ANXIETY_BEFORE: `Anxiety Before`,
      ANXIETY_AFTER: `Anxiety After`,
      AVG_ANXIETY: `Avg Anxiety`,
      AVG_ANXIETY_AFTER: `Avg Anxiety After`,
      AVG_ANXIETY_BEFORE: `Avg Anxiety Before`,
      AVG_CHANGE: `Avg Change`,
      AVG_PAIN: `Avg Pain`,
      AVG_PAIN_AFTER: `Avg After`,
      AVG_PAIN_BEFORE: `Avg Before`,
      AVG_TIME: `Avg Time`,
      AWAKENING: `Awakening`,
      AWAKENING_AFTER: `Awakening After`,
      BEFORE: `Before`,
      CANCELLED_ACCOUNTS: `Cancelled Accounts`,
      CONVERSION_RATE: `Conversion rate`,
      CONVERSIONS: `Conversions`,
      CHANGE: `Change`,
      DAILY: `Daily`,
      DISTRIBUTION_E_LEARNING_COMPLETED: `E-Learning complétés`,
      DISTRIBUTION_E_LEARNING_OPENED: `E-learning ouverts`,
      DISTRIBUTION_PRESCRIBED_BY_DOCTORS: `Médecins qui ont prescrit`,
      DISTRIBUTION_PRESCRIBED_BY_DOCTORS_LAST_MONTH: `Médecins ayant prescrit en 30 jours`,
      DISTRIBUTION_REGISTERED_DOCTORS: `Médecins inscrits`,
      DISTRIBUTION_REGISTERED_PATIENTS: `Patients abonnés`,
      DISTRIBUTION_SESSIONS_PATIENTS_AVG_LAST_MONTH: `Nombre moy. de séances/mois/patient`,
      DISTRIBUTION_REGISTERED_PATIENTS_LAST_MONTH: `Nouveaux abonnés (30j)`,
      DISTRIBUTION_SESSIONS_PATIENTS_LAST_WEEK: `Patients 1 séance/sem min`,
      DOCTORS: `Doctors`,
      GENDER: `Gender`,
      HOSPITALS: `Hospitals`,
      INVITED: `Invited`,
      JOINED: `Joined`,
      LIFETIME_REVENUE: `Lifetime Revenue`,
      MONTHLY_TOTAL: `Monthly Total`,
      NEW_ACCOUNTS: `New accounts`,
      NO_CHANGE: `No Change`,
      NUMBER_OF_LISTENS: `Total Sessions`,
      THIS: `this`,
      TODAY: `Today`,
      PAIN: `Pain`,
      PAIN_AFTER: `Pain After`,
      PAIN_BEFORE: `Pain Before`,
      PAIN_LEVELS: `Pain Levels`,
      PATIENTS: `Patients`,
      PENDING_INVITATIONS: `Pending invitations`,
      PERC_IMPROVEMENT_LEVELS: `% Improvement levels`,
      PRO_ACCOUNTS: `Pro Accounts`,
      RELIEF_IN_PERCENT: `Relief in %`,
      REVENUE_INDIVIDUAL: `Revenue Individual Accounts`,
      REVENUE_PRO: `Revenue Pro Accounts`,
      REVENUE_TOTAL: `Total Revenue`,
      SESSIONS: `Sessions`,
      SLEEP: `Sleep`,
      SLEEP_AFTER: `Sleep After`,
      TOTAL_PATIENTS: `Total Patients`,
      TOTAL_REVENUE: `Total revenue`,
      UPCOMING_REVENUE: `Upcoming Revenue`,
      USERS: `Active Users`,
      WEEK: `week`,
      YEAR_TO_DATE: `Year to Date`,
    },
    subscriptionsPage: {
      A_NAME: `a name`,
      A_NAME_OR_AN_EMAIL: `a name or an email`,
      ALL_SUBSCRIPTIONS: `All subscriptions`,
      AN_EMAIL: `an email`,
      ACTIVE: `Active`,
      ACCOUNT_NAME: `Account name`,
      ASSOCIATED_PRO_ACCOUNT: `Associated Pro Account`,
      ASSOCIATED_DOCTOR_ACCOUNT: `Referring Doctor Account`,
      DAILY_USE: `Daily Use`,
      DECLINED: `Declined`,
      DETAILS_FOR_ACCOUNT_SUBSCRIPTION: `Subscription details for this account are presented below.`,
      EMAIL: `Email`,
      END_DATE: `End Date`,
      INACTIVE: `Inactive`,
      INDIVIDUAL: `Individual`,
      INDIVIDUAL_SUBSCRIPTIONS: `Individual subscriptions`,
      MAKE_PAYMENT: `Make a payment`,
      NAME: `Name`,
      PAYMENT: `Payment`,
      PROFESSIONAL: `Professional`,
      PROFESSIONAL_SUBSCRIPTIONS: `Professional subscriptions`,
      SIGNUP_DATE: `Signup Date`,
      SUBSCRIPTION_DETAILS: `Subscription details`,
      SUBSCRIPTIONS: `Subscriptions`,
      SUBSCRIPTIONS_TABLE_DESCR_A: `The following is a list of {{ type }} subscriptions along with their statistics.`,
      SUBSCRIPTIONS_TABLE_DESCR_B: `Please click on {{ target }} for account details.`,
      VIEW_USAGE: `View usage`,
    },
    table: {
      ACTION: `Action`,
      ACTIONS: `Actions`,
      ACTIVE: `Active`,
      AFTER: `After`,
      AGE: `Age`,
      ARCHIVED: `Archived`,
      AMOUNT: `Amount`,
      APPROVED: `Approved`,
      APPROVAL_STATUS: `Approval Status`,
      AVG_IMPROVEMENT: `Avg Improvement`,
      AVG_PAIN_AFTER: `Avg After`,
      AVG_PAIN_BEFORE: `Avg Before`,
      AVG_SESSION_DURATION: `Avg Session Duration`,
      BEFORE: `Before`,
      CHANGE: `Change`,
      CONNECTIONS: `Connections`,
      COST: `Cost`,
      COUNTRY: `Country`,
      DAILY_USE: `Daily Use`,
      DATE: `Date`,
      DOCTORS: `Doctors`,
      EMAIL: `Email`,
      EMAIL_PATIENT: `Patient email`,
      EMAIL_AND_SONG: `Email and Song`,
      ERROR: `Error`,
      FREE: `Free`,
      GENDER: `Gender`,
      IMPROVEMENT: `Improvement`,
      INACTIVE: `Inactive`,
      INDICATION: `Indication`,
      INVITES_COMPLETED: `Invites completed`,
      INVITES_SENT: `Invites sent`,
      LAST_SESSION: `Last session`,
      LAST_USED: `Last Used`,
      LICENSES: `Licenses`,
      MOST_PLAYED: `Most played`,
      NAME: `Name`,
      NAME_OR_EMAIL: `Name or Email`,
      NUMBER_OF_LISTENS: `Total Sessions`,
      PATIENTS: `Patients`,
      PATIENTS_ACTIVE: `Active patients`,
      PENDING: `Pending`,
      PERCENT_IMPROVEMENT: `% Improvement`,
      PERCENT_PATIENTS: `% Patients`,
      PAYMENT: `Payment`,
      REFERRALS: `Referrals`,
      REPRESENTATIVE: `Representative`,
      SESSION: `Session`,
      SESSION_TYPE: `Session Type`,
      SESSIONS: `Sessions`,
      SINCE: `Since`,
      SIGNUP_DATE: `Signup date`,
      SONG: `Song`,
      STATUS: `Status`,
      TITLE: `Title`,
      TOTAL_PER_SESSION: `Total per session`,
      TYPE: `Type`,
    },
    trainingPage: {
      PLAY: `Play`,
      TRAINING: `Training`,
      TRAINING_MEDIA: `Training Media`,
      TRAINING_VIDEO: `Training video`,
      TRAINING_VIDEO_1: `Video - Hospital Module`,
      TRAINING_VIDEO_2: `Video - EHPAD Module`,
      TRAINING_VIDEO_3: `Video - Clinical Module`,
    },
    userTypes: {
      ADMINISTRATOR: `Music Care Administrator`,
      DOCTOR: `Doctor`,
      PRO_USER: `Music Care Pro User`,
    },
  },
  fr: {
    accountsPage: {
      A_NAME: `un nom`,
      A_NAME_OR_AN_EMAIL: `un nom ou un email`,
      ACCEPTS_IMAGE_FILES: `Accepte les types de fichiers 'png' et 'jpeg'`,
      ACCOUNT_INFO: `Information`,
      ACCOUNT_TYPE: `Type de Compte`,
      ACCOUNT_TYPE_CREATE: `Créer un nouveau type de compte et définir les autorisations manuellement`,
      ACCOUNT_TYPE_DESCR: `Sélectionner un type de compte avec des paramètres d'autorisation pré-remplis ou créer un nouveau type de compte`,
      ACCOUNT_TYPE_NAME_EXISTS: `Le nom du type de compte existe déjà`,
      ACCOUNT_TYPE_SELECT_CREATE: `Sélectionner ou créer un type de compte`,
      ACCOUNTS: `Comptes`,
      ACCOUNTS_UPDATED_FOR_SOCIAL_SIGN_IN: `Compte mis à jour pour la connexion via Google. Veuillez vous reconnecter.`,
      ACTIONS: `Actions`,
      ACTIVE_SINCE: `Actif depuis`,
      ADMIN: `Administrative permissions`,
      ADMIN_CREATE_PRO: `Create pro accounts`,
      ADMIN_DESCR: `Set this account's administrative permissions`,
      ADMIN_MANAGE_MUSIC: `Manage music`,
      ADMIN_VIEW_PRO: `View pro accounts`,
      ALL: `Tous les comptes`,
      ALL_ACCOUNTS: `tous les comptes`,
      AN_EMAIL: `un email`,
      AN_ERROR_OCCURED_PLEASE_TRY_LATER: `Une erreur est survenue. Réessayez plus tard s'il vous plaît.`,
      ANXIETY: `Surmonter le stress`,
      AVG_CHANGE: `Changement Moy.`,
      AVG_PAIN_AFTER: `Moy. Après`,
      AVG_PAIN_BEFORE: `Moy. Avant`,
      AVG_TIME: `Durée du Temps`,
      AWAKENING: `Bien vous réveiller`,
      CHANGE: `Évolution`,
      CREATE_A_NEW_ACCOUNT: `Créer un nouveau compte`,
      CREATE_ACCOUNT: `Créer une invitation de Compte Pro`,
      CREATE_AN_ACCOUNT_INDIVIDUAL: `Créer un Compte Individuel`,
      CREATE_AN_ACCOUNT_PRO: `Créer un Compte PRO`,
      COMPANY_LOGO: `Logo de l'entreprise (facultatif)`,
      COMPANY_LOGO_DESCR: `Choisir le logo à utiliser dans toute l'application`,
      COMPANY_LOGO_IMAGE_FILE: `Fichier image du logo`,
      COMPANY_LOGO_PREVIEW: `Company logo preview`,
      CONNECTION: `Séances`,
      CONNECTIONS: `Séances`,
      CONNECTIONS_TOTAL: `Total des séances`,
      CONVERSION_RATE: `Taux de conversion`,
      CONVERSIONS: `Les conversions`,
      COUNTRY: `Pays`,
      CREATED_BY: `Créé(e) par`,
      DISTRIBUTIONS: `Distributions`,
      DOCTOR: `Médecin`,
      DOCTORS: `Médecins`,
      E_LEARNING_WELCOME: `E-Learning lors de l'accueil des médecins`,
      EDIT_ACCOUNT: `Modifier le Compte`,
      EDIT_AN_ACCOUNT: `Modifier un Compte PRO`,
      EMAIL: `Email`,
      EMAIL_ADDRESS: `Email`,
      EMAIL_ADDRESS_ADMIN: `Email du compte pro`,
      EMAIL_ADDRESS_EXISTS: `Un compte avec cette adresse e-mail existe déjà`,
      EMAIL_ADDRESS_DESCR: `Le nouvel utilisateur recevra une email avec un invitation pour changer le mot de passe`,
      EMAIL_ADDRESS_INVALID: `Format d'email invalide`,
      FEATURES: `Fonctionnalité`,
      FEATURES_ALWAYS_WELCOME_VID: `Vidéo de bienvenue toujours en ligne`,
      FEATURES_DESCR: `Définir les fonctionnalités auxquelles ce type de compte a accès`,
      FEATURES_IMMERSIVE: `Vidéo immersive`,
      FEMALE: `Femme`,
      FIELD_CANNOT_BE_EMPTY: `Le champ ne peut pas être vide`,
      FILE_IS_NOT_AN_IMAGE: `File is not an image`,
      FILE_IS_TOO_LARGE: `File can be at most {{ maxFileSize }}mb`,
      HEALTH_RECORD: `Dossier de santé`,
      HOSPITAL: `Établissement de Santé`,
      HOSPITALS: `Établissements de Santé`,
      IMAGE_FILE_NAME: `Nom du fichier image`,
      INDEPENDENTPRACTITIONERS: `Libéraux`,
      INDEPENDENT_PRACTITIONERS: `Libéraux`,
      INDICATION: `Indication`,
      INDICATIONS: `Les indications`,
      INDICATIONS_ANXIETY: `Surmonter le stress`,
      INDICATIONS_AWAKENING: `Bien vous réveiller`,
      INDICATIONS_DESCR: `Définir au moins un type d'indication que ce compte peut activer`,
      INDICATIONS_PAIN: `Mieux vivre avec sa douleur`,
      INDICATIONS_SLEEP: `Mieux dormir`,
      INDIVIDUAL_PATIENT: `Patient Individuel`,
      INVITATIONS: `Invitations`,
      INVITATIONS_DOCTOR: `Médecin`,
      INVITATIONS_DESCR: `Définir les types d'invitations que ce compte peut envoyer`,
      INVITATIONS_PATIENT: `Patient`,
      INVITATIONS_REPRESENTATIVE: `Représentant`,
      INVITED: `Invité`,
      JOINED: `Depuis`,
      LICENSE: `Licence`,
      LICENSES: `Licences`,
      LICENSES_DESCR: `Caractéristiques des abonnements individuels rattachés au compte PRO`,
      LOG_OUT_OF_ALL_DEVICES: `Déconnectez-vous de tous les appareils`,
      MALE: `Homme`,
      MASK_PRIVATE_INFO: `Masquer les informations privées`,
      TRIAL_WITHOUT_CREDIT_CARD: `Trial without credit card`,
      MAX_LENGTH: `Max length: {{ maxLength }} characters`,
      MAX_LICENSES_IS: `Max licenses is {{ max }}`,
      MAX_NUMBER_OF_DAYS_IS_365: `Max number of days is 365`,
      MAX_PRICE_IS: `Max price is {{ max }}`,
      NAME: `Nom`,
      NAME_PRO_ACCOUNT: `Nom et prénom du compte pro`,
      NAME_OR_EMAIL: `Nom ou email`,
      NEW_ACCOUNT_TYPE_NAME: `Nouveau nom de type de compte`,
      NUM_OF_DAYS: `Nb de jours offerts`,
      NUM_OF_LICENSES: `Nb de licences PRO`,
      OTHER: `Autre`,
      PAIN: `Mieux vivre avec sa douleur`,
      PATIENT: `Patient`,
      PATIENT_RECORD: `Dossier patient`,
      PATIENTS: `Patients`,
      PERMISSIONS: `Autorisations`,
      PLEASE_REGISTER_BEFORE_SOCIAL_SIGN_IN:
        'Veuillez vous enregistrer votre compte avant de tenter de vous connecter avec un compte Google',
      PLEASE_SET_A_PRIVATE_PASSWORD_BEFORE_SOCIAL_SIGN_IN:
        'Veuillez définir un mot de passe privé pour votre compte avant de tenter de vous connecter avec un compte Google',
      PREVIEW: `Preview`,
      PRICE: `Prix de l'abonnement ({{ currencySymbol }})`,
      REFERRAL: `Référence`,
      REFERRED_BY: `Référé par`,
      REFERRED_BY_REFERRER: `Référé par {{ referrerName }} le`,
      REFERRALS: `Références`,
      REPORTING: `Rapports`,
      REPORTING_DESCR: `Sélectionner les types de rapports que ce compte peut recevoir`,
      REPORTING_MUSIC_STATS: `Statistiques musicales`,
      REPORTING_PAIN: `Niveaux de douleur`,
      REPORTING_PATIENT_RECORDS: `Dossiers des patients`,
      REPRESENTATIVES: `Visiteurs Medicaux`,
      SAVE_ACCOUNT_EDITS: `Enregistrer les modifications du compte`,
      SELECT_PATIENT_RECORD_QUESTIONS: `Sélectionneer laquelle des questions ci-dessous sera présentée au patient.`,
      SENOFI: `Sanofi`,
      SESSION: `Session`,
      SESSIONS: `Sessions`,
      SINCE: `Depuis`,
      SLEEP: `Mieux dormir`,
      SONG: `Séance`,
      LICENSES: `Licences`,
      TYPE: `Type`,
      USERS: `utilisateurs`,
    },
    auth: {
      ACCOUNT_CREATED_SUCCESSFULLY: `Compte enregistré avec succès`,
      ALREADY_RECEIVED_CODE: `Vous avez déjà reçu un code?`,
      AN_ERROR_OCCURRED: `Une erreur s'est produite: impossible de terminer la demande`,
      // AUTHENTICATION_ERROR: `Invalid email or password`,
      CODE_CANNOT_BE_EMPTY: `Le champ de code ne peut pas être vide`,
      COMPLETE_REGISTRATION: `Complétez votre inscription`,
      COMPLETE_REGISTRATION_DESCR: `Définissez un mot de passe pour terminer l'enregistrement de votre compte pro`,
      CONFIRM_PASSWORD: `Confirmez le mot de passe`,
      CONFIRMATION_CODE: `Code de confirmation`,
      // CONNECTION_ERROR: `Cannot connect`,
      EMAIL_ADDRESS: `Email`,
      ENTER_CONFIRMATION_CODE_AND_NEW_PASSWORD: `Entrez le code de confirmation qui a été envoyé à votre e-mail avec votre nouveau mot de passe`,
      // EMAIL_IS_REQUIRED: `Email is required`,
      ENTER_EMAIL_TO_RESET_PASSWORD: `Entrez votre e-mail pour réinitialiser votre mot de passe`,
      EMAIL_FIELD_CANT_BE_EMPTY: `Le champ de l'e-mail ne peut pas être vide`,
      ERROR: `Une erreur s'est produite`,
      ERROR_403_FORBIDDEN: `Forbidden`,
      ERROR_403_FORBIDDEN_DESC: `You are not allowed to access this resource.`,
      ERROR_404_PAGE_NOT_FOUND: `Page not found`,
      ERROR_404_PAGE_NOT_FOUND_DESC: `The page you are looking for might have been removed.`,
      ERROR_500_INTERNAL_SERVER_ERROR: `Internal server error`,
      ERROR_500_INTERNAL_SERVER_ERROR_DESCR: `The server encountered something unexpected that didn't allow it to
      complete the request.`,
      FORGOT_PASSWORD: `Mot de passe oublié`,
      GO_BACK: `Retourner`,
      INCORRECT_USERNAME_OR_PASSWORD: `Email ou mot de passe incorrect`,
      INVITE_OR_USER_EMAIL_ALREADY_EXISTS: `Un compte avec cette adresse e-mail existe déjà`,
      INVALID_EMAIL: `Format d'email invalide`,
      INVALID_INVITE: `Invitation invalide ou expirée`,
      INVALID_VERIFICATION_CODE_PROVIDED_PLEASE_TRY_AGAIN: `Code de vérification non valide fourni`,
      INVALID_DISTRIBUTION: `Lien de distribution non valide`,
      INVITE_NOT_FOUND: `Invalid or expired invite`,
      JOIN_TERMS_UNCHECKED: `Veuillez accepter les condition de ventes et notre politique de donnes pour completer votre inscription`,
      LINK_TO_RESET_PASSWORD_SENT: `Un lien pour réinitialiser votre mot de passe a été envoyé à votre adresse e-mail`,
      LOG_IN: `Entrez votre email pour créer un compte gratuit`,
      INVITE_TERMS_UNCHECKED: `Veuillez indiquer que vous avez reçu le consentement de votre patient`,
      MUST_BE_A_PRO_USER: `Must be a PRO user to log in`,
      NETWORK_ERROR: `Erreur réseau`,
      NEW_PASSWORD: `Nouveau mot de passe`,
      PASSWORD: `Mot de passe`,
      PASSWORD_ATTEMPTS_EXCEEDED: `Trop de tentatives de connexion`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_LOWERCASE_CHARACTERS: `Un mot de passe doit contenir un de chaque: une minuscule et une majuscule, un chiffre, un caractère spécial`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_NUMERIC_CHARACTERS: `Un mot de passe doit contenir un de chaque: une minuscule et une majuscule, un chiffre, un caractère spécial`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_SYMBOL_CHARACTERS: `Un mot de passe doit contenir un de chaque: une minuscule et une majuscule, un chiffre, un caractère spécial`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_MUST_HAVE_UPPERCASE_CHARACTERS: `Un mot de passe doit contenir un de chaque: une minuscule et une majuscule, un chiffre, un caractère spécial`,
      PASSWORD_DOES_NOT_CONFORM_TO_POLICY_PASSWORD_NOT_LONG_ENOUGH: `Un mot de passe doit comporter au moins 8 caractères`,
      PASSWORD_FIELD_CANT_BE_EMPTY: `Le champ du mot de passe ne peut pas être vide`,
      // PASSWORD_IS_TOO_SHORT: `Password is too short`,
      // PASSWORD_IS_TOO_LONG: `Password is too long`,
      // PASSWORD_IS_REQUIRED: `Password is required`,
      PASSWORD_MUST_CONTAIN: `Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et une casse spéciale`,
      PASSWORD_MUST_BE: `Un mot de passe doit contenir chacun des éléments suivants: une minuscule et une majuscule, un chiffre, un caractère spécial`,
      PASSWORDS_DONT_MATCH: `Les mots de passe ne correspondent pas`,
      PLEASE_COMPLETE_E_LEARNING_MODULE: `Veuillez compléter le module e-learning avant de vous inscrire`,
      // PLEASE_FILL_ALL_FIELDS: `Please fill in all fields`,
      RETURN_TO_WEBSITE: `Return to website`,
      REGISTER: `S'inscrire`,
      REMEMBER_ME: `Souviens-toi de moi`,
      REPLACE_ASSIGNED_TEMPORARY_PASSWORD: `Vous devez remplacer votre mot de passe temporaire avant de pouvoir vous connecter`,
      RESET_PASSWORD: `Réinitialiser le mot de passe`,
      RPPS_INVALID: `RPPS non valide`,
      SAVE_PASSWORD: `Enregistrer le mot de passe`,
      SET_YOUR_PASSWORD: `Définissez votre nouveau mot de passe`,
      SIGN_IN: `Se connecter`,
      // SIGN_UP: `Sign up`,
      SIGN_IN_TO_YOUR_ACCOUNT: `Connectez-vous à votre compte pour continuer`,
      SIGN_IN_WITH_GOOGLE: `Connectez-vous avec Google`,
      SUCCESSFULLY_SAVED_PASSWORD: `Mot de passe enregistré avec succès`,
      TEMPORARY_PASSWORD_HAS_EXPIRED_AND_MUST_BE_RESET_BY_AN_ADMINISTRATOR: `Le mot de passe temporaire a expiré et doit être réinitialisé par un administrateur`,
      TOGGLE_PASSWORD_VISIBILITY: `Basculer la visibilité du mot de passe`,
      USER_DOES_NOT_EXIST: `User does not exist`,
      WELCOME_TO_MUSIC_CARE: `Bienvenue dans l'outil d'administration de Music Care`,
    },
    dashboardPage: {
      DASHBOARD: `Dashboard`,
      TOP_LOCATIONS_USING_MUSIC_CARE: `Top Localisation`,
      TOP_MUSIC: `Top Séance`,
      TOP_PRESCRIBERS: `Top Prescribers`,
      WELCOME_BACK: `Bienvenue`,
    },
    distributionsPage: {
      DISTRIBUTIONS: `Distributions`,
      REGISTRATION_LINK: `Registration link`,
    },
    eLearningPage: {
      BEGIN_E_LEARNING: `Commencer`,
      INVALID_INVITE: `Invitation invalide ou expirée`,
      UNABLE_TO_BEGIN_TRAINING: `Erreur: Impossible de commencer le module de formation`,
      WELCOME_SUBTEXT: ``,
      WELCOME_TEXT: ``,
      WELCOME_TO_MUSIC_CARE: ``,
    },
    footer: {
      HELP_CENTER: `Aide`,
      PRIVACY: `Politique de Protection des Données`,
      SIGN_UP: `S'inscrire à Music Care`,
      SUPPORT: `Contact`,
      TERMS_OF_SERVICE: `Conditions Générales`,
    },
    general: {
      ARCHIVE: `Archiver`,
      BY: `par`,
      CANCEL: `Annuler`,
      DAY: `Jour`,
      DAYS: `Jours`,
      DELETE: `Effacer`,
      DELETING: `Effacer`,
      EDIT: `Éditer`,
      EDITING: `Édition`,
      SAVE: `Sauvegarder`,
      SELECT_ALL: `Tout sélectionner`,
      SELECTED: `Choisi`,
      THERE_ARE: `Il y a`,
      THERE_IS: `Il y a`,
      UNARCHIVE: `Desarchiver`,
    },
    genresPage: {
      ACCEPTS_IMAGE_FILES: `Accepte les types de fichiers image 'png' et 'jpeg'`,
      ACTIONS: `Actions`,
      ADD_NEW_GENRE: `Ajouter une nouvelle catégorie`,
      ADD_GENRE: `Ajouter une catégorie`,
      DELETE_GENRE_BODY: `This action will irreversibly delete this genre. Are you sure you want to proceed?`,
      DELETE_GENRE_BODY_MUST_CHANGE: `{{ numSongsSentence }} song{{ plural }} that currently belong{{ notPlural }} to this genre. Please choose a different genre that they can be assigned to before this one is deleted.`,
      DELETE_GENRE_BODY_CANT_DELETE: `Since there are songs that are assigned to this genre and there are no other genres available, this genre cannot be deleted. In order to delete this genre, please create another genre that these songs could be reassigned to first.`,
      DELETE_GENRE_ASSIGN_TO: `Genre to assign songs to`,
      DELETE_GENRE_WARNING: `AVERTISSEMENT!`,
      DELETE_REASSIGN: `Reassign & delete genre`,
      FILE_IS_NOT_AN_IMAGE: `File is not an image`,
      GENRE_ALREADY_EXISTS: `Une catégorie avec ce nom existe déjà`,
      GENRE_EDIT_PROPERTIES: `Modifiez les propriétés d'une catégorie.`,
      GENRE_IMAGE: `L'image d'une catégorie`,
      GENRE_MAX_LENGTH: `Max length: {{ maxLength }} characters`,
      GENRE_NAME_CANT_BE_EMPTY: `Le champ du nom de la catégorie ne peut pas être vide`,
      GENRE_NAME_EN: `Nom de la catégorie EN`,
      GENRE_NAME_FR: `Nom de la catégorie FR`,
      GENRE_PROPERTIES: `Propriétés de la catégorie`,
      GENRES: `Catégories musicales`,
      GENRES_MANAGEMENT: `Gestion des catégories`,
      GENRES_TABLE: `Tableau des catégories`,
      IMAGE_FILE_NAME: `Nom du fichier image`,
      INVALID_POSITION_VALUE: `Valeur de position non valide`,
      MAX_FILE_SIZE_IS: `Max file size is {{ maxFileSize }}`,
      NAME_EN: `Nom EN`,
      NAME_FR: `Nom FR`,
      NUMBER_OF_SONGS: `Nombre de séances`,
      POSITION: `Position`,
      POSITION_AMONG_GENRES: `Position parmi les catégories`,
      PREVIEW: `Aperçu`,
      SELECT_IMAGE_PREVIEW: `Sélectionnez une image pour voir un aperçu`,
      UPLOADED_IMAGE: `Uploaded image`,
    },
    header: {
      INVITE_PATIENT: `Recommander à un patient`,
      OPEN_DRAWER: `Tiroir ouvert`,
      PROFILE: `Profil`,
      SEARCH: `Chercher`,
      SIGN_OUT: `Déconnexion`,
    },
    invitationsPage: {
      ACCEPTED: `Accepté`,
      DOCTOR: `Médecin`,
      INVITATIONS: `Invitation`,
      INVITE_DOCTORS: `Invitez des médecins à rejoindre votre réseau Music Care.`,
      PATIENT: `Patient`,
      PRO: `Pro`,
      RESEND: `Renvoyer`,
      REVOKE: `Révoquer`,
      SENT: `Envoyé`,
      YOU_WILL_SEE_THEIR_ACTIVITY: `Vous verrez leur activité et celle de leurs patients dans vos rapports.`,
      YOUR_PRESCRIBER_CODE: `Votre code prescripteur est`,
    },
    invitePage: {
      ACCESS_APPLICATION: `Application access`,
      ACCESS_DEMONSTRATION: `Demonstration access`,
      CHOOSE_TYPE_OF_INVITATION: `Choose an invitation type`,
      DASHBOARD_AND_REPORTS: `Dashboard and reports`,
      DOCTOR: `Doctor`,
      EVERY_USER_WILL_RECEIVE_INVITATION: `Every new user will receive an invitation email with an E-learning link`,
      INVITATION_GP: `GP Invitation`,
      INVITATION_PATIENT: `Patient Invitation`,
      INVITE: `Invite`,
      INVITE_USER: `Invite user`,
      MED_REP: `Med Rep`,
      PATIENT: `Patient`,
      PATIENT_HEALTH_DOSSIER: `Patient health dossier`,
    },
    inviteFormPage: {
      ACCOUNT_TYPE: `Type de Compte`,
      AN_ERROR_OCCURRED: `An error eccured when attempting to send the emails.`,
      CLOSE: `Fermer`,
      COMPLETE_SIGN_UP: `Compléter l'inscription`,
      CREATE_A_DISTRIBUTION: `Créer un canal de distribution`,
      CREATE_DISTRIBUTION: `Créer`,
      DISTRIBUTION_CHANNEL_EXISTS: `Une distribution avec ce nom existe déjà`,
      DISTRIBUTION_FORM: `Distribution form`,
      DISTRIBUTION_LINK: `Lien Distribution`,
      DISTRIBUTION_LINK_DESCR: `Eligendi laborum quia expedita voluptatibus ut aspernatur. Et iure enim qui asperiores vel non et vel. Aut sed veniam non et porro vero minima hic.`,
      DOCTOR: `Médecin`,
      EMAIL_ADDRESS: `Email`,
      EMAIL_ALREADY_ENTERED: `This email has already been entered above`,
      EMAIL_ADDRESS_EXISTS: `Un compte avec cette adresse e-mail existe déjà`,
      EMAIL_ADDRESS_INVALID: `Invalid email format`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      GO_BACK: `Go back`,
      INVALID_REFERRAL_CODE: `Invalid referral code`,
      INVITE: `Invitation`,
      INVITE_FORM: `Invite form`,
      INVITE_DOCTOR: `Inviter un médecin`,
      INVITE_PATIENT: `Recommander à un patient`,
      LEGAL_DISCLAIMER: `Je certifie avoir informé mon patient et recueilli son consentement exprès, conformément à la réglementation applicable en matière de données personnelles et `,
      LEGAL_DISCLAIMER_2: ` de MUSIC CARE pour communiquer l’adresse email ci-dessus à la société Music Care afin que cette dernière lui propose ses services.`,
      LEGAL_DISCLAIMER_LINK: `aux CGVU PRO`,
      NAME_CAN_BE_AT_MOST: `Un nom peut contenir au maximum 40 caractères`,
      NAME_IS_REQUIRED: `Un nom est requis`,
      NAME_IS_TOO_SHORT: `Un nom doit comporter au moins 3 caractères`,
      NAME_MAY_NOT_CONTAIN_ANY_SPECIAL_CHARACTERS: `Un nom ne peut contenir aucun caractère spécial`,
      NEW_DISTRIBUTION_NAME: `Nom de la nouvelle distribution`,
      PATIENT: `Patient`,
      PRESCRIBER_CODE: `Code prescripteur (*Optionnel)`,
      PRESCRIBER_CODE_TOO_SHORT: `A prescriber code is 8 characters long`,
      SEND_INVITATIONS: `Envoyer l'invitation`,
    },
    links: {
      ABOUT_MUSIC_CARE: `https://www.music.care/fr/Compagnie.html`,
      APP_APPLE_STORE: `https://apps.apple.com/ca/app/music-care/id849636644`,
      APP_GOOGLE_STORE: `https://play.google.com/store/apps/details?id=com.o12s.musiccare`,
      CONTACT: `https://app.music.care/contact`,
      FAQ: `https://www.music.care/fr/Aide.html`,
      HOMEPAGE: `https://www.music.care/`,
      PRIVACY: `https://www.music.care/fr/politiques.html`,
      TERMS_DOCTOR: `https://www.music.care/fr/conditions-prescripteurs.html`,
      TERMS_PRO: `https://www.music.care/fr/conditions-professionnels.html`,
      TERMS_PRO_2: `https://www.music.care/politiques.html`,
      TERMS_PRO_3: `https://docs.google.com/forms/d/e/1FAIpQLScAELoSaTvGcPgAj5_UbsMB49kZ_BmaJnEaQo8qgZ1kzp-9kQ/viewform`,
      LEGAL_DISCLAIMER: `https://www.music.care/conditions-pro.html`,
    },
    musicStatisticsPage: {
      ALL: `Tous`,
      ANXIETY: `Surmonter le stress`,
      AWAKENING: `Bien vous réveiller`,
      END: `Fin`,
      EXPORT: `Export`,
      FILTER_CRITERIA: `Critères de filtre de sélection`,
      INDICATION: `Indication`,
      LISTENING_STATISTICS: `Statistiques d'Écoute`,
      MUSIC_RIGHTS: `Music Rights`,
      MUSIC_STATISTICS: `Statistiques par Séance`,
      SEARCH: `Search`,
      SELECT_START_AND_END: `Select a start and an end date`,
      SESSION_STATISTICS: `Statistiques des Comptes PRO`,
      SLEEP: `Mieux dormir`,
      SONG_STATISTICS: `Statistiques de séance`,
      START: `Début`,
      STATS_EXPORT: `statistiques_musicales`,
      PAIN: `Mieux vivre avec sa douleur`,
    },
    profilePage: {
      ADDRESS: `Addresse`,
      ADDRESS_CONTINUED: `Address continued`,
      CHANGE_IMAGE: `Changer`,
      CHANGE_EMAIL: `Changer l'adresse email`,
      CHANGE_PASSWORD: `Changer le mot de passe`,
      CONFIRM_EMAIL: `Confirmer la nouvelle adresse email`,
      CONFIRM_EMAIL_CHANGE: `Confirmer la changement`,
      CONFIRM_PASSWORD: `Confirmer le nouvel mot de passe`,
      CONFIRM_PASSWORD_CHANGE: `Confirmer la changement du mot de passe`,
      COUNTRY: `Pays`,
      CITY: `Ville`,
      CURRENT_PASSWORD: `Mot de passe actuel`,
      DOCTOR_ID: `Numéro RPPS`,
      EMAIL_ADDRESS: `Email actuel`,
      EMAILS_MUST_MATCH: `Emails must match`,
      EXPIRED: `Expiré`,
      FIELD_CAN_BE_AT_MOST: `{{ fieldName }} peut comporter au maximum {{max}} caractères`,
      FIELD_CANNOT_BE_EMPTY: `Field cannot be empty`,
      FIELD_IS_REQUIRED: `Champ requis`,
      IMAGE_RECOMMENDATION: `An image at least 128px by 128px in .jpg format is recommended`,
      INVALID_PASSWORD: `Mot de passe incorrect`,
      MUST_BE_A_VALID_EMAIL: `Doit être un email validationSchema`,
      NAME: `Nom et prénom`,
      NEW_EMAIL: `Nouvelle adresse email`,
      NEW_PASSWORD: `Nouveau mot de passe`,
      NEW_PASSWORD_CONFIRM: `Confirmer le nouveau mot de passe`,
      OF: `de`,
      OF_MASC: `du`,
      PASSWORD_CAN_BE_AT_MOST: `Le mot de passe peut comporter au maximum {{max}} caractères`,
      POSTAL_CODE: `Code postal`,
      PLEASE_CONTACT_ADMIN: `Veuillez contacter votre administrateur pour plus d'informations.`,
      PASSWORDS_DO_NOT_MATCH: `Les mots de passe ne correspondent pas`,
      PASSWORD_IS_TOO_SHORT: `Le mot de passe est trop court`,
      PASSWORD_MUST_CONTAIN: `Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial.`,
      PERSONAL_USAGE_LEFT: `Usage personnel restant`,
      PROFILE: `Profil`,
      SAVE: `Sauvegarder`,
      STATE_ETC: `State, Provice, etc.`,
      THE_APOSTROPHE: `l'`,
      THE_MASC: `le`,
      THE_FEMIN: 'la',
      YOU_WERE_INVITED_BY: `Vous avez été invité par`,
      YOUR_ACCESS: `Votre Accés`,
      YOUR_INFORMATION: `Vos Informations`,
      USER_AVATAR: `User avatar`,
    },
    registrationPage: {
      ACCOUNT_CREATED_SUCCESSFULLY: `Compte enregistré avec succès`,
      CONGRATULATIONS: `Accédez dès maintenant à l’application Music Care pour la recommander à vos patients`,
      CONGRATULATIONS_SUBTITLE: `Veuillez creer votre compte gratuit pour recommander Music Care à vos patients par un accès démonstrateur et d'outil de gestion de vos patients.`,
      DOCTOR_ID: `Numéro RPPS`,
      DOCTOR_ID_CAN_BE_AT_MOST: `Le numéro RPPS peut comporter au maximum {{max}} caractères`,
      DOCTOR_ID_IS_REQUIRED: `Le numéro RPPS est requis`,
      DOCTOR_REGISTRATION: `Inscription du médecin`,
      EMAIL: `Email`,
      LEGAL_DOCTOR_A: `J’ai lu et accepte expressément les `,
      LEGAL_DOCTOR_B: ` de MUSIC CARE.`,
      LEGAL_DOCTOR_DESCR_A: `Vos données personnelles ici collectées sont utilisées conformément à la `,
      LEGAL_DOCTOR_DESCR_B: ` (Article B§3) de MUSIC CARE aux fins de répondre à ses obligations précontractuelles et/ou contractuelles à votre égard dans le cadre la fourniture des services de MUSIC CARE liés à votre Compte. Pour toute question relative à vos données personnelles, vous pouvez contacter son délégué à la protection des données (ci-après « DPO ») joignable à l’adresse postale MUSIC CARE – DPO 7 rue Popincourt – 75011 Paris (France) ou par email : dpo@music.care`,
      LEGAL_DOCTOR_DESCR_LINK: `Politique de Protection des Données`,
      LEGAL_DOCTOR_LINK: `Conditions Générales d’Utilisation des Services`,
      LEGAL_DOCTOR_C_1: `J’accepte que mes Données de Santé soient utilisées par MUSIC CARE suivant sa Politique de `,
      LEGAL_DOCTOR_C_2: ` de façon totalement anonymisée pour des recherches scientifiques pour le développement des services thérapeutiques de MUSIC CARE. Je peux m’opposer à cet usage à tout moment en cliquant `,
      LEGAL_DOCTOR_C_3: ` ou sur demande écrite aux coordonnées précitées.`,
      LEGAL_DOCTOR_LINK_2: `Protection des Données`,
      LEGAL_DOCTOR_LINK_3: `ICI`,
      NAME: `Nom complet`,
      NAME_CAN_BE_AT_MOST: `Le nom peut comporter au maximum {{max}} caractères`,
      NAME_IS_REQUIRED: `Le nom est requis`,
      PASSWORD: `Mot de passe`,
      PASSWORD_CONFIRM: `Confirmez le mot de passe`,
      PASSWORD_CAN_BE_AT_MOST: `Le mot de passe peut comporter au maximum {{max}} caractères`,
      PASSWORDS_DO_NOT_MATCH: `Les mots de passe ne correspondent pas`,
      PASSWORD_IS_REQUIRED: `Le mot de passe est requis`,
      PASSWORD_IS_TOO_SHORT: `Le mot de passe est trop court`,
      PASSWORD_MUST_CONTAIN: `Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial.`,
      REGISTER: `Terminer l’inscription`,
      RPPS_INVALID: `RPPS non valide`,
      TOGGLE_PASSWORD_VISIBILITY: `Basculer la visibilité du mot de passe`,
    },
    routes: {
      ACCESS_APP: `Accès App/Demo`,
      ACCOUNTS: `Comptes`,
      ALL: `En attente`,
      CONTACT_US: `Contactez nous`,
      CREATE_ACCOUNT_DOCTOR: `Médecin`,
      CREATE_ACCOUNT_INDIVIDUAL: `Patient`,
      CREATE_ACCOUNT_PRO: `Professionnel`,
      CREATE_AN_ACCOUNT: `Créer Compte`,
      CREATE_DISTRIBUTION: `Distribution`,
      DISTRIBUTION: `Distribution`,
      DISTRIBUTIONS: `Distributions`,
      DASHBOARD: `Dashboard`,
      DOCTORS: `Médecins`,
      EDIT_ACCOUNT: `Modifier un Compte`,
      FAQ: `FAQ`,
      GENRES: `Catégorie`,
      HOSPITALS: `Établissements de Santé`,
      INDEPENDENT_PRACTITIONERS: `Libéraux`,
      INDIVIDUAL: `Individuels`,
      INVITATIONS: `Invitations`,
      LISTENING_STATISTICS: `Statistiques d'Écoute`,
      MUSIC: `Musique`,
      MUSIC_CARE_ADMIN: `Music Care Admin`,
      MY_PRO_ACCOUNT: `Mon Compte Pro`,
      PATIENTS: `Patients`,
      PROFESSIONAL: `Professionnels`,
      PROFILE: `Profil`,
      REGISTRATION_DOCTOR: `Inscription Médecin`,
      REGISTRATION_PRO: `Inscription Pro`,
      REPRESENTATIVES: `Visiteurs Médicaux`,
      SIGN_UP: `S'inscrire`,
      SONGS: `Séances`,
      SUBSCRIPTIONS: `Abonnements`,
      TRAINING: `Formation`,
    },
    signUpPage: {
      ACCOUNT_SUCCESSFULLY_CREATED: `Compte créé avec succès. Merci de consulter vos emails.`,
      AN_ERROR_OCCURRED: `Erreur : impossible de terminer la demande`,
      CONFIRM_PASSWORD: `Confirmez le mot de passe`,
      CONTINUE_TO_SIGNUP: `Continuez à vous inscrire`,
      DISPLAY_TRAINING_FORM: `En savoir plus`,
      EMAIL_ADDRESS: `Email`,
      FIELD_CANNOT_BE_EMPTY: `Le champ ne peut pas être vide`,
      FIELD_CAN_BE_AT_MOST: `Le champ peut être au plus {{ maxLen }} caractères`,
      NAME: `Nom et prénom`,
      MUST_BE_A_VALID_EMAIL: `Doit être un email valide`,
      OR: `OU`,
      PLEASE_CHECK_YOUR_EMAIL: `Merci de consulter vos emails`,
      REGISTRATION: `Inscription`,
      SIGN_UP: `S'inscrire`,
      SUBMIT_EMAIL: `En savoir plus`,
      TOGGLE_PASSWORD_VISIBILITY: `Basculer la visibilité du mot de passe`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION: `Offrez à vos patients une nouvelle prise en charge de leurs douleurs chroniques.`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION_DESCR: `La web-app Music Care a fait ses preuves en milieu hospitalier et contribue au soulagement de la douleur chronique en complément d’un traitement médicamenteux. Music Care est maintenant disponible pour vos patients. Après ce court tutoriel, vous saurez pourquoi et comment ajouter une dose de musique à vos prescriptions. Renseignez votre adresse email pour commencer :`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION_FOOTER: `Vos données personnelles ici collectées sont utilisées conformément à la Politique de Protection des Données (Article B§3) de MUSIC CARE aux fins de satisfaire ses obligations précontractuelles et/ou contractuelles à votre égard dans le cadre la fourniture des services de MUSIC CARE liés à votre Compte. Pour toute question relative à vos données personnelles, vous pouvez contacter son délégué à la protection des données (ci-après « DPO ») joignable à l’adresse postale MUSIC CARE – DPO 7 rue Popincourt – 75011 Paris (France) ou par email : dpo@music.care`,
      YOU_WERE_REFERRED_BY: `Vous avez été référé par {{ honorific }}{{ referrerName }}`,
      WELCOME_TO_MUSIC_CARE_REGISTRATION_FOOTER_STUDY: `S Guétin & al[2012]. The Effects of Music Intervention in the Management of Chronic Pain. Clin J Pain`,
    },
    snackbar: {
      ACCOUNT_EDIT_SNACKBAR_SUCCESS: `Compte modifié avec succès`,
      ACCOUNT_EDIT_SNACKBAR_FAIL: `Impossible de modifier le compte`,
      AVATAR_FILE_IS_NOT_AN_IMAGE_FAIL: `Le fichier n'est pas une image`,
      AVATAR_FILE_IS_TOO_LARGE_FAIL: `Le fichier est trop volumineux`,
      CREATE_ACCOUNT_SNACKBAR_FAIL: `Échec de l'invitation de l'utilisateur professionnel`,
      CREATE_ACCOUNT_SNACKBAR_SUCCESS: `Utilisateur pro invité avec succès`,
      DISTRIBUTION_CREATION_FAIL: `Erreur : Échec de la création de la distribution`,
      DISTRIBUTION_CREATION_SUCCESS: `Distribution créée avec succès`,
      EDIT_ACCOUNT_SNACKBAR_FAIL: `Échec de la modification de l'utilisateur professionnel`,
      EDIT_ACCOUNT_SNACKBAR_SUCCESS: `Utilisateur pro modifié avec succès`,
      GENRE_ADD_SNACKBAR_SUCCESS: `Catégorie ajouté avec succès`,
      GENRE_ADD_SNACKBAR_FAIL: `Échec de l'ajout de la catégorie`,
      GENRE_DELETE_SNACKBAR_FAIL: 'Impossible de supprimer le genre',
      GENRE_DELETE_SNACKBAR_SUCCESS: 'Genre supprimé avec succès',
      GENRE_EDIT_SNACKBAR_FAIL: `Échec de la modification de la catégorie`,
      GENRE_EDIT_SNACKBAR_SUCCESS: `Catégorie modifié avec succès`,
      IMAGE_UPLOAD_FAIL: `Impossible de télécharger l'image`,
      IMAGE_UPLOAD_FILE_IS_NOT_AN_IMAGE_FAIL: `le fichier sélectionné n'est pas une image valide`,
      IMAGE_UPLOAD_FILE_IS_TOO_LARGE_FAIL: `Error: Selected file is too large`,
      IMAGE_UPLOAD_SUCCESS: `Image d'avatar mise à jour avec succès`,
      INVITES_CODE_FAIL: `Code de parrainage fourni non valide`,
      INVITES_FETCH_FAIL: `impossible d'obtenir des invitations`,
      INVITES_SENT_FAIL: `Impossible de créer des invitations pour certains des emails demandés`,
      INVITES_SENT_SUCCESS: `Invitations envoyées avec succès aux e-mails fournis`,
      INVITE_RESEND_FAIL: `Impossible de renvoyer l'invitation`,
      INVITE_RESEND_SUCCESS: `Invitation renvoyée avec succès`,
      INVITE_REVOKE_FAIL: `impossible de révoquer l'invitation`,
      INVITE_REVOKE_SUCCESS: `Invitation révoquée avec succès`,
      LOG_OUT_ALL_DEVICES_FAIL: `Impossible de se déconnecter des appareils utilisant ce compte`,
      LOG_OUT_ALL_DEVICES_SUCCESS: `Déconnecté avec succès sur tous les appareils utilisant ce compte`,
      MUSIC_STATISTICS_FAIL: `Impossible de télécharger les statistiques musicales pour le moment`,
      PASSWORD_LIMIT_EXCEEDED: `Limite de tentative de modification de mot de passe dépassée, veuillez réessayer après un certain temps`,
      PASSWORD_UPDATE_FAIL: `Mot de passe mis à jour avec succès`,
      PASSWORD_UPDATE_SUCCESS: `Mot de passe mis à jour avec succès`,
      PROFILE_UPDATE_FAIL: `Impossible de mettre à jour le profil`,
      PROFILE_UPDATE_SUCCESS: `Profil mis à jour avec succès`,
      REGISTER_DISTRIBUTION_SNACKBAR_FAIL: `Impossible de s'inscrire`,
      REGISTER_DISTRIBUTION_SNACKBAR_SUCCESS: `Votre confirmation d'inscription a été envoyé`,
      REGISTER_DOCTOR_SNACKBAR_SUCCESS: `Compte médecin enregistré avec succès`,
      REGISTER_PRO_SNACKBAR_SUCCESS: `Compte pro enregistré avec succès`,
      SONG_ADD_SNACKBAR_FAIL: `Échec de l'ajout de la séance`,
      SONG_ADD_SNACKBAR_SUCCESS: `Séance ajoutée avec succès`,
      SONG_ARCHIVE_FAIL: `Échec de l'archivage de la chanson`,
      SONG_ARCHIVE_SUCCESS: `Song successfully archived`,
      SONG_DELETE_SNACKBAR_FAIL: `Échec de la suppression de la séance {{plural}}`,
      SONG_DELETE_SNACKBAR_SUCCESS: `La séance {{plural}} a bien été supprimée`,
      SONG_EDIT_SNACKBAR_SUCCESS: `Séance modifiée avec succès {{plural}}`,
      SONG_EDIT_SNACKBAR_FAIL: `Échec de la modification de la séance {{plural}}`,
      SONG_UNARCHIVE_FAIL: `Erreur : Impossible de désarchiver la chanson`,
      SONGS_EDIT_SNACKBAR_FAIL: `Unable to update {{ numErrors }} song{{ plural }}`,
      SONGS_EDIT_SNACKBAR_SUCCESS: `Successfully updated {{ numSongs }} songs`,
      SONG_UNARCHIVE_SUCCESS: `Chanson désarchivée avec succès`,
      UNABLE_TO_SEND_INVITES: `Impossible d'envoyer des invitations`,
    },
    songsPage: {
      // Currently app saves downloaded songs as 'mp3' files by default, until change it there to allow m4a handling, only mp3 is allowed
      // ACCEPTS_AUDIO_FILES: `Accepte les fichiers audio 'mp3' et 'm4a'`,
      ACCEPTS_AUDIO_FILES: `Accepte les fichiers audio 'mp3'`,
      ACCEPTS_IMAGE_FILES: `Accepte les types de fichiers image 'png' et 'jpeg'`,
      ACTIONS: `Actions`,
      ACTIVE: `Actif`,
      ADD_AN_INSTRUMENT_EN: ` `,
      ADD_AN_INSTRUMENT_FR: ` `,
      ADD_NEW_SONG: `Ajouter une nouvelle séance`,
      ADD_SONG: `Ajouter une séance`,
      ALL: `Tout`,
      ARTIST: `Artiste`,
      ARCHIVED: `Archivé`,
      AUDIO_FILE: `Fichier audio`,
      AUDIO_FILE_A: `Fichier audio A Tempo 1-3`,
      AUDIO_FILE_B: `Fichier audio B Tempo 4-7`,
      AUDIO_FILE_C: `Fichier audio C Tempo 8-10`,
      DELETE_SONG_BODY: `La suppression d'un séance supprimera son fichier audio ainsi que toutes ses informations du répertoire de séances. Voulez-vous vraiment supprimer cette séance?`,
      DELETE_SONG_TITLE: `Supprimer une séance`,
      DELETE_SONG_WARNING: `AVERTISSEMENT!`,
      DESCRIPTION_EN: `Description EN`,
      DESCRIPTION_FR: `Description FR`,
      EDIT_SONGS: `Modifier le(s) séance(s)`,
      EDIT_PROPERTIES_OF: `Modifiez les propriétés de {{editTarget}}.`,
      EDITING: `Édition`,
      EDITING_SONGS: `Modification de {{numberEdited}} séances`,
      FIELD_CANNOT_BE_EMPTY: `Le champ ne peut pas être vide`,
      FILE_NAME: `Nom de fichier`,
      FILE_IS_NOT_AN_IMAGE: `Le fichier n'est pas une image`,
      FILE_IS_NOT_AUDIO: `Le fichier chargé n'est pas un fichier audio valide`,
      FILE_IS_TOO_LARGE: `Le fichier peut être au plus {{ maxFileSize }}mb`,
      FILE_LENGTH_NEEDS_TO_BE_AT_LEAST: `Le fichier audio doit être au moins {{ minDuration }} secondes`,
      FILL_PROPERTIES: `Renseignez les propriétés de la séance à ajouter`,
      GENRE: `Catégorie`,
      IMAGE: `Image`,
      INSTRUMENT_EN: `Instrument EN`,
      INSTRUMENT_EXISTS: `L'instrument existe déjà`,
      INSTRUMENT_FR: `Instrument FR`,
      INSTRUMENT_PAIRS: `Paires d'instruments`,
      INSTRUMENTS: `Instruments`,
      INSTRUMENTS_MODIFICATION_NOT_AVAILABLE: `La modification des instruments n'est pas disponible lors de l'édition de plusieurs séances.`,
      INVALID_POSITION_VALUE: `Valeur de position non valide`,
      IS_NEW: `Nouveau`,
      LENGTH_SET_AUTOMATICALLY: `La longueur est définie automatiquement`,
      LOOP_PROPERTIES: `Propriétés de la boucle`,
      MARK_AS_NEW_UNTIL: `Nouveau jusqu'à`,
      MAX_FILE_SIZE_IS: `Max file size is {{ maxFileSize }}`,
      MODIFICATION_LOOP_NOT_AVAILABLE: `La modification des propriétés de boucle n'est pas disponible lors de l'édition de plusieurs séances.`,
      MULTIPLE_SONGS: `Plusieurs séances à la fois`,
      MUST_BE_LESS_THAN_LENGTH: `Doit être inférieur à la longueur`,
      MUST_BE_LESS_THAN_TIME: `Doit être inférieur au temps {{plural}} {{- timeVal}}`,
      MUST_BE_MORE_THAN_LESS_THAN: `Doit être supérieur au temps {{timeVal1}} et inférieur au temps {{timeVal2}}`,
      MUST_BE_MORE_THAN_TIME: `Doit être plus que le temps {{plural}} {{- timeVal}}`,
      NEW_STATUS: `Nouveau statut`,
      NEW_STATUS_WILL_BE_INDICATED: `Le statut 'Nouveau' sera indiqué jusqu'à cette date (incluse)`,
      NO_PREVIEWING_IMAGE_WHEN_MULTIPLE: `Prévisualisation et remplacement d'une image non disponible lors de l'édition de plusieurs séances.`,
      NO_REPLACING_IMAGE_WHEN_MULTIPLE: `Prévisualisation et remplacement d'une image non disponible lors de l'édition de plusieurs séances.`,
      POSITION: `Position`,
      POSITION_MODIFICATION_NOT_AVAILABLE: `La modification de position n'est pas disponible lors de l'édition de plusieurs séances.`,
      POSITION_IN_GENRE1: `Position dans la catégorie`,
      POSITION_IN_GENRE2: `Position dans la catégorie`,
      POSITION_IN_GENRE3: `Position dans la catégorie`,
      PREVIEW: `Aperçu`,
      SINGLE_SONG: `Une seule séance`,
      SELECT_AUDIO_TO_ANALYZE: `Sélectionnez un fichier audio pour analyser la longueur`,
      SELECT_AUDIO_FIRST: `Sélectionnez d'abord un fichier audio`,
      SELECT_IMAGE_PREVIEW: `Sélectionnez une image pour voir un aperçu`,
      SONG_ID: `ID de la séance`,
      SONG_ALBUM_COVER: `Image de couverture de l'album de la séance`,
      SONG_EXISTS_WITH_THIS_ARTIST_AND_TITLE: `A song already exists with this artist and genre`,
      SONG_MAX_LENGTH: `Max length: {{ maxLength }} characters`,
      SONG_PROPERTIES: `Propriétés du morceau`,
      SONGS_WITH_MULTIPLE_ARTISTS_SELECTED: `Multiple artists selected`,
      SONGS_WITH_MULTIPLE_GENRES_SELECTED: `Multiple genres selected`,
      SONGS: `Séances`,
      SONGS_MANAGEMENT: `Gestion des séances`,
      SONGS_TABLE: `Tableau des séances`,
      STATUS: `Statut`,
      TIME_NUMBER: `Heure {{number}} (s)`,
      TITLE: `Séance`,
      TITLE_EN: `Séance EN`,
      TITLE_FR: `Séance FR`,
      TOTAL_LENGTH: `Longueur (s) totale (s)`,
      UPDATED: `Mis à jour`,
      USING_UPLOADED_FILE: `Utilisation du fichier actuellement téléchargé`,
    },
    stats: {
      ACTIVE_PRO_ACCOUNTS: `Comptes pro actifs`,
      AFTER: `Après`,
      AGE: `Âge`,
      ANXIETY: `Surmonter le stress`,
      ANXIETY_BEFORE: `Anxiété avant`,
      ANXIETY_AFTER: `Anxiété après`,
      AVG_ANXIETY: `Anxiété Moy.`,
      AVG_ANXIETY_AFTER: `Anxiété Moy. Après`,
      AVG_ANXIETY_BEFORE: `Anxiété Moy. Avant`,
      AVG_CHANGE: `% d'Évolution`,
      AVG_PAIN: `Douleur Moy.`,
      AVG_PAIN_AFTER: `Moy. Après`,
      AVG_PAIN_BEFORE: `Moy. Avant`,
      AVG_TIME: `Durée Moy.`,
      AWAKENING: `Reveil`,
      AWAKENING_AFTER: `Reveil après`,
      BEFORE: `Avant`,
      CANCELLED_ACCOUNTS: `Nb de comptes résiliés`,
      CHANGE: `Évolution`,
      CONVERSION_RATE: `Taux de conversion`,
      CONVERSIONS: `Conversions`,
      DAILY: `Aujourd’hui`,
      DISTRIBUTION_E_LEARNING_COMPLETED: `E-Learning complétés`,
      DISTRIBUTION_E_LEARNING_OPENED: `E-learning ouverts`,
      DISTRIBUTION_PRESCRIBED_BY_DOCTORS: `Médecins qui ont prescrit`,
      DISTRIBUTION_PRESCRIBED_BY_DOCTORS_LAST_MONTH: `Médecins ayant prescrit en 30 jours`,
      DISTRIBUTION_REGISTERED_DOCTORS: `Médecins inscrits`,
      DISTRIBUTION_REGISTERED_PATIENTS: `Patients abonnés`,
      DISTRIBUTION_REGISTERED_PATIENTS_LAST_MONTH: `Nouveaux abonnés (30j)`,
      DISTRIBUTION_SESSIONS_PATIENTS_LAST_WEEK: `Patients 1 séance/sem min`,
      DISTRIBUTION_SESSIONS_PATIENTS_AVG_LAST_MONTH: `Nombre moy. de séances/mois/patient`,
      DOCTORS: `Médecins`,
      GENDER: `Catégorie`,
      HOSPITALS: `Établissements de Santé`,
      INVITED: `Invité`,
      JOINED: `Rejoint`,
      LIFETIME_REVENUE: `Revenus Accumulés`,
      MONTHLY_TOTAL: `Mois Cumulés`,
      NEW_ACCOUNTS: `Nb de nouveaux comptes inscrits`,
      NO_CHANGE: `Pas de Changement`,
      NUMBER_OF_LISTENS: `Séances`,
      PAIN: `Mieux vivre avec sa douleur`,
      PAIN_AFTER: `Douleur après`,
      PAIN_BEFORE: `Douleur avant`,
      PAIN_LEVELS: `Niveau de Douleur`,
      PATIENTS: `Patients`,
      PENDING_INVITATIONS: `Invitations en attente`,
      PERC_IMPROVEMENT_LEVELS: `Taux d'évolution `,
      PRO_ACCOUNTS: `Comptes pro`,
      RELIEF_IN_PERCENT: `Soulagement en %`,
      REVENUE_INDIVIDUAL: `Chiffre d'affaires Individuel`,
      REVENUE_PRO: `Chiffre d'affaires Pro`,
      REVENUE_TOTAL: `Chiffre d'affaires Total`,
      SLEEP: `Sleep`,
      SLEEP_AFTER: `Sommeil après`,
      SESSIONS: `Séances`,
      THIS: `cette`,
      TODAY: `Aujourd'hui`,
      TOTAL_PATIENTS: `Nb de Patients`,
      TOTAL_REVENUE: `Chiffre d'affaires`,
      UPCOMING_REVENUE: `Revenu Prévu`,
      USERS: `Patients en cours`,
      WEEK: `semaine`,
      YEAR_TO_DATE: `Année en cours`,
    },
    subscriptionsPage: {
      A_NAME: `un nom`,
      A_NAME_OR_AN_EMAIL: `Un nom ou un email`,
      ACCOUNT_NAME: 'Nom du compte',
      ACTIVE: `Actif`,
      ALL_SUBSCRIPTIONS: `Tous les abonnements`,
      AN_EMAIL: `un email`,
      ASSOCIATED_PRO_ACCOUNT: `Associated Pro Account`,
      ASSOCIATED_DOCTOR_ACCOUNT: `Compte du médecin référent`,
      DAILY_USE: `Usage quotidien`,
      DECLINED: `Refusé`,
      EMAIL: `Email`,
      END_DATE: `Date de fin d'abonnement`,
      INACTIVE: `Inactif`,
      INDIVIDUAL: `Individuel`,
      INDIVIDUAL_SUBSCRIPTIONS: `Abonnements individuels`,
      MAKE_PAYMENT: `Effectuer un paiement`,
      NAME: `Nom`,
      PROFESSIONAL: `Professionnel`,
      PROFESSIONAL_SUBSCRIPTIONS: `Abonnements professionnels`,
      SIGNUP_DATE: `Date d'inscription`,
      SINCE: `Debut`,
      STATUS: `Statut`,
      SUBSCRIPTION_DETAILS: `Détails de l'Abonnement`,
      SUBSCRIPTIONS: `Abonnements`,
      SUBSCRIPTIONS_TABLE_DESCR_A: `Voici une liste des abonnements {{type}} avec leurs statistiques.`,
      SUBSCRIPTIONS_TABLE_DESCR_B: `Veuillez cliquer sur {{target}} pour obtenir les détails du compte.`,
      TYPE: `Type`,
      VIEW_USAGE: `View usage`,
    },
    table: {
      ACTION: `Action`,
      ACTIONS: `Actions`,
      ACTIVE: `Actif`,
      AFTER: `Après`,
      AGE: `Âge`,
      ARCHIVED: `Archivé`,
      AMOUNT: `Quantité`,
      APPROVED: `Apprové`,
      APPROVAL_STATUS: `Statut`,
      AVG_IMPROVEMENT: `Taux d'évolution`,
      AVG_PAIN_AFTER: `Moy. Après`,
      AVG_PAIN_BEFORE: `Moy. Avant`,
      AVG_SESSION_DURATION: `Avg Session Duration`,
      BEFORE: `Avant`,
      CHANGE: `Change`,
      CONNECTIONS: `Connexions`,
      COST: `Tarif`,
      COUNTRY: `Pays`,
      DAILY_USE: `Usage Quotidien`,
      DATE: `Date`,
      DOCTORS: `Médecins`,
      EMAIL: `Email`,
      EMAIL_PATIENT: `Email patient`,
      EMAIL_AND_SONG: `Email et Séance`,
      ERROR: `Erreur`,
      FREE: `Gratuit`,
      GENDER: `Catégorie`,
      INACTIVE: `Inactif`,
      INDICATION: `Indication`,
      INVITES_SENT: `Invitations envoyées`,
      INVITES_COMPLETED: `Invitations acceptées`,
      IMPROVEMENT: `Amélioration`,
      LAST_SESSION: `Dernière séance`,
      LAST_USED: `Dernière Utilisation`,
      LICENSES: `Licences`,
      MOST_PLAYED: `Top Séance`,
      NAME: `Nom`,
      NAME_OR_EMAIL: `Nom ou Email`,
      NUMBER_OF_LISTENS: `Nb de Séances`,
      PATIENTS: `Patients`,
      PATIENTS_ACTIVE: `Patients actifs`,
      PAYMENT: `Paiement`,
      PENDING: `Pending`,
      PERCENT_IMPROVEMENT: `% Évolution`,
      PERCENT_PATIENTS: `% Patients`,
      REFERRALS: `Références`,
      REPRESENTATIVE: `Visiteur Médical`,
      SESSION: `Session`,
      SESSIONS: `Nb de Séances`,
      SESSION_TYPE: `Type de Séance`,
      SIGNUP_DATE: `Date d'inscription`,
      SINCE: `Depuis`,
      SINCE: `Debut`,
      SONG: `Séance`,
      STATUS: `Statut`,
      TITLE: `Séance`,
      TOTAL_PER_SESSION: `Séance Total`,
      TYPE: `Type`,
    },
    trainingPage: {
      PLAY: `Commencer`,
      TRAINING: `Formation`,
      TRAINING_MEDIA: `Capsules Vidéos`,
      TRAINING_VIDEO: `Présentation Music Care`,
      TRAINING_VIDEO_1: `Vidéo - Module Centre Hospitalier`,
      TRAINING_VIDEO_2: `Vidéo - Module EHPAD`,
      TRAINING_VIDEO_3: `Vidéo - Module Clinique`,
    },
    userTypes: {
      DOCTOR: `Médecin`,
      ADMINISTRATOR: `Administrateur de Music Care`,
      PRO_USER: `Music Care Pro User`,
    },
  },
}
