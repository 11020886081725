import { formatAmount, formatDate } from 'utils'

export const accountPermissionsTranslation = {
  indications: {
    pain: 'INDICATIONS_PAIN',
    anxiety: 'INDICATIONS_ANXIETY',
    sleep: 'INDICATIONS_SLEEP',
    awakening: 'INDICATIONS_AWAKENING',
  },
  features: {
    alwaysOnWelcomeVideo: 'FEATURES_ALWAYS_WELCOME_VID',
    eLearning: 'E_LEARNING_WELCOME',
    immersive: 'FEATURES_IMMERSIVE',
    patientRecord: 'PATIENT_RECORD',
    maskPrivateInfo: 'MASK_PRIVATE_INFO',
    trialWithoutCreditCard: 'TRIAL_WITHOUT_CREDIT_CARD',
  },
  invitations: {
    doctor: 'INVITATIONS_DOCTOR',
    patient: 'INVITATIONS_PATIENT',
  },
  reporting: {
    painLevels: 'REPORTING_PAIN',
    musicStatistics: 'REPORTING_MUSIC_STATS',
    patientRecords: 'REPORTING_PATIENT_RECORDS',
  },
  admin: {
    createPro: 'ADMIN_CREATE_PRO',
    viewPro: 'ADMIN_VIEW_PRO',
    music: 'ADMIN_MANAGE_MUSIC',
    distribution: 'DISTRIBUTIONS',
  },
}

export const accountsDefaultListTypes = {
  en: {
    // // TODO: remove hospitals
    // hospitals: 'hospitals',
    'my-pro-account': 'myProAccount',
    patients: 'patients',
    doctors: 'doctors',
  },
  fr: {
    // // TODO: remove hospitals
    'mon-compte-pro': 'myProAccount',
    patients: 'patients',
    medecins: 'doctors',
  },
}

export const accountCellTypes = {
  actions: { headLabel: 'ACTIONS' },
  after: { headLabel: 'AFTER' },
  age: { headLabel: 'AGE' },
  avgImprovement: {
    headLabel: 'AVG_IMPROVEMENT',
    showChangeColor: true,
  },
  avgAfter: { headLabel: 'AVG_PAIN_AFTER' },
  avgBefore: { headLabel: 'AVG_PAIN_BEFORE' },
  before: { headLabel: 'BEFORE' },
  country: { headLabel: 'COUNTRY' },
  email: { headLabel: 'EMAIL' },
  emailPatient: { headLabel: 'EMAIL_PATIENT' },
  gender: { headLabel: 'GENDER' },
  improvement: { headLabel: 'IMPROVEMENT', showChangeColor: true },
  indication: { headLabel: 'INDICATION', translate: true },
  lastSession: { headLabel: 'LAST_SESSION' },
  licenses: { headLabel: 'LICENSES' },
  mostPlayed: { headLabel: 'MOST_PLAYED' },
  name: { headLabel: 'NAME' },
  nameOrEmail: { headLabel: 'NAME_OR_EMAIL', useInstead: ['name', 'email'] },
  patients: { headLabel: 'PATIENTS' },
  referrals: { headLabel: 'REFERRALS' },
  representative: { headLabel: 'REPRESENTATIVE' },
  signupDate: { headLabel: 'SIGNUP_DATE' },
  session: { headLabel: 'SESSION' },
  sessions: { headLabel: 'SESSIONS' },
  song: { headLabel: 'SONG' },
  type: { headLabel: 'TYPE', translate: true },
}

export const createAccountPatientDefaultQuestions = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis leo at est ultrices ultricies vel sed nibh?',
  'Interdum et malesuada fames ac ante ipsum primis in faucibus?',
  'Morbi at blandit quam. Nam lobortis tincidunt suscipit Proin sem est, consequat vitae tempus a, scelerisque lacinia lacus?',
  'Sed ut urna mi. Sed ut ultrices quam, ac lacinia purus?',
  'Proin cursus interdum bibendum. Praesent efficitur ipsum eget dignissim dapibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
  'Nullam eget ante hendrerit urna mattis luctus. Maecenas auctor turpis diam, vel sodales risus convallis eu?',
  'Etiam quis laoreet lacus. In malesuada ac tortor et maximus?',
  'Curabitur rutrum vitae massa elementum mattis. In sit amet viverra mi?',
  'Sed suscipit fermentum justo, et ultrices enim. Nam consequat dictum turpis sed egestas?',
  'Cras malesuada ex sed accumsan porta. Nam sodales risus eget quam laoreet, vitae bibendum quam gravida?',
  'Pellentesque augue magna, facilisis id risus molestie, pretium posuere quam?',
  'Nulla vitae purus eu est elementum efficitur. Fusce lobortis elit vitae sapien blandit tincidunt?',
  'Morbi porta nisl in sem auctor, eu aliquam nunc hendrerit?',
]

export const getPatientsStatsStructure = ({
  indicationConnections,
  totalPatients,
  totalSessions,
  pendingInvitations,
}) => {
  return {
    gridStats: [
      {
        title: 'USERS',
        amount: formatAmount(totalPatients),
      },
      {
        title: 'PENDING_INVITATIONS',
        amount: formatAmount(pendingInvitations),
      },
    ],
    doughnutConnectionsChart: {
      title: 'CONNECTIONS_TOTAL',
      data: {
        center: {
          value: formatAmount(totalSessions),
          descr: 'CONNECTIONS',
        },
        labels: ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING'],
        datasets: [
          {
            data: [
              indicationConnections.pain.connections,
              indicationConnections.anxiety.connections,
              indicationConnections.sleep.connections,
              indicationConnections.awakening.connections,
            ],
          },
        ],
        headCells: ['INDICATION', 'CONNECTIONS'],
      },
    },
  }
}

export const getPatientStatsStructure = ({
  painCount = 0,
  anxietyCount = 0,
  sleepCount = 0,
  awakeningCount = 0,
  total = 0,
}) => ({
  doughnutConnectionsChart: {
    title: 'CONNECTIONS_TOTAL',
    data: {
      center: {
        value: formatAmount(total),
        descr: total === 1 ? 'CONNECTION' : 'CONNECTIONS',
      },
      labels: ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING'],
      datasets: [
        {
          data: [painCount, anxietyCount, sleepCount, awakeningCount],
        },
      ],
      headCells: ['INDICATION', 'CONNECTIONS'],
    },
  },
})

export const getProAccountsStatsStructure = ({
  totalProUsers,
  totalPatients,
  indicationAverages,
  indicationConnections,
  mostPlayed,
  proAccountTypeName,
}) => ({
  proAccountTypeName,
  gridStats: [
    {
      title: 'PRO_ACCOUNTS',
      amount: formatAmount(totalProUsers),
    },
    {
      title: 'TOTAL_PATIENTS',
      amount: formatAmount(totalPatients),
    },
  ],
  table: [
    {
      id: 'songsPlayedData',
      headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
      data: mostPlayed.map(({ title, count }) => [
        { cellData: title, isHead: true, noTranslate: true },
        { cellData: formatAmount(count) },
      ]),
    },
    {
      id: 'indicationData',
      headCells: [
        { headCell: 'INDICATION' },
        { headCell: 'CONNECTIONS' },
        { headCell: 'CHANGE' },
      ],
      data: [
        [
          { cellData: 'PAIN', isHead: true },
          { cellData: formatAmount(indicationConnections.pain.connections) },
          { cellData: indicationConnections.pain.avgChange, isPercent: true },
        ],

        [
          { cellData: 'ANXIETY', isHead: true },
          { cellData: formatAmount(indicationConnections.anxiety.connections) },
          { cellData: indicationConnections.anxiety.avgChange, isPercent: true },
        ],

        [
          { cellData: 'SLEEP', isHead: true },
          { cellData: formatAmount(indicationConnections.sleep.connections) },
          { cellData: indicationConnections.sleep.avgChange, isPercent: true },
        ],
        [
          { cellData: 'AWAKENING', isHead: true },
          { cellData: formatAmount(indicationConnections.awakening.connections) },
          { cellData: indicationConnections.awakening.avgChange, isPercent: true },
        ],
      ],
    },
  ],
  charts: [
    [
      {
        type: 'barMultiple',
        height: '235px',
        chartData: {
          alwaysShowTooltips: true,
          noTooltipsTitles: true,
          chartWidth: '95',
          title: `CHANGE`,
          key: 'beforeAfterComparison',
          data: [
            {
              labels: ['PAIN_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.pain.before],
                },
              ],
              group: 0,
            },
            {
              labels: ['PAIN_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.pain.after],
                },
              ],
              group: 0,
            },
            {
              labels: ['ANXIETY_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.anxiety.before],
                },
              ],
              group: 1,
            },
            {
              labels: ['ANXIETY_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.anxiety.after],
                },
              ],
              group: 1,
            },
            {
              labels: ['SLEEP_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.sleep.after],
                },
              ],
              group: 2,
            },
            {
              labels: ['AWAKENING_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.awakening.after],
                },
              ],
              group: 3,
            },
          ],
        },
      },
    ],
  ],
})

export const getProAccountStatsStructure = ({
  name,
  totalSessions,
  indicationAverages,
  indicationConnections,
  mostPlayed,
  prescriberCode,
  referredBy,
  referralDate,
  totalLicenses,
  totalReferrals,
  t,
}) => ({
  gridName: {
    title: name,
    subtitle: {
      isBold: false,
      text: `${t('REFERRED_BY_REFERRER', { referrerName: referredBy })} ${formatDate(
        referralDate
      )}`,
    },
    body: [
      ...(prescriberCode
        ? [
            {
              text: `${prescriberCode}`,
              // TODO: Confirm if needs to be link
              // isLink: true,
              // link: getLocalizedLink(links.code),
            },
          ]
        : []),
      {
        text: `${formatAmount(totalLicenses)} ${t(
          `LICENSE${totalLicenses === 1 ? '' : 'S'}`
        )}`,
        // TODO: Confirm if needs to be link
        // isLink: true,
        // link: getLocalizedLink(links.licenses),
      },
      {
        text: `${formatAmount(totalReferrals)} ${t(
          `REFERRAL${totalReferrals === 1 ? '' : 'S'}`
        )}`,
        // TODO: Confirm if needs to be link
        // isLink: true,
        // link: getLocalizedLink(links.referrals),
      },
    ],
  },
  gridStats: [
    {
      title: 'NUMBER_OF_LISTENS',
      amount: formatAmount(totalSessions),
    },
  ],
  table: [
    {
      id: 'songsPlayedData',
      headCells: [{ headCell: 'MOST_PLAYED' }, { headCell: 'SESSIONS' }],
      data: mostPlayed.map(({ title, count }) => [
        { cellData: title, isHead: true, noTranslate: true },
        { cellData: formatAmount(count) },
      ]),
    },
    {
      id: 'indicationData',
      headCells: [
        { headCell: 'INDICATION' },
        { headCell: 'CONNECTIONS' },
        { headCell: 'CHANGE' },
      ],
      data: [
        [
          { cellData: 'PAIN', isHead: true },
          { cellData: formatAmount(indicationConnections.pain.connections) },
          { cellData: indicationConnections.pain.avgChange, isPercent: true },
        ],

        [
          { cellData: 'ANXIETY', isHead: true },
          { cellData: formatAmount(indicationConnections.anxiety.connections) },
          { cellData: indicationConnections.anxiety.avgChange, isPercent: true },
        ],

        [
          { cellData: 'SLEEP', isHead: true },
          { cellData: formatAmount(indicationConnections.sleep.connections) },
          { cellData: indicationConnections.sleep.avgChange, isPercent: true },
        ],
        [
          { cellData: 'AWAKENING', isHead: true },
          { cellData: formatAmount(indicationConnections.awakening.connections) },
          { cellData: indicationConnections.awakening.avgChange, isPercent: true },
        ],
      ],
    },
  ],
  charts: [
    [
      {
        type: 'barMultiple',
        height: '235px',
        chartData: {
          alwaysShowTooltips: true,
          noTooltipsTitles: true,
          chartWidth: '95',
          title: `CHANGE`,
          key: 'beforeAfterComparison',
          data: [
            {
              labels: ['PAIN_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.pain.before],
                },
              ],
              group: 0,
            },
            {
              labels: ['PAIN_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.pain.after],
                },
              ],
              group: 0,
            },
            {
              labels: ['ANXIETY_BEFORE'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.anxiety.before],
                },
              ],
              group: 1,
            },
            {
              labels: ['ANXIETY_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.anxiety.after],
                },
              ],
              group: 1,
            },
            {
              labels: ['SLEEP_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.sleep.after],
                },
              ],
              group: 2,
            },
            {
              labels: ['AWAKENING_AFTER'],
              translateLabels: true,
              datasets: [
                {
                  data: [indicationAverages.awakening.after],
                },
              ],
              group: 3,
            },
          ],
        },
      },
    ],
  ],
  doughnutConnectionsChart: {
    title: 'CONNECTIONS_TOTAL',
    data: {
      center: {
        value: formatAmount(totalSessions),
        descr: totalSessions === 1 ? 'CONNECTION' : 'CONNECTIONS',
      },
      labels: ['PAIN', 'ANXIETY', 'SLEEP', 'AWAKENING'],
      datasets: [
        {
          data: [
            indicationConnections.pain.connections,
            indicationConnections.anxiety.connections,
            indicationConnections.sleep.connections,
            indicationConnections.awakening.connections,
          ],
        },
      ],
      headCells: ['INDICATION', 'CONNECTIONS'],
    },
  },
})
