import React from 'react'
import { connect } from 'react-redux'
import SnackbarCustom from 'components/Snackbars/SnackbarCustom'
import { processQueue, setOpenState } from 'redux/actions'

const Snackbars = ({ activeSnackbar, isOpen, processQueue, setOpenState }) => {
  if (!activeSnackbar) return <></>

  const { snackbarId, message, success } = activeSnackbar
  return (
    <SnackbarCustom
      key={snackbarId}
      id={snackbarId}
      message={message}
      success={success}
      isOpen={isOpen}
      setOpenState={setOpenState}
      processQueue={processQueue}
    />
  )
}

const mapStateToProps = ({ snackbar: { activeSnackbar, isOpen } }) => ({
  activeSnackbar,
  isOpen,
})

const willMapDispatchToProps = { processQueue, setOpenState }

export default connect(mapStateToProps, willMapDispatchToProps)(Snackbars)
