import { SET_PRO_ACCOUNT_TYPES, SET_PRO_ACCOUNT_ROUTES } from '../constants'
import i18n from 'translation'
import { accountsApi } from 'api'
import { sortBy } from 'lodash'
import async from 'components/utils/Async'

const tRoutes = i18n.getFixedT(null, 'routes')
const AccountsPage = async(() => import('pages/accounts/AccountsPage'))

export const getProAccountTypes = () => async (dispatch) => {
  const { result, error } = await accountsApi.getProAccountTypes()

  // TODO: Display error message if error
  dispatch({
    type: SET_PRO_ACCOUNT_TYPES,
    payload: error ? null : result.proAccountTypes,
  })
}

export const getProAccountRoutes = () => async (dispatch) => {
  const { result, error } = await accountsApi.getProAccountTypes()

  const proAccountTypes = error ? null : result.proAccountTypes
  const proAccountRoutes = []
  if (proAccountTypes) {
    const accountsTranslated = tRoutes('ACCOUNTS').toLowerCase()
    proAccountRoutes.push(
      ...sortBy(
        [
          {
            typeId: 'accounts',
            routes: proAccountTypes.flatMap(({ name, id: accountId }) => [
              {
                name,
                path: `/${accountsTranslated}/${accountId}`,
                component: AccountsPage,
                permission: 'admin.viewPro',
              },
              {
                name,
                path: `/${accountsTranslated}/${accountId}/:id`,
                component: AccountsPage,
                permission: 'admin.viewPro',
                noSidebar: true,
              },
            ]),
          },
        ],
        ['name']
      )
    )
  }

  // TODO: Display error message if error
  dispatch({
    type: SET_PRO_ACCOUNT_ROUTES,
    payload: proAccountRoutes,
  })
}

export const resetProAccountTypes = () => async (dispatch) => {
  dispatch({
    type: SET_PRO_ACCOUNT_TYPES,
    payload: null,
  })
}
