import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Bell, MessageSquare, Search as SearchIcon } from 'react-feather'
import { darken } from 'polished'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import {
  Button as MuiButton,
  Grid as MuiGrid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { spacing } from '@material-ui/system'

import { HeaderLanguageMenu, HeaderUserMenu } from 'components/Layout/Header'

import { getLocalizedLink } from 'translation'

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`
const Button = styled(MuiButton)(spacing)
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`
const Grid = styled(MuiGrid)(spacing)
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
  }
`
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`

const Header = ({ onDrawerToggle, permissions }) => {
  const { t } = useTranslation('header')
  const history = useHistory()

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label={t('OPEN_DRAWER')}
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          {/* <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder={t('SEARCH')} />
            </Search>
          </Grid> */}
          <Grid item xs />
          <Grid item mr={4}>
            {permissions.invitations.patient && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className=""
                onClick={() => {
                  const link = getLocalizedLink(['createAccount', 'individual'])
                  history.push(link)
                }}
                px={14}
              >
                {t('INVITE_PATIENT')}
              </Button>
            )}
          </Grid>
          <Grid item>
            <HeaderLanguageMenu />
            <HeaderUserMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = ({
  auth: {
    attributes: { permissions },
  },
}) => ({ permissions })

export default connect(mapStateToProps)(withTheme(Header))
