import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { CssBaseline, Paper as MuiPaper, Button as MuiButton } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { isWidthUp } from '@material-ui/core/withWidth'
import { connect } from 'react-redux'
import { getCurrentLanguage, getLocalizedLink } from 'translation'
import { setELearningInfo } from 'redux/actions'
import { useHistory } from 'react-router-dom'
import { FooterELearning, Footer } from 'components/Layout/Footer'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`

const RootFullWidth = styled.div`
  display: flex;
  min-height: 100vh;
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Paper = styled(MuiPaper)(spacing)
const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

function ELearning({ children, width, footerFixed, eLearningInfo, setELearningInfo }) {
  const history = useHistory()
  const languageLink = getLocalizedLink(['register', 'doctor'])
  const registerLink = `${process.env.REACT_APP_PLAYER_APP_URL}${languageLink}?id=${eLearningInfo.id}`

  useEffect(() => {
    if (!eLearningInfo) return

    const { eLearningUrl } = eLearningInfo
    const drimifyWidget = new window.Drimify.Widget({
      autofocus: true,
      height: '1200px',
      element: 'drimify-container',
      engine: `${eLearningUrl}&lang=${getCurrentLanguage()}`,
      style: 'border:0px solid #d1d1d1; position: absolute; height: 100%;',
    })

    drimifyWidget.onCompletion = () => {
      // TODO: Confirm should disable timeout since we now have button in footer
      // setTimeout(() => {
      //   setELearningInfo(null)
      //   history.push(`${registerLink}?id=${id}`)
      // }, 7000)
    }

    drimifyWidget.load()
  }, [eLearningInfo])

  return eLearningInfo ? (
    <>
      {children}
      <FooterELearning
        onClick={() => (setELearningInfo(null), window.location.replace(registerLink))}
      />
    </>
  ) : (
    <RootFullWidth>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent p={isWidthUp('lg', width) ? 10 : 5}>{children}</MainContent>
        <Footer footerFixed={footerFixed} />
      </AppContent>
    </RootFullWidth>
  )
}

const mapStateToProps = ({ auth: { eLearningInfo } }) => ({ eLearningInfo })
const willMapDispatchToProps = {
  setELearningInfo,
}
export default connect(mapStateToProps, willMapDispatchToProps)(ELearning)
