import BariolRegular from 'assets/fonts/Bariol-Regular.woff'
import BariolRegular2 from 'assets/fonts/Bariol-Regular.woff2'
import BariolLight from 'assets/fonts/Bariol-Light.woff'
import BariolLight2 from 'assets/fonts/Bariol-Light.woff2'
import BariolBold from 'assets/fonts/Bariol-Bold.woff'
import BariolBold2 from 'assets/fonts/Bariol-Bold.woff2'
import BariolThin from 'assets/fonts/Bariol-Thin.woff'
import BariolThin2 from 'assets/fonts/Bariol-Thin.woff2'
import BariolBoldItalic from 'assets/fonts/BariolBold-Italic.woff'
import BariolBoldItalic2 from 'assets/fonts/BariolBold-Italic.woff2'
import BariolLightItalic from 'assets/fonts/BariolLight-Italic.woff'
import BariolLightItalic2 from 'assets/fonts/BariolLight-Italic.woff2'
import BariolRegularItalic from 'assets/fonts/BariolRegular-Italic.woff'
import BariolRegularItalic2 from 'assets/fonts/BariolRegular-Italic.woff2'
import BariolThinItalic from 'assets/fonts/BariolThin-Italic.woff'
import BariolThinItalic2 from 'assets/fonts/BariolThin-Italic.woff2'

export const bariol = {
  fontFamily: 'Bariol',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${BariolRegular}) format('woff'),
    url(${BariolRegular2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolBold = {
  fontFamily: 'Bariol',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${BariolBold}) format('woff'),
    url(${BariolBold2}) format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolLight = {
  fontFamily: 'Bariol',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${BariolLight}) format('woff'),
    url(${BariolLight2}) format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolItalic = {
  fontFamily: 'Bariol',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${BariolRegularItalic}) format('woff'),
    url(${BariolRegularItalic2}) format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolBoldItalic = {
  fontFamily: 'Bariol',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${BariolBoldItalic}) format('woff'),
    url(${BariolBoldItalic2}) format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolLightItalic = {
  fontFamily: 'Bariol',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    url(${BariolLightItalic}) format('woff'),
    url(${BariolLightItalic2}) format('woff2'),
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolThin = {
  fontFamily: 'Bariol',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    url(${BariolThin}) format('woff'),
    url(${BariolThin2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

export const bariolThinItalic = {
  fontFamily: 'Bariol',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    url(${BariolThinItalic}) format('woff'),
    url(${BariolThinItalic2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}
