import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  List,
  ListItemText,
  ListItem as MuiListItem,
  Button as MuiButton,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { getCurrentLanguage } from 'translation'

const Button = styled(MuiButton)(spacing)
const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`

function FooterELearning({ onClick }) {
  const { t } = useTranslation('footer')

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} justify="center">
          <List>
            <ListItem>
              <Button variant="contained" color="primary" onClick={onClick}>
                {t('SIGN_UP')}
              </Button>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default FooterELearning

FooterELearning.propTypes = { onClick: PropTypes.func.isRequired }
