import { format, subWeeks, subHours, subMinutes, subDays, subMonths } from 'date-fns'
import { random } from 'lodash'
import { doctorsData } from 'api/dummyData/accountsDummyData'

const sentNames = ['Houston Dudley', 'Titus Russo', 'Harper Small', 'Kenley Burton']

const idStartSent = 1602098763477

const sentData = sentNames.map((name, i) => {
  const id = String(idStartSent + i)
  const [firstName, lastName] = name.split(' ')
  const shortName = `${firstName[0]} ${lastName}`
  const email = `${firstName}@${lastName}.com`
  return {
    id,
    name: shortName,
    email,
    status: 'sent',
    date: format(subDays(new Date(), i), 'd/M/yy'),
  }
})

const acceptedData = doctorsData
  .slice(0, 5)
  .map(({ id, name, email, referredDate }, i) => {
    return {
      id,
      name,
      email,
      status: 'accepted',
      date: referredDate,
      links: { name: { path: ['accounts', 'doctors'], param: id } },
    }
  })

export const invitationsData = [...sentData, ...acceptedData]
