import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  ADD_SNACKBAR,
  PROCESS_SNACKBAR_QUEUE,
} from '../constants'

const initialState = {
  isOpen: false,
  message: '',
  activeSnackbar: null,
  snackbarQueue: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SNACKBAR: {
      const { message, snackbarId, success } = action.payload

      const updatedQueue = [...state.snackbarQueue, { message, snackbarId, success }]

      return {
        ...state,
        snackbarQueue: updatedQueue,
      }
    }

    case OPEN_SNACKBAR: {
      return { ...state, isOpen: true }
    }

    case CLOSE_SNACKBAR: {
      return { ...state, isOpen: false }
    }

    case PROCESS_SNACKBAR_QUEUE: {
      if (state.snackbarQueue.length === 0)
        return {
          ...state,
        }

      const { message, success, snackbarId } = state.snackbarQueue[0]
      const updatedQueue = state.snackbarQueue.slice(1)

      return {
        ...state,
        activeSnackbar: { message, success, snackbarId },
        snackbarQueue: updatedQueue,
        isOpen: true,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
