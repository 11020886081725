// import { newApiRequest as apiRequest } from 'api/apiRequest'
import { apiRequest } from '../apiRequest'
import i18n, { getCurrentLanguage, getLocalizedLink } from 'translation'
import {
  allData,
  dashboardStatsData,
  doctorsData,
  doctorStatsData,
  hospitalsData,
  hospitalStatsData,
  hospitalsStatsData,
  independentPractitionersData,
  independentPractitionerStatsData,
  patientsData,
  patientStatsData,
  patientsStatsData,
  representativesData,
  representativeStatsData,
  sessionsData,
} from '../dummyData/accountsDummyData'
import {
  getPatientsStatsStructure,
  getPatientStatsStructure,
  getProAccountsStatsStructure,
  getProAccountStatsStructure,
} from 'components/Accounts/constants'
import { formatAmount, formatDate, getSessionTimeFormat } from 'utils'

const t = i18n.getFixedT(null, 'accountsPage')

const getAllAccounts = () => {
  return {
    data: allData,
    tableInfo: {
      heads: ['type', 'nameOrEmail', 'sessions', 'avgBefore', 'avgAfter', 'actions'],
      initialOrder: 'nameOrEmail',
      rowIdName: 'id',
    },
  }
}

const getDoctors = (accountId) => {
  return accountId
    ? {
        data: patientsData,
        tableInfo: {
          heads: [
            'email',
            'gender',
            'age',
            'sessions',
            'avgBefore',
            'avgAfter',
            'mostPlayed',
          ],
          initialOrder: 'email',
          rowIdName: 'email',
        },
      }
    : {
        data: doctorsData,
        tableInfo: {
          heads: [
            'name',
            'email',
            'representative',
            'referrals',
            'avgBefore',
            'avgAfter',
            'avgImprovement',
            'actions',
          ],
          rowIdName: 'email',
          initialOrder: 'name',
        },
      }
  // return apiRequest('accounts', []).get({ id }, config)
}

const getIndependentPractitioners = (accountId) => {
  return accountId
    ? {
        data: patientsData,
        tableInfo: {
          heads: [
            'email',
            'gender',
            'age',
            'sessions',
            'avgBefore',
            'avgAfter',
            'avgImprovement',
            'mostPlayed',
          ],
          initialOrder: 'email',
          rowIdName: 'email',
        },
      }
    : {
        data: independentPractitionersData,
        tableInfo: {
          heads: [
            'name',
            'email',
            'representative',
            'referrals',
            'avgBefore',
            'avgAfter',
            'avgImprovement',
            'actions',
          ],
          rowIdName: 'email',
          initialOrder: 'name',
        },
      }
  // return apiRequest('accounts', []).get({ id }, config)
}

const getRepresentatives = async (accountId) => {
  return accountId
    ? {
        data: doctorsData,
        tableInfo: {
          heads: [
            'name',
            'patients',
            'sessions',
            'avgBefore',
            'avgAfter',
            'avgImprovement',
            'mostPlayed',
            'actions',
          ],
          rowIdName: 'name',
          initialOrder: 'name',
        },
      }
    : {
        data: representativesData,
        tableInfo: {
          heads: ['name', 'email', 'referrals', 'actions'],
          rowIdName: 'email',
          initialOrder: 'name',
        },
      }
  // return apiRequest('accounts', []).get({ id }, config)
}

const getPatients = async (accountId) => {
  if (accountId) {
    // INDIVIDUAL PATIENT
    const { result, error } = await apiRequest(
      `/accounts/patient?lang=${getCurrentLanguage()}&id=${accountId}`,
      {
        sessions: {},
        stats: {},
        user: {},
      }
    ).get()

    if (error) {
      console.log('Error fetching patient:', error)
      return { error }
    }

    const parsedSessions = result.sessions.map((session) => ({
      song: session.title,
      before: session.painBefore,
      after: session.painAfter,
      indication: session.indication.toUpperCase(),
      // session: formatDate(session.createdAt, getSessionTimeFormat()),
      session: formatDate(session.createdAt, 'yyyy/MM/dd H:mm'),
      type: 'SESSION',
    }))

    // const gridName = {
    //   title: result.stats.email,
    //   subtitle: {
    //     isBold: false,
    //     text: `${t('REFERRED_BY_REFERRER', { referrerName: result.stats.referredBy })} ${formatDate(
    //       new Date(result.stats.referralDate),
    //       'd/M/yy'
    //     )}`,
    //   },
    //   body: {
    //     // TODO: hide Patient records until v3
    //     //   isLink: true,
    //     //   text: t('HEALTH_RECORD'),
    //     //   link: getLocalizedLink(links.healthRecord),
    //   },
    // }

    const parsedStatsData = getPatientStatsStructure(result.stats)

    const parsedUserInfo = {
      ...result.user,
      activeSince: formatDate(result.user.referralDate),
    }

    return {
      data: parsedSessions,
      tableInfo: {
        heads: ['session', 'song', 'indication', 'before', 'after'],
        initialOrder: 'session',
        rowIdName: 'session',
      },
      statsData: parsedStatsData,
      userInfo: parsedUserInfo,
    }
  }

  // ALL PATIENTS
  const { result, error } = await apiRequest(
    `/accounts/patients/list?lang=${getCurrentLanguage()}`,
    {
      patients: [],
      stats: {},
    }
  ).get()

  // TODO: Handle error
  if (error) {
    console.log('Error fetching patients:', error)
    return { error }
  }

  const parsedPatients = result.patients.map(
    ({ id, email, sessions: { sessionCount, lastSession }, createdAt }) => ({
      id,
      emailPatient: email,
      sessions: sessionCount,
      links: {
        emailPatient: { path: ['accounts', 'patients'], param: id },
        // TODO:
        // healthRecord: { path: [`#`] },
      },
      type: 'PATIENT',
      signupDate: formatDate(createdAt),
      lastSession: sessionCount ? formatDate(lastSession) : null,
    })
  )

  const parsedStats = getPatientsStatsStructure(result.stats)

  return {
    data: parsedPatients,
    tableInfo: {
      heads: ['emailPatient', 'signupDate', 'sessions', 'lastSession'],
      initialOrder: 'email',
      rowIdName: 'id',
    },
    statsData: parsedStats,
  }
}

const getHospitals = (hospitalAccountId) => {
  return hospitalAccountId
    ? getSessions(hospitalAccountId, 'hospital')
    : {
        data: hospitalsData,
        tableInfo: {
          heads: [
            'name',
            // TODO: Disabled until enable profile user info editing
            // 'country',
            'licenses',
            'sessions',
            'avgBefore',
            'avgAfter',
            'avgImprovement',
            'mostPlayed',
            'actions',
          ],
          rowIdName: 'id',
          initialOrder: 'name',
        },
      }
}

const getProAccounts = async (type, accountId, canEditAccounts) => {
  if (accountId) {
    // INDIVIDUAL PRO ACCOUNT
    const { result, error } = await apiRequest(
      `/accounts/pro/stats?lang=${getCurrentLanguage()}&id=${accountId}&type=${type}`,
      {
        sessions: {},
        stats: {},
      }
    ).get()

    if (error) {
      console.log('Error fetching pro account:', error)
      return { error }
    }

    const parsedSessions = result.sessions.map((session) => ({
      song: session.title,
      before: session.painBefore,
      after: session.painAfter,
      indication: session.indication.toUpperCase(),
      session: formatDate(session.createdAt, getSessionTimeFormat()),
      type: 'SESSION',
    }))

    const parsedStatsData = getProAccountStatsStructure({ ...result.stats, t })

    return {
      data: parsedSessions,
      tableInfo: {
        heads: ['session', 'song', 'indication', 'before', 'after'],
        initialOrder: 'session',
        rowIdName: 'session',
      },
      statsData: parsedStatsData,
    }
  }

  // ALL PRO ACCOUNTS
  const { result, error } = await apiRequest(
    `/accounts/pro/stats/list?lang=${getCurrentLanguage()}&type=${type}`,
    {
      proAccounts: [],
      stats: {},
    }
  ).get()

  // TODO: Handle error
  if (error) {
    console.log('Error fetching pro accounts:', error)
    return { error }
  }

  const parsedProAccounts = result.proAccounts.map(
    ({
      id,
      name,
      licenses,
      sessions: { avgAfter, avgBefore, sessionCount, mostPlayed },
    }) => ({
      id,
      name,
      avgAfter,
      avgBefore,
      sessions: sessionCount,
      mostPlayed,
      licenses,
      links: {
        name: { path: ['accounts', type], param: id },
        // TODO: Disable until next stage
        // healthRecord: { path: [`#`] },
      },
      type: 'PRO_ACCOUNT',
    })
  )

  const parsedStats = getProAccountsStatsStructure(result.stats)

  return {
    data: parsedProAccounts,
    tableInfo: {
      heads: [
        'name',
        // TODO: Disabled until enable profile user info editing
        // 'country',
        'licenses',
        'sessions',
        'avgBefore',
        'avgAfter',
        'mostPlayed',
        ...(canEditAccounts ? ['actions'] : []),
      ],
      rowIdName: 'id',
      initialOrder: 'name',
    },
    statsData: parsedStats,
  }
}

const getSessions = (accountId, type) => {
  return {
    data: sessionsData,
    tableInfo: {
      heads: ['session', 'song', 'indication', 'before', 'after', 'improvement'],
      initialOrder: 'session',
      rowIdName: 'session',
    },
  }
  // return apiRequest('accounts', []).get({ id }, config)
}

const getAccountsList = (type, id, canEditAccounts) => {
  // Temporary mock function until backend up and running

  switch (type) {
    case 'all':
      return getAllAccounts()
    case 'patients':
      return getPatients(id)
    case 'representatives':
      return getRepresentatives(id)
    case 'doctors':
      return getDoctors(id)

    case 'hospitals':
      return getHospitals(id)
    case 'independentPractitioners':
      return getIndependentPractitioners(id)
    case 'sessions':
      return getSessions(id)
    default:
      return getProAccounts(type, id, canEditAccounts)
  }
}

const getAccountsStats = async (type, id) => {
  // Temp mock function until backend up and running

  if (true) {
    switch (type) {
      case 'patients': {
        const { email, referrerName, referredDate, links } = patientsData.find(
          ({ id: someId }) => someId === '1603386645359'
        )
        const gridName = {
          title: email,
          subtitle: {
            isBold: false,
            text: `${t('REFERRED_BY_REFERRER', { referrerName })} ${referredDate}`,
          },
          body: {
            // TODO: hide Patient records until v3
            //   isLink: true,
            //   text: t('HEALTH_RECORD'),
            //   link: getLocalizedLink(links.healthRecord),
          },
        }
        return { ...patientStatsData, gridName }
      }

      case 'doctors': {
        const { fullName, referrerName, referredDate, organization } = doctorsData.find(
          ({ id: someId }) => someId === id
        )
        const gridName = {
          title: fullName,
          subtitle: {
            isBold: false,
            text: `${t('REFERRED_BY_REFERRER', { referrerName })} ${referredDate}`,
          },
          body: { text: organization },
        }
        return { ...doctorStatsData, gridName }
      }

      case 'independentPractitioners': {
        const { fullName, referrerName, referredDate, organization } =
          independentPractitionersData.find(({ id: someId }) => someId === id)
        const gridName = {
          title: fullName,
          subtitle: {
            isBold: false,
            text: `${t('REFERRED_BY_REFERRER', { referrerName })} ${referredDate}`,
          },
          body: { text: organization },
        }
        return { ...independentPractitionerStatsData, gridName }
      }

      case 'representatives': {
        const { fullName, company, startDate } = representativesData.find(
          ({ id: someId }) => someId === id
        )
        const gridName = {
          title: fullName,
          subtitle: { isBold: true, text: company },
          body: { text: `${t('SINCE')} ${startDate}` },
        }
        return { ...representativeStatsData, gridName }
      }

      case 'hospitals': {
        const { name, createdBy, createdDate, referrals, licenses, links, code } =
          hospitalsData.find(({ id: someId }) => someId === id)
        const gridName = {
          title: name,
          subtitle: {
            isBold: false,
            text: `${t('CREATED_BY')} ${createdBy} ${createdDate}`,
          },
          body: [
            {
              text: `${code}`,
              isLink: true,
              link: getLocalizedLink(links.code),
            },
            {
              text: `${licenses} ${t('LICENSES')}`,
              isLink: true,
              link: getLocalizedLink(links.licenses),
            },
            {
              text: `${referrals} ${t('REFERRALS')}`,
              isLink: true,
              link: getLocalizedLink(links.referrals),
            },
          ],
        }
        return { ...hospitalStatsData, gridName }
      }
      default:
        return {}
    }
  }

  switch (type) {
    case 'patients':
      console.log('patientsStatsData:', patientsStatsData)

      return patientsStatsData
    case 'hospitals':
      return hospitalsStatsData
    default:
      return {}
  }
}

const getAccountStats = () => {
  return { success: true, data: { ...dashboardStatsData } }
}

const getAccountInfo = (accountId) => {
  // TODO: Call is mocked until api
  const accountInfo = allData.find(({ id }) => id === accountId)
  if (accountInfo) return { result: { success: true, accountInfo } }

  return {
    result: { success: false },
    error: new Error('Account is inaccessible or does not exist.'),
  }

  // return apiRequest(`/accounts/${accountId}`, {}).get()
}

const getProAccountTypes = () => apiRequest('/accounts/pro-type/list', []).get()

const createProAccount = (newAccountProData) =>
  apiRequest('/accounts/pro/create', {}).post(newAccountProData)

const createDoctorAccount = (newAccountDoctorData) =>
  apiRequest('/accounts/doctor/create', {}).post(newAccountDoctorData)

const updateProfile = (newAccountData) =>
  apiRequest('accounts/pro/profile/update', {}).put(newAccountData)

const getProfileInfo = () => apiRequest('accounts/pro/profile').get()

const getProAccount = (id) => apiRequest(`accounts/pro?id=${id}`).get(id)

const updateProAccount = (updatedAccountInfo) =>
  apiRequest('accounts/pro/update', {}).put(updatedAccountInfo)

export const accountsApi = {
  createDoctorAccount,
  createProAccount,
  getProAccountTypes,
  getAccountInfo,
  getAccountsList,
  getAccountsStats,
  getAccountStats,
  getProAccount,
  updateProAccount,
  updateProfile,
  getProfileInfo,
}
