import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Footer from 'components/Layout/Footer/Footer'
import { CssBaseline, Paper as MuiPaper } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { isWidthUp } from '@material-ui/core/withWidth'
import { connect } from 'react-redux'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`

const RootFullWidth = styled.div`
  display: flex;
  min-height: 100vh;
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Paper = styled(MuiPaper)(spacing)
const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

function Auth({ children, isColumn, width, footerFixed }) {
  return isColumn ? (
    <RootFullWidth>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent p={isWidthUp('lg', width) ? 10 : 5}>{children}</MainContent>
        <Footer footerFixed={footerFixed} />
      </AppContent>
    </RootFullWidth>
  ) : (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  )
}

export default Auth
