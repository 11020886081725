import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from 'components/PrivateRoute/'
import AuthLayout from 'layouts/Auth'
import DashboardLayout from 'layouts/Dashboard'
import BareLayout from 'layouts/Bare'
import ELearningLayout from 'layouts/ELearning'
import Page404 from 'pages/auth/Page404'
import {
  bareAuthenticatedLayoutRoutes,
  dashboardLayoutRoutes,
  barePublicLayoutRoutes,
  eLearningRoutes,
} from 'routes'
import { getLocalizedLink } from 'translation'
import { getProAccountRoutes } from 'redux/actions'

const childRoutes = (RouteComponent, Layout, routes) =>
  routes
    .filter(({ isLinkOut }) => !isLinkOut)
    .map(
      ({
        children,
        path,
        permission,
        component: Component,
        isFullWidth,
        isColumn,
        language,
        id,
        footerFixed,
        noDoctor,
      }) =>
        children ? (
          // Route item with children
          children.map(
            ({
              center,
              path,
              permission: childPermission,
              component: Component,
              isOnlySidebarLink,
              language,
              noDoctor,
            }) =>
              isOnlySidebarLink ? null : (
                <RouteComponent
                  key={path}
                  path={path}
                  permission={childPermission}
                  noDoctor={noDoctor}
                  exact
                  render={(props) => (
                    <Layout
                      isFullWidth={isFullWidth}
                      routes={routes}
                      center={center}
                      isColumn={isColumn}
                    >
                      <Component language={language} {...props} />
                    </Layout>
                  )}
                />
              )
          )
        ) : (
          // Route item without children
          <RouteComponent
            key={id}
            path={path}
            permission={permission}
            exact
            noDoctor={noDoctor}
            render={(props) => (
              <Layout
                isFullWidth={isFullWidth}
                routes={routes}
                isColumn={isColumn}
                footerFixed={footerFixed}
              >
                <Component {...props} language={language} />
              </Layout>
            )}
          />
        )
    )

const Routes = ({ getProAccountRoutes, proAccountRoutes, permissions }) => {
  useEffect(() => {
    if (permissions?.admin?.viewPro) getProAccountRoutes()
  }, [permissions?.admin?.viewPro])

  const addDynamicRoutes = (staticRoutes, dynamicRoutes) =>
    staticRoutes.map((routeGroup) => {
      const routesToAdd = dynamicRoutes.find(({ typeId }) => typeId === routeGroup.id)
      if (!routesToAdd || !routeGroup.children) return routeGroup

      return { ...routeGroup, children: [...routeGroup.children, ...routesToAdd.routes] }
    })

  const dashboardLayoutRoutesWithProAccountRoutes = proAccountRoutes.length
    ? addDynamicRoutes(dashboardLayoutRoutes, proAccountRoutes)
    : dashboardLayoutRoutes

  return (
    <Router>
      <Switch>
        {childRoutes(
          PrivateRoute,
          DashboardLayout,
          dashboardLayoutRoutesWithProAccountRoutes
        )}
        {childRoutes(PrivateRoute, BareLayout, bareAuthenticatedLayoutRoutes)}
        {childRoutes(Route, AuthLayout, barePublicLayoutRoutes)}
        {childRoutes(Route, ELearningLayout, eLearningRoutes)}
        <Route
          path="/"
          render={() => <Redirect to={getLocalizedLink(['dashboard'])} />}
        />
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  )
}

const mapStateToProps = ({ auth: { attributes }, accounts: { proAccountRoutes } }) => ({
  permissions: attributes?.permissions,
  proAccountRoutes,
})

const willMapDispatchToProps = { getProAccountRoutes }

export default connect(mapStateToProps, willMapDispatchToProps)(Routes)
