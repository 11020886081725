import {
  addDays,
  format,
  parse,
  subWeeks,
  subHours,
  subMinutes,
  subDays,
  subMonths,
} from 'date-fns'
import { range, random, sortBy } from 'lodash'

import {
  representativesData,
  hospitalsData,
  patientsData,
} from 'api/dummyData/accountsDummyData'

export const individualData = patientsData.map(({ id, email, referredDate }, i) => {
  const status = i % 4 === 0 ? 'inactive' : 'active'
  const payment =
    status === 'active'
      ? format(subDays(new Date(), random(1, 10)), 'd/M/yy')
      : 'DECLINED'

  return {
    id,
    type: 'INDIVIDUAL',
    email,
    status,
    payment,
    signupDate: referredDate,
    lastUsed: format(subDays(new Date(), random(1, 10)), 'd/M/yy'),
    dailyUse: `${random(0, 9) * 10}m`,
    makePayment: payment === 'DECLINED',
    links: { email: { path: ['subscriptions', 'individual'], param: id } },
    endDate: format(addDays(new Date(), random(10, 120)), 'd/M/yy'),
  }
})

export const professionalData = sortBy(
  [
    ...representativesData.map(({ id, name, startDate }, i) => {
      const status = i % 4 === 0 ? 'inactive' : 'active'
      const payment =
        status === 'active'
          ? format(subDays(new Date(), random(1, 10)), 'd/M/yy')
          : 'DECLINED'

      return {
        id,
        name,
        type: 'PROFESSIONAL',
        status,
        payment,
        status,
        dailyUse: `${random(1, 9) * 105}m`,
        lastUsed: format(subDays(new Date(), random(1, 10)), 'd/M/yy'),
        licenses: random(20, 200),
        links: { name: { path: ['subscriptions', 'professional'], param: id } },
        makePayment: payment === 'DECLINED',
        signupDate: startDate,
      }
    }),
    ...hospitalsData.map(({ id, createdDate, name, licenses }, i) => {
      const status = i % 4 === 0 ? 'inactive' : 'active'
      const payment =
        status === 'active'
          ? format(subDays(new Date(), random(1, 10)), 'd/M/yy')
          : 'DECLINED'
      return {
        id,
        name,
        type: 'PROFESSIONAL',
        status,
        licenses,
        payment,
        status,
        lastUsed: format(subDays(new Date(), random(1, 10)), 'd/M/yy'),
        dailyUse: `${random(1, 9) * 166}m`,
        makePayment: payment === 'DECLINED',
        links: { name: { path: ['subscriptions', 'professional'], param: id } },
        signupDate: createdDate,
      }
    }),
  ].map((subscription) => ({
    ...subscription,
    endDate: format(addDays(new Date(), random(10, 120)), 'd/M/yy'),
  })),
  ['name']
)

export const allData = sortBy([...professionalData, ...individualData], ['lastUsed'])

export const professionalStatsData = {
  gridStats: [
    {
      title: 'REVENUE_PRO',
      amount: '$730,000',
    },
    {
      title: 'NEW_ACCOUNTS',
      amount: '500',
    },
    {
      title: 'CANCELLED_ACCOUNTS',
      amount: '100',
    },
  ],
}

export const individualStatsData = {
  gridStats: [
    {
      title: 'REVENUE_INDIVIDUAL',
      amount: '$240,000',
    },
    {
      title: 'NEW_ACCOUNTS',
      amount: '500',
    },
    {
      title: 'CANCELLED_ACCOUNTS',
      amount: '100',
    },
  ],
}

export const allStatsData = {
  gridStats: [
    {
      title: 'REVENUE_INDIVIDUAL',
      amount: '$240,000',
    },
    {
      title: 'REVENUE_PRO',
      amount: '$730,000',
    },
    {
      title: 'REVENUE_TOTAL',
      amount: '$1,730,000',
    },
  ],
}

export const singleSubscriptionData = range(100).map((i) => {
  const date = format(subWeeks(new Date(), i), 'd/M/yy')
  const payment = i
    ? random(1, 10) % 5 === 0
      ? 'DECLINED'
      : format(addDays(parse(date, 'd/M/yy', new Date()), 3), 'd/M/yy')
    : undefined

  return {
    amount: '9.99',
    date,
    payment,
    makePayment: payment === 'DECLINED',
  }
})

export const singleSubscriptionStatsData = {
  gridStats: [
    {
      title: 'TOTAL_REVENUE',
      amount: '$480,000',
    },
  ],
}
