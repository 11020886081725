import { createMuiTheme } from '@material-ui/core/styles'
import { enUS, frFR } from '@material-ui/core/locale'

import variants from 'theme/variants'
import typography from 'theme/typography'
import overrides from 'theme/overrides'
import breakpoints from 'theme/breakpoints'
import props from 'theme/props'
import shadows from 'theme/shadows'
import * as fonts from 'theme/fonts'
import { getCurrentLanguage } from 'translation'

const theme = (variant) => {
  return createMuiTheme(
    {
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      spacing: 4,
      body: variant.body,
      header: variant.header,
      palette: variant.palette,
      sidebar: variant.sidebar,
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': Object.values(fonts),
          },
        },
      },
    },
    getCurrentLanguage() === 'fr' ? frFR : enUS
  )
}

const themes = variants.reduce(
  (acc, variant) => ((acc[variant.name] = theme(variant)), acc),
  {}
)

export default themes
