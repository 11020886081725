import { useEffect, useState } from 'react'
import Amplify, { Hub } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { displaySocialAuthMessage, initialAutoSignIn } from 'redux/actions'

const useInitialization = () => {
  const [initialAuthDone, setInitialAuthDone] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation('accountsPage')

  useEffect(() => {
    // Initiate Hub before Auth configuration so that it can report on sign in events
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        // case 'signIn':
        //   break
        // case 'signOut':
        //   break
        // case 'customOAuthState':
        //   break
        case 'cognitoHostedUI_failure':
          {
            let message
            let isError
            if (
              data.message.startsWith('PreSignUp+failed+with+error+User+does+not+exist')
            ) {
              message = 'PLEASE_REGISTER_BEFORE_SOCIAL_SIGN_IN'
              isError = true
            } else if (
              data.message ===
              'PreSignUp+failed+with+error+User+status+must+be+CONFIRMED+to+sign+in+with+a+social+identity.+Current+status%3A+FORCE_CHANGE_PASSWORD.+'
            ) {
              message = 'PLEASE_SET_A_PRIVATE_PASSWORD_BEFORE_SOCIAL_SIGN_IN'
              isError = true
            } else if (data.message.startsWith('Already+found+an+entry+for+username+')) {
              message = 'ACCOUNTS_UPDATED_FOR_SOCIAL_SIGN_IN'
              isError = false
            } else {
              message = 'AN_ERROR_OCCURED_PLEASE_TRY_LATER'
              isError = true
            }

            if (message)
              displaySocialAuthMessage({ message: t(message), isError })(dispatch)
          }
          break
      }
    })

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_AUTH_REGION,
        userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_WEB_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
          scope: process.env.REACT_APP_AWS_AUTH_SCOPE.split(','),
          redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGN_OUT,
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    })

    const initialAuth = async () => {
      await initialAutoSignIn()(dispatch)
      setInitialAuthDone(true)
    }
    initialAuth()
  }, [])

  return initialAuthDone
}

export default useInitialization
