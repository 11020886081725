import { format } from 'date-fns'
import { enUS, fr } from 'date-fns/locale'

import { getCurrentLanguage } from 'translation'

const locales = { en: enUS, fr }

const currencySymbols = {
  EUR: '€',
  USD: '$',
  DEFAULT: '€',
}

export const getSessionTimeFormat = () => {
  const datePattern =
    process.env[`REACT_APP_DATE_FORMAT_${getCurrentLanguage().toUpperCase()}`]
  const isEnglish = getCurrentLanguage() === 'en'
  const hourPattern = isEnglish ? 'h:mmaaa' : 'H:mm'

  return `${datePattern} ${hourPattern}`
}

export const formatDate = (
  date,
  formatPattern = process.env[
    `REACT_APP_DATE_FORMAT_${getCurrentLanguage().toUpperCase()}`
  ]
) => {
  const dateObj = date instanceof Date ? date : new Date(date)
  return format(dateObj, formatPattern, { locale: locales[getCurrentLanguage()] })
}

export const formatAmount = (number, decimalDigits = 0, { showFull } = {}) => {
  const parsedBySize =
    showFull || Math.abs(number) < 1000
      ? getLocaleStringFromNumber(number, decimalDigits)
      : Math.abs(number) < 1000000
      ? `${getLocaleStringFromNumber(number / 1000, 1)}k`
      : `${getLocaleStringFromNumber(number / 1000000, 1)}m`

  return parsedBySize
}

export const formatMoney = (number, currency) => {
  const currencySymbol = currencySymbols[currency] || currencySymbols.DEFAULT
  return `${currencySymbol}${formatAmount(number, 2)}`
}

const getLocaleStringFromNumber = (number, digits = 2) => {
  const locale = getCurrentLanguage()
  return number.toLocaleString(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}
