import { combineReducers } from 'redux'

import accountsReducer from './accountsReducer'
import authReducer from './authReducer'
import musicReducer from './musicReducer'
import snackbarReducer from './snackbarReducer'
import themeReducer from './themeReducer'

export default combineReducers({
  accounts: accountsReducer,
  auth: authReducer,
  music: musicReducer,
  snackbar: snackbarReducer,
  theme: themeReducer,
})
