export const getAudioDuration = (localFile) => {
  return new Promise((resolve) => {
    let audio = new Audio(localFile)
    // If can't read the metadata in two seconds, assume the file is corrupt or not an audio file
    const notAnAudioFileTimeout = setTimeout(() => resolve({}), 2000)
    audio.setAttribute('preload', 'metadata')
    audio.addEventListener('loadedmetadata', () => {
      audio.pause()
      const duration = Math.floor(audio.duration)
      audio = null
      clearImmediate(notAnAudioFileTimeout)
      resolve({ duration })
    })
  })
}
