import axios from 'axios'
import { Auth } from 'aws-amplify'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
})

api.interceptors.request.use(
  async function refreshInterceptor(config) {
    try {
      const session = await Auth.currentSession()

      config.headers.Authorization = 'Bearer ' + session.getIdToken().jwtToken
    } catch {
      // No logged-in user: don't set auth header
    }
    return config
  },
  (e) => Promise.reject(e)
)

export const apiOld = axios.create({
  baseURL: process.env.REACT_APP_OLD_API_URL,
})

export const apiLambdas = axios.create({
  baseURL: process.env.REACT_APP_LAMBDAS_API,
})

apiLambdas.interceptors.request.use(
  async function refreshInterceptor(config) {
    try {
      const session = await Auth.currentSession()

      config.headers.Authorization = 'Bearer ' + session.getAccessToken().jwtToken
    } catch {
      // No logged-in user: don't set auth header
    }
    return config
  },
  (e) => Promise.reject(e)
)
