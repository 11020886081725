import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Snackbar } from '@material-ui/core'
import { Check, ReportProblem, Close } from '@material-ui/icons'
import { green, red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  successIcon: {
    color: green[500],
    marginRight: '0.3em',
  },
  failureIcon: {
    color: red[500],
    marginRight: '0.3em',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const SnackbarCustom = ({ id, isOpen, message, processQueue, setOpenState, success }) => {
  const classes = useStyles()
  const handleClose = (_, reason) => reason !== 'clickaway' && setOpenState(false)
  const handleExited = () => processQueue()

  const resultIcon = success ? (
    <Check className={classes.successIcon} />
  ) : (
    <ReportProblem className={classes.failureIcon} />
  )

  return (
    <Snackbar
      key={id}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      onExited={handleExited}
      ContentProps={{
        'aria-describedby': `snackbar-message-${id}`,
      }}
      message={
        <span className={classes.message}>
          {resultIcon} <span id={`snackbar-message-${id}`}>{message}</span>
        </span>
      }
      action={
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <Close />
        </IconButton>
      }
    />
  )
}

export default SnackbarCustom

SnackbarCustom.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  processQueue: PropTypes.func.isRequired,
  setOpenState: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
}
