import { apiRequest } from '../apiRequest'
import { getCurrentLanguage } from 'translation'

const createDistribution = (name) => {
  const params = { name, language: getCurrentLanguage() }

  return apiRequest('/distribution/create').post(params)
}

const getDistributionsStats = () =>
  apiRequest(`/distribution/list`, { distributions: [], stats: {} }).get()

const getDistributionByLink = (link) =>
  apiRequest(`/distribution?link=${link}`, { distribution: {} }).get()

const getDistributionStats = (id) =>
  apiRequest(`/distribution/doctors/list?id=${id}`, {
    doctors: [],
    info: {},
    stats: {},
  }).get()

export const distributionApi = {
  createDistribution,
  getDistributionByLink,
  getDistributionStats,
  getDistributionsStats,
}
