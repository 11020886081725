import { Auth } from 'aws-amplify'

import { setCurrentLanguage } from 'translation'

import * as actionTypes from 'redux/constants'
import { authApi, awsApi } from 'api'

export const displaySocialAuthMessage =
  ({ message, isError = true }) =>
  (dispatch) => {
    dispatch({ type: actionTypes.AUTH_SOCIAL_MSG, payload: { message, isError } })
  }

export const socialSignIn = (provider) => () => {
  Auth.federatedSignIn({ provider })
}

export const changePassword = (currentPassword, newPassword) => async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, currentPassword, newPassword)

    return { success: true }
  } catch (e) {
    console.log('Error changing password: ', e.message)
    return { success: false, code: e.code, message: e.message }
  }
}

export const signIn = (email, password) => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_START })

  try {
    const user = await Auth.signIn(email, password)
    const payload = { user }

    if (user.signInUserSession) {
      const attributes = await getParseAttributes(user)

      payload.attributes = attributes
    }

    const language = user?.attributes?.language || user?.storage?.i18nextLng
    if (language) {
      const parsedLang =
        language &&
        (language.includes('-') ? language.split('-')[0] : language).toLowerCase()
      payload.language = parsedLang
    }

    dispatch({
      type: actionTypes.AUTH_SUCCESS,
      payload,
    })
  } catch (e) {
    dispatch({ type: actionTypes.AUTH_FAIL, payload: e.message })
    console.log('Error signing in: ', e)
  }
}

export const initialAutoSignIn = () => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_START })
  try {
    const user = await Auth.currentAuthenticatedUser()
    const attributes = await getParseAttributes(user)

    const payload = { user, attributes }

    const language = user?.attributes?.language || user?.storage?.i18nextLng
    if (language) {
      const parsedLang =
        language &&
        (language.includes('-') ? language.split('-')[0] : language).toLowerCase()
      payload.language = parsedLang
    }

    dispatch({
      type: actionTypes.AUTH_SUCCESS,
      payload,
    })
  } catch (e) {
    // If not logged in, and everything else is ok,
    // expect a 'The user is not authenticated' message so do not record it as error

    dispatch({
      type: actionTypes.AUTH_FAIL,
      payload:
        e === 'The user is not authenticated' ||
        e.message === 'Access Token has been revoked'
          ? null
          : e,
    })
  }
}

export const setNewPassword = (userWithTempPassword, newPassword) => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_START })
  try {
    const user = await Auth.completeNewPassword(userWithTempPassword, newPassword)
    const attributes = await getParseAttributes(user)

    const payload = { user, attributes }

    const language = user?.attributes?.language || user?.storage?.i18nextLng
    if (language) {
      const parsedLang =
        language &&
        (language.includes('-') ? language.split('-')[0] : language).toLowerCase()
      payload.language = parsedLang
    }

    dispatch({ type: actionTypes.AUTH_SUCCESS, payload })
  } catch (e) {
    dispatch({ type: actionTypes.AUTH_FAIL, payload: e.message })
    console.log(e)
  }
}

export const signOut = () => async (dispatch) => {
  try {
    await Auth.signOut()
    dispatch({ type: actionTypes.AUTH_LOGOUT })
  } catch (e) {
    dispatch({ type: actionTypes.AUTH_FAIL, payload: e.message })
    console.log('error signing out: ', e)
  }
}

export const forgotPasswordSubmitEmail = (email) => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_START })
  try {
    await Auth.forgotPassword(email)
    dispatch({ type: actionTypes.AUTH_RESET_PASS_SUCCESS })
    return true
  } catch (e) {
    console.log('e:', e)
    dispatch({ type: actionTypes.AUTH_FAIL, payload: e.message })
    return false
  }
}

export const forgotPasswordSubmitCode =
  (email, code, newPassword) => async (dispatch) => {
    dispatch({ type: actionTypes.AUTH_START })
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
      dispatch({ type: actionTypes.AUTH_RESET_PASS_SUCCESS })
      return true
    } catch (e) {
      console.log('Error:', e)
      dispatch({ type: actionTypes.AUTH_FAIL, payload: e.message })
      return false
    }
  }

export const setELearningInfo = (eLearningInfo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_E_LEARNING,
    payload: eLearningInfo || null,
  })
}

const getParseAttributes = async (user, useExistingAttributes) => {
  const attributesToDecode = process.env.REACT_APP_USER_ATTRIBUTES_TO_DECODE.split(',')
  // TODO: don't fetch attributes if useExistingAttributes is true
  const attributesArr = await Auth.userAttributes(user)

  const attributes = attributesArr.reduce((acc, { Name, Value }) => {
    const parsedName = Name.replace(/^custom:/, '')
    acc[parsedName] = attributesToDecode.includes(parsedName)
      ? JSON.parse(decodeURIComponent(Value))
      : Value

    return acc
  }, {})

  // TODO: replace with institution ?
  // attributes.referrerOrg = attributes.referrerOrg || {
  //   gender: 'M',
  //   name: { fr: 'Groupe Sanofi', en: 'Sanofi Group' },
  // }

  if (
    attributes.hasOwnProperty('prescriberCode') &&
    attributes.hasOwnProperty('otherInfo')
  )
    attributes['prescriberCode'] = attributes['otherInfo']['prescriberCode']

  return attributes
}

export const resetAuthError = () => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_RESET_PASS_SUCCESS })
}

export const refreshAttributes = () => async (dispatch) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const newAttributes = await getParseAttributes(user)

    dispatch({
      type: actionTypes.SET_ATTRIBUTES,
      payload: newAttributes,
    })
    return { success: true }
  } catch (e) {
    console.log('Error refreshing attributes: ', e.message)
    return { success: false, code: e.code, message: e.message }
  }
}

export const setOwnInitialPassword =
  ({ email, tempPassword, ownPassword }) =>
  async (dispatch) => {
    try {
      const userWithTempPassword = await Auth.signIn(email, tempPassword)

      if (userWithTempPassword?.challengeName !== 'NEW_PASSWORD_REQUIRED')
        throw Error('Invalid user login attempt')

      const user = await Auth.completeNewPassword(userWithTempPassword, ownPassword)
      const attributes = await getParseAttributes(user, false)

      const payload = { user, attributes }

      const language = user?.attributes?.language || user?.storage?.i18nextLng
      if (language) {
        const parsedLang =
          language &&
          (language.includes('-') ? language.split('-')[0] : language).toLowerCase()
        payload.language = parsedLang
      }

      dispatch({ type: actionTypes.AUTH_SUCCESS, payload })
      return true
    } catch (err) {
      console.log('Error logging in', err)
      dispatch(authFail(err))
      return false
    }
  }

// const saveToken = (token, remember) =>
//   remember
//     ? localStorage.setItem('jwtToken', token)
//     : sessionStorage.setItem('jwtToken', token)

// const getToken = () =>
//   sessionStorage.getItem('jwtToken') || localStorage.getItem('jwtToken')

// const removeToken = () => (
//   localStorage.removeItem('jwtToken'), sessionStorage.removeItem('jwtToken')
// )

// export const authStart = () => {
//   return {
//     type: actionTypes.AUTH_START,
//   }
// }

// export const authSuccess = (userData) => (dispatch) => {
//   const { id: userId, vip, language, saveFiles } = userData
//   return dispatch({
//     type: actionTypes.AUTH_SUCCESS,
//     payload: { userId, isPro: !!vip, language, saveFiles },
//   })
// }

export const authFail = (error) => (dispatch) => {
  return dispatch({
    type: actionTypes.AUTH_FAIL,
    payload: error,
  })
}

// export const logOut = () => (dispatch) => {
//   removeToken()
//   return dispatch({
//     type: actionTypes.AUTH_LOGOUT,
//   })
// }

// export const checkAuthTimeout = (expirationTime) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(logOut())
//   }, expirationTime * 1000)
// }

// export const logIn = ({ email, password, remember }) => async (dispatch) => {
//   dispatch(authStart())
//   // In case token exists but another user wants to log in
//   removeToken()

//   const {
//     result: { success, token, userData, message },
//     error,
//   } = await authApi.logIn({ email, password })

//   if (!success) return dispatch(authFail(error || message))

//   saveToken(token, remember)
//   dispatch(authSuccess(userData))

//   return success
// }

// export const setAuthRedirectPath = (path) => {
//   return {
//     type: actionTypes.SET_AUTH_REDIRECT_PATH,
//     path: path,
//   }
// }

// export const initialAutoAuth = () => async (dispatch) => {
//   dispatch(authStart())
//   const token = getToken()
//   if (!token) {
//     dispatch(logOut())
//     return false
//   }

//   const {
//     result: { success, userData },
//   } = await authApi.logIn({}, token)
//   if (success) {
//     // If user manu  y changed language to be different than language set in his db profile, load that language
//     const userSettings = getSettings(userData.id)
//     if (userSettings.language) userData.language = userSettings.language
//     userData.saveFiles = !!userSettings.saveFiles

//     dispatch(authSuccess(userData))
//   } else dispatch(logOut())

//   return success
// }

// const saveSettings = (settingsToUpdate, userId) => {
//   const settingsItem = `settings_${userId}`
//   const settings = JSON.parse(localStorage.getItem(settingsItem)) || {}
//   const updatedSettings = { ...settings, ...settingsToUpdate }
//   localStorage.setItem(settingsItem, JSON.stringify(updatedSettings))
// }

// const getSettings = (userId) => {
//   const settings = JSON.parse(localStorage.getItem(`settings_${userId}`)) || {}
//   return settings
// }

export const setLanguage = (language) => (dispatch) => {
  // TODO: Add new api for saving language
  // const userId = getState().auth.userId
  // saveSettings({ language }, userId)
  dispatch({ type: actionTypes.SET_LANGUAGE, payload: language })
  setCurrentLanguage(language)
}

export const updateUserInfo = (values) => async (dispatch) => {
  // TODO
  try {
    const { data } = await authApi.changeUserInfo(values)
    // dispatch({ type: actionTypes.SET_USER_INFO, payload: data })
    // TODO toast alert for result
  } catch (err) {
    // TODO toast alert for result
  }
}

export const updateAvatar =
  ({ contentType, extension, name, rawFile }) =>
  async () => {
    const { error: getSignedImageUrlError, result: getSignedImageUrlResult } =
      await awsApi.getSignedPutImageUrl({
        contentType: encodeURIComponent(contentType),
        fileName: `${encodeURIComponent(name)}.${extension}`,
        category: 'avatar',
      })

    if (getSignedImageUrlError) {
      console.log(
        'Error getting signed url: ' + getSignedImageUrlError.description ||
          getSignedImageUrlError.message
      )
      return { success: false }
    }

    const { signedPutImageUrl, imageFile } = getSignedImageUrlResult

    const { error: uploadImageError } = await awsApi.uploadFileToS3(
      signedPutImageUrl,
      rawFile,
      contentType,
      // TODO: edit progress bar fn
      () => {}
    )

    if (uploadImageError) {
      console.log(
        'Error uploading image: ' + uploadImageError.description ||
          uploadImageError.message
      )
      return { success: false }
    }

    return { success: true, avatar: decodeURIComponent(imageFile) }
  }

// TODO
export const updateEmail = (newEmail) => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_START })
  try {
    const {
      success,
      data: { email },
    } = await authApi.changeEmail(newEmail)

    // TODO: SET_USER_INFO is obsolete
    dispatch({ type: actionTypes.SET_USER_INFO, payload: { email } })
    // TODO toast alert for success result
  } catch (err) {
    // TODO toast alert for fail result
  }
}

export const resetPasswordFromProfile = () => async (dispatch, getState) => {
  try {
    const success = await Auth.forgotPassword(getState().auth.attributes.email)
    console.log('success:', success)

    await new Promise((resolve) => setTimeout(resolve, 3000))
    // TODO toast alert for success result
  } catch (err) {
    // TODO toast alert for fail result
  }
}
