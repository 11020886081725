import {
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_RESET_PASS_SUCCESS,
  AUTH_START,
  AUTH_SOCIAL_MSG,
  AUTH_SUCCESS,
  SET_LANGUAGE,
  SET_USER_INFO,
  SET_E_LEARNING,
  SET_ATTRIBUTES,
} from '../constants'

const initialState = {
  user: null,
  accountsInfo: { prescribers: [], patients: [] },
  isAuthenticated: false,
  groups: [],
  error: null,
  loading: false,
  language: 'fr',
  socialMessage: null,
  attributes: null,
  eLearningInfo: null,
  accountType: null,
  isDoctor: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START: {
      return { ...state, loading: true, error: null, socialMessage: null }
    }

    case AUTH_FAIL: {
      // Convert error message to i18n key code
      const errorMessage =
        typeof action.payload === 'string' || !action.payload
          ? action.payload
          : action.payload.message
      const error =
        errorMessage && errorMessage.replace(/\s/g, '_').replace(/\W/g, '').toUpperCase()

      return { ...state, loading: false, error: error || state.error }
    }

    case AUTH_SUCCESS: {
      const { user, attributes, language } = action.payload

      const accessToken = user?.signInUserSession?.accessToken
      const groups = accessToken?.payload?.['cognito:groups'] || []
      const isDoctor = groups.includes('doctors')
      const hasAnAdminPermission = Object.values(attributes.permissions.admin).some(
        (permission) => permission
      )

      const accountType = hasAnAdminPermission
        ? 'ADMINISTRATOR'
        : isDoctor
        ? 'DOCTOR'
        : 'PRO_USER'

      return {
        ...state,
        accountType,
        user,
        attributes,
        groups,
        isDoctor,
        loading: false,
        isAuthenticated: !!accessToken?.jwtToken,
        error: null,
        language: language || state.language,
        socialMessage: null,
      }
    }

    case SET_ATTRIBUTES: {
      const attributes = action.payload

      const hasAnAdminPermission = Object.values(attributes.permissions.admin).some(
        (permission) => permission
      )

      const accountType = hasAnAdminPermission
        ? 'ADMINISTRATOR'
        : state.isDoctor
        ? 'DOCTOR'
        : 'PRO_USER'

      return { ...state, accountType, attributes }
    }

    case AUTH_LOGOUT: {
      return {
        ...initialState,
        language: state.language,
      }
    }

    case AUTH_RESET_PASS_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        socialMessage: null,
      }
    }

    case AUTH_SOCIAL_MSG: {
      const { isError, message } = action.payload
      return { ...state, socialMessage: { isError, message } }
    }

    case SET_LANGUAGE: {
      return { ...state, language: action.payload }
    }

    case SET_USER_INFO: {
      // TODO: Move to attributes
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        error: null,
        loading: false,
      }
    }

    case SET_E_LEARNING: {
      return {
        ...state,
        eLearningInfo: action.payload,
      }
    }

    default:
      return { ...state }
  }
}

export default authReducer
