import React, { Fragment, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { rgba, darken } from 'polished'
import { NavLink as RouterNavLink, withRouter, Link as OutLink } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'
import 'vendor/perfect-scrollbar.css'

import {
  Avatar,
  Box,
  Chip,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { Add as AddIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons'
import { deepOrange } from '@material-ui/core/colors'
import { get } from 'lodash'

const NavLink = forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />)

const CreateIcon = styled(AddIcon)`
  color: white;
  font-size: 1.25rem;
  position: relative;
  left: -3px;
  bottom: 2px;
`
const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  cursor: default;
  justify-content: space-evenly;
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`
const BrandLogo = styled.div`
  width: fit-content;
  height: 2.5em;
`
const BrandImg = styled.img`
  display: block;
  object-fit: contain;
  height: 100%;
`
const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  cursor: ${({ button }) => (button ? 'pointer' : 'default')};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    ${({ button }) =>
      button
        ? `
    background: rgba(0, 0, 0, 0.08);
    `
        : ''};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`
const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`
const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`
const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`
const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`
const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`
const OrangeAvatar = styled(Avatar)`
  background-color: ${deepOrange[500]};
`
const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`
const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`
const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`
const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`
const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.725rem;
  display: block;
  padding: 1px;
`
const SubCategoryIcon = styled(ChevronLeftIcon)`
  transform: rotate(-45deg);
  color: white;
  font-size: 1.25rem;
  position: relative;
  left: -3px;
  bottom: 2px;
`

const SidebarCategory = ({
  name,
  icon,
  classes,
  badge,
  isLinkOut,

  ...rest
}) => {
  return isLinkOut ? (
    <Category {...rest} to={{ pathname: rest.to }} component={OutLink} target="_blank">
      {icon}
      <CategoryText>{name}</CategoryText>
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  ) : (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  )
}

function SidebarLink({ name, to, badge }) {
  return (
    <Link button dense component={NavLink} exact to={to} activeClassName="active">
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  )
}

const Sidebar = ({ routes, classes, staticContext, location, ...rest }) => {
  const { t } = useTranslation('userTypes')
  const { accountType, attributes } = useSelector(({ auth }) => auth)
  const isDoctor = accountType === 'DOCTOR'
  const logoBaseUrl = process.env.REACT_APP_COMPANY_LOGO_URL

  const logoSrc = attributes.companyLogo
    ? `${logoBaseUrl}/${attributes.companyLogo}`
    : '/static/img/logo/logo_white.png'

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand>
        <BrandLogo>
          <BrandImg src={logoSrc} />
        </BrandLogo>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category) =>
              !category.noSidebar &&
              (!category.noDoctor || !isDoctor) &&
              (!category.permission ||
                get(attributes.permissions, category.permission)) &&
              (!category.children ||
                category.children.some(({ permission }) => !permission) ||
                category.children
                  .filter(({ permission }) => permission)
                  .some(({ permission }) => get(attributes.permissions, permission))) ? (
                <Fragment key={category.id}>
                  {!!category.header && (
                    <SidebarSection>{category.header}</SidebarSection>
                  )}

                  {category.children ? (
                    <>
                      <SidebarCategory name={category.name} icon={category.icon} />
                      {category.children.map((route) =>
                        !route.noSidebar &&
                        (!route.permission ||
                          get(attributes.permissions, route.permission)) ? (
                          <SidebarLink
                            key={route.name + route.path}
                            name={
                              <Box display="flex">
                                {route.isCreate ? <CreateIcon /> : <SubCategoryIcon />}
                                {route.name}
                              </Box>
                            }
                            to={route.path}
                            icon={route.icon}
                            badge={route.badge}
                          />
                        ) : null
                      )}
                    </>
                  ) : category.isLinkOut ? (
                    <SidebarCategory
                      name={category.name}
                      to={category.path}
                      isLinkOut
                      icon={category.icon}
                      badge={category.badge}
                      button
                    />
                  ) : (
                    <SidebarCategory
                      name={category.name}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      badge={category.badge}
                      button
                    />
                  )}
                </Fragment>
              ) : null
            )}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            {attributes.avatar ? (
              <Avatar
                alt={attributes.name}
                src={`${process.env.REACT_APP_AVATAR_URL}/${attributes.avatar}`}
              />
            ) : (
              <OrangeAvatar>{attributes.name?.[0] || ''}</OrangeAvatar>
            )}
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">{attributes.name || ''}</SidebarFooterText>
            <SidebarFooterSubText variant="caption">
              {t(accountType)}
            </SidebarFooterSubText>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  )
}

export default withRouter(Sidebar)
