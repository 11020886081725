import { api, apiOld, apiLambdas } from 'api/api'

const createOldApi = (api) => (url, placeholder) => {
  const get = async () => {
    let res
    try {
      res = await api.get(url)
    } catch (err) {
      console.error(err)
      return { result: placeholder, error: err.message }
    }

    return {
      result: res && res.data ? res.data.result || res.data : placeholder,
    }
  }

  const post = async (params, config) => {
    let res
    try {
      res = await api.post(url, params, config)
    } catch (err) {
      console.error(err)
      return { result: placeholder, error: err.message, errorCode: err.code }
    }

    return {
      result: res && res.data ? res.data.result || res.data : placeholder,
    }
  }

  const put = async (data) => {
    let res
    try {
      res = await api.put(url, data)
    } catch (err) {
      console.error(err)
      return { result: placeholder, error: err.message, errorCode: err.code }
    }

    return {
      result: res && res.data ? res.data.result || res.data : placeholder,
    }
  }

  return {
    get,
    post,
    put,
  }
}

const createApi = (api) => (url, placeholder) => {
  const requestTemplate =
    (reqFn) =>
    async (...args) => {
      let res
      try {
        res = await reqFn(...args)
      } catch (err) {
        if (process.env.NODE_ENV !== 'production')
          console.log(`Error at ${url}: `, err.message || err)

        return {
          result: placeholder,
          error: {
            message: err?.response?.data?.error || err.message,
            description: err?.response?.data?.error_description,
            status: err?.response?.status,
            data: err?.response?.data?.error_data,
          },
        }
      }

      return {
        result: res?.data ? res.data : placeholder,
      }
    }

  const get = requestTemplate(() => api.get(url))

  const post = requestTemplate((params, config) => api.post(url, params, config))

  const put = requestTemplate((data) => api.put(url, data))

  return {
    get,
    post,
    put,
  }
}

// Old api will return data in 'res.data.result'
const oldApiRequest = createOldApi(apiOld)
// For new api, will use 'res.data'
const apiRequest = createApi(api)

const apiLambdaRequest = createApi(apiLambdas)

export { apiRequest, apiLambdaRequest, oldApiRequest }
