import { invitationsData } from 'api/dummyData/invitationsDummyData'
import { apiRequest } from '../apiRequest'
import { getCurrentLanguage } from 'translation'

const sendInvitationsDoctors = ({ emails }) => {
  const params = { emails, language: getCurrentLanguage() }

  return apiRequest('/invite/doctor/create').post(params)
}

const sendInvitationsPatients = ({ emails, prescriberCode }) => {
  const params = { emails, language: getCurrentLanguage() }
  if (prescriberCode) params.prescriberCode = prescriberCode.toString()

  return apiRequest('/invite/patient/create').post(params)
}

const sendInvitationPro = (newInvitationProData) => {
  const params = { ...newInvitationProData, language: getCurrentLanguage() }

  return apiRequest('/invite/pro/create').post(params)
}

const getInvitationPatientInfo = (invitationId) => {
  return apiRequest(`/invite/patient?id=${invitationId}`).get()
}

const getInvitationProInfo = (invitationProId) =>
  apiRequest(`/invite/pro?id=${invitationProId}`).get()

const getInvitationDoctorInfo = (invitationDoctorId) =>
  apiRequest(`/invite/doctor?id=${invitationDoctorId}`).get()

const completeInvitation = (data) => apiRequest(`/user/create`).post(data)

// Temporary structure until api for mock data manipulation
let invitationsDataTemp = invitationsData

const getInvitations = () => apiRequest(`/invite/list`, { invites: [] }).get()

const revokeInvitation = (id, type) => {
  if (!['patient', 'pro', 'doctor'].includes(type)) throw Error('Missing invite type')
  return apiRequest(`/invite/${type}/update`).put({ id, status: 'revoked' })
}

const resendInvitation = (id, type) => {
  if (!['patient', 'pro', 'doctor'].includes(type)) throw Error('Missing invite type')
  return apiRequest(`/invite/${type}/update`).put({ id, status: 'sent' })
}

const setStartTrainingDateOnInvitationPro = (id) =>
  apiRequest('/invite/pro/update/elearning').put({ id })

const setCompletedTrainingDateOnInvitationPro = (id) =>
  apiRequest('/invite/pro/update/elearning/completed').put({ id })

const createDisributionDoctorInvite = ({ distributionId, email, skipELearning }) =>
  apiRequest('/invite/distribution/create').post({
    distribution: distributionId,
    email,
    language: getCurrentLanguage(),
    skipELearning,
  })
//skipELearning

export const inviteApi = {
  completeInvitation,
  createDisributionDoctorInvite,
  getInvitationPatientInfo,
  getInvitationProInfo,
  getInvitationDoctorInfo,
  getInvitations,
  revokeInvitation,
  resendInvitation,
  sendInvitationPro,
  sendInvitationsPatients,
  sendInvitationsDoctors,
  setStartTrainingDateOnInvitationPro,
  setCompletedTrainingDateOnInvitationPro,
}
