export const getSongAudioAddress = (songId) =>
  `${process.env.REACT_APP_SONG_FILE_URL}/seance_${songId}/track.mp3`

export const getSongImageAddress = (imageName) =>
  `${process.env.REACT_APP_SONG_IMAGE_URL}/${imageName}`

// export const getImmersiveVideoUrl = (fileName) =>
//   `${process.env.REACT_APP_CLOUD_FRONT_IMMERSIVE_VIDEO}/${fileName}`

// export const getImmersiveThumbUrl = (fileName) =>
//   `${process.env.REACT_APP_CLOUD_FRONT_IMMERSIVE_THUMB}/${fileName}`
